<mat-form-field #skillFormField *ngIf="!chipOnlyInput" class="skill-chip-field" appearance="fill">
    <mat-label>{{fieldLabel}}</mat-label>
    <mat-chip-list #chipList [formControl]="skillControl">
      <mat-chip
        *ngFor="let skill of shownSkills"
        (removed)="remove(skill)"
        disableRipple>
        {{skill.skillName}}
        <button matChipRemove>
          <mat-icon svgIcon="cancel"></mat-icon>
        </button>
      </mat-chip>
      <input
        [placeholder]="(disabled) ? null : 'Search for skills...'"
        #skillInput
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="null"
        (matChipInputTokenEnd)="add($event)"
        [formControl]="skillControl">
        <mat-spinner *ngIf="isSpinnerLoading" diameter="25"></mat-spinner>
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let filteredSkill of filteredSkills | async" [value]="filteredSkill">
        {{filteredSkill.skillName}}
      </mat-option>
    </mat-autocomplete>
    <mat-error>{{getErrorMessage()}}</mat-error> 
</mat-form-field>

<mat-form-field *ngIf="chipOnlyInput" appearance="fill" class="skill-chip-field">
  <mat-label>{{fieldLabel}}</mat-label>
  <mat-chip-list>
    <mat-chip
      *ngFor="let skill of shownSkills"
      (removed)="sendToSkillsToDevelop(skill)"
      disableRipple
      [selected]="skill.skillOverlaps"
      color="accent">
    {{skill.skillName}}
    <button matTooltip="Add to Skills I Want to Develop" *ngIf="!skill.skillOverlaps" matChipRemove>
      <mat-icon svgIcon="add"></mat-icon>
    </button>
  </mat-chip>
  </mat-chip-list>
</mat-form-field>

 <!-- popup for empty skills based on search -->
<de-modal modalTitle="No search results found" [open]="NoResultModalOpen" showMask="true" (close)="emptyResultModel()"
  theme="secondary" width="30vw" height="25vh">
  <div class="notification-modal-body" position="centered">
    <div>
      <h2 class="notification-modal-title">No skills were found. Please search again.</h2>
    </div>
    <de-button (buttonClick)="emptyResultModel()" class="notification-modal-button" type="primary"
      size="small">Close</de-button>
  </div>
</de-modal>
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../../config/app.config';
import { Observable } from 'rxjs';
import { Gig } from '../../shared/interfaces/gig/gig.interface';
import { GigCategory } from 'src/app/shared/interfaces/gig/gig-category.interface';
import { GigLocation } from 'src/app/shared/interfaces/gig/gig-location.interface';
import { PaginatedEmployeeList } from 'src/app/shared/interfaces/employee/paginated-employee-list';
import { GigVisibility } from 'src/app/shared/interfaces/employee/gig-visibility.interface';
import { SharedGig } from 'src/app/shared/interfaces/gig/shared-gig.interface';
import { GigReport } from 'src/app/shared/interfaces/gig/gig-report.interface';
import { GigFile } from 'src/app/shared/interfaces/gig/gig-file.interface';

@Injectable({
  providedIn: 'root'
})
export class GigService {

  constructor(private http: HttpClient, private appConfig: AppConfig) { }

  getAllGigs(): Observable<Gig[]> {
    let url: string = this.appConfig.apiBaseUrl + '/gigs';
    return this.http.get<Gig[]>(url);
  }

  getGigDetails(gigId: number): Observable<Gig> {
    let url: string = this.appConfig.apiBaseUrl + `/gigs/${gigId}`;
    return this.http.get<Gig>(url);
  }

  getGigCategories(): Observable<GigCategory[]> {
    let url: string = this.appConfig.apiBaseUrl + '/categories';
    return this.http.get<GigCategory[]>(url);
  }

  getGigLocations(): Observable<GigLocation[]> {
    let url: string = this.appConfig.apiBaseUrl + '/locations';
    return this.http.get<GigLocation[]>(url);
  }

  createGig(gig: any): Observable<Gig[]> {
    let url: string = this.appConfig.apiBaseUrl + '/gigs';
    return this.http.post<Gig[]>(url, gig, { responseType: 'text' as 'json' });
  }

  postGig(gig, gigId: number): Observable<Gig[]> {
    let url: string = this.appConfig.apiBaseUrl + `/gigs/${gigId}/action/GigAdminApprovalWFAction`;
    return this.http.post<Gig[]>(url, gig, { responseType: 'text' as 'json' });
  }

  closeGig(gigId: number): Observable<any> {
    let url: string = this.appConfig.apiBaseUrl + `/gigs/${gigId}/closed`;
    return this.http.post<any>(url, {}, { responseType: 'text' as 'json' });
  }

  saveGigDraft(gig: any): Observable<Gig> {
    let url: string = this.appConfig.apiBaseUrl + `/gigs/save`;
    return this.http.post<Gig>(url, gig, { responseType: 'text' as 'json' });
  }

  updateGigDraft(gig: any): Observable<Gig> {
    let url: string = this.appConfig.apiBaseUrl + `/gigs/save`;
    return this.http.put<Gig>(url, gig, { responseType: 'text' as 'json' });
  }

  uploadFileInGig(formData, selectedFileName, payload): Observable<any> {
    // console.log("formData", formData);

    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data; boundary=123',
      // 'Access-Control-Allow-Origin': '*',
      //  'Access-Control-Allow-Private-Network':'true',
      // 'Authorization':'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjlHbW55RlBraGMzaE91UjIybXZTdmduTG83WSJ9.eyJhdWQiOiI5NDk1MmJkMi05MTIwLTRiZjYtYTZjNy1mZTJhMDQ0OTdkY2YiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vMmVkZTM4M2EtN2UxZi00MzU3LWE4NDYtODU4ODZiMmMwYzRkL3YyLjAiLCJpYXQiOjE2OTg5NTY5ODgsIm5iZiI6MTY5ODk1Njk4OCwiZXhwIjoxNjk4OTYxNzI2LCJhaW8iOiJBVlFBcS84VUFBQUFjQ1VOdytmc3l4cU9WRWVhbTZ1b1hwbHo3VEdFemJDc1R5QW4vTVY3OXB5aTEydmtkYUhyVTVQTW16YVdtSmd1Sk1CdjMza0R3aFIzRjFLUUpnL2xRQlJrMC8yaVBtWU80RDU4YnpxUnZuZz0iLCJhenAiOiI5NDk1MmJkMi05MTIwLTRiZjYtYTZjNy1mZTJhMDQ0OTdkY2YiLCJhenBhY3IiOiIwIiwiZW1haWwiOiJTYWdhci5QYXRlbEBkdWtlLWVuZXJneS5jb20iLCJuYW1lIjoiUGF0ZWwsIFNhZ2FyIiwib2lkIjoiNDI4ZjJiMjUtOTcwNS00MzAyLTlkODMtZTFmM2IyYTc2ODNjIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiU2FnYXIuUGF0ZWxAZHVrZS1lbmVyZ3kuY29tIiwicmgiOiIwLkFSd0FPamplTGg5LVYwT29Sb1dJYXl3TVRkSXJsWlFna2ZaTHBzZi1LZ1JKZmM4Y0FJby4iLCJyb2xlcyI6WyJ0bXBBZG1pbiIsInRtcE1hbmFnZXIiLCJ0bXBFbXBsb3llZSJdLCJzY3AiOiJBc3NldHMuUmVhZCIsInN1YiI6ImpkN191MTRZQll0eEFqZHRFUjFKZGFNdy1SMVRFYUFBaVdsWVd0VWlBTUEiLCJ0aWQiOiIyZWRlMzgzYS03ZTFmLTQzNTctYTg0Ni04NTg4NmIyYzBjNGQiLCJ1dGkiOiJDUHM1NTN4QmJFS3VtTE1lU2xvd0FBIiwidmVyIjoiMi4wIiwiZW1wbG95ZWVJZCI6IjUzODUyMyIsIm1haWwiOiJTYWdhci5QYXRlbEBkdWtlLWVuZXJneS5jb20iLCJsYW5JZCI6IlNQYXRlMTMiLCJkZXBhcnRtZW50IjoiNDY0MTYifQ.qgZWwlQR_JEcyTSEESbmb7imn2X1g543vJzOpw0E3QVzYafU_nwjfIVSCjh0Y34atMxd-0phuNjIfzSJlQGguY503rxiedYETUrNWOToOpQ4mgmIa3WL7GQJniEpYm3hrRP-pUISgj6Bdwj8H4Ft4gW5KrFN1P1Do6GCxpGDCT3hJgusBqclhV7kf11Zm9lOcCDZHF3TdF3BQXMoh3B5iyEfVgn82ZhLZKM8KSCSPWZBWGziaXuPcJskKmPew5NWMjwaid16STRp2D6a5Y9shMSYLOHKEjsPRew_WLyhmp9AX5oymMBUauHC-6KwXPYqseZrh7engFe60a1CRY6zgQ'

    })
    let url: string = "https://my8uttgmb9.execute-api.us-east-1.amazonaws.com/talent-marketplace-api-dev/files/talent-marketplace-gig-bucket-dev"
    // let url: string = `https://talentmarketplace-api-sbx.duke-energy.app/gigs/upload/${bucket}/${filename}`;

    return this.http.post<any>(url, formData, { responseType: 'text' as 'json' });
  }

  downloadGigFile(keyName: string): Observable<GigFile[]> {
    let url: string = `https://my8uttgmb9.execute-api.us-east-1.amazonaws.com/talent-marketplace-api-dev/files/talent-marketplace-gig-bucket-dev/${keyName}`;
    return this.http.get<GigFile[]>(url);
  }

  searchGigs(searchText: string): Observable<Gig[]> {
    let url: string = this.appConfig.apiBaseUrl + `/gigs/search?searchText=${searchText}`;
    return this.http.get<Gig[]>(url);
  }

  getRecommendedGigs(employeeId: string): Observable<Gig[]> {
    let url: string = this.appConfig.apiBaseUrl + `/recommendation/gigSkills?employeeId=${employeeId}`;
    return this.http.get<Gig[]>(url);
  }

  searchForEmployee(searchText: string): Observable<PaginatedEmployeeList> {
    let url: string = this.appConfig.apiBaseUrl + `/employees?pageNumber=1&pageSize=25&searchText=${searchText}`;
    return this.http.get<PaginatedEmployeeList>(url);
  }

  getGigVisibility(gigId: number): Observable<GigVisibility> {
    let url: string = this.appConfig.apiBaseUrl + `/gigs/${gigId}/visibility`;
    return this.http.get<GigVisibility>(url);
  }

  saveGigVisibility(gigId: number, gigVisibility: GigVisibility): Observable<GigVisibility> {
    let url: string = this.appConfig.apiBaseUrl + `/gigs/${gigId}/visibility`;
    return this.http.post<any>(url, gigVisibility, { responseType: 'text' as 'json' });
  }

  shareGig(gigId: number, sharedGig: SharedGig[]): Observable<any> {
    let url: string = this.appConfig.apiBaseUrl + `/gigs/${gigId}/share`;
    return this.http.post<any>(url, sharedGig, { responseType: 'text' as 'json' });
  }
  gigReport(request: any): Observable<Array<GigReport>> {
    let url: string = this.appConfig.apiBaseUrl + `/reports/gigs`;
    return this.http.post<any>(url, request, { responseType: 'json' });
  }
}
<mat-card class="main-content-container">

  <ng-container>
    <div class="feed-title">
      <h1>Admin Gig Report</h1>
    </div>
    <div class="main-filter-actions">
      <div class="filter-input">
        <mat-form-field appearance="outline">
          <span matPrefix>
            <mat-icon svgIcon="search"></mat-icon>
          </span>
          <input [(ngModel)]="searchText" matInput (keyup.enter)="searchGigs()"
            placeholder="Filter By (Enter Specific Value)">
          <button *ngIf="searchText" matSuffix mat-icon-button disableRipple (click)="clearSearchResults()">
            <mat-icon svgIcon="close"></mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="date-filter">
        <mat-form-field>
          <mat-label>Enter start date</mat-label>
          <input [max]="maxDate" [(ngModel)]="startDate" name="startDate"
            (dateChange)="dateRangeChange(startDate, endDate)" matInput [matDatepicker]="startPicker">
          <mat-hint>MM/DD/YYYY</mat-hint>
          <button *ngIf="startDate" matSuffix mat-icon-button disableRipple (click)="clearDates('startDate')">
            <mat-icon svgIcon="close"></mat-icon>
          </button>
          <mat-datepicker-toggle matSuffix [for]="startPicker">
          </mat-datepicker-toggle>
          <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="date-filter">
        <mat-form-field>
          <mat-label>Enter end date</mat-label>
          <input [max]="maxDate" [(ngModel)]="endDate" name="endDate" (dateChange)="dateRangeChange(startDate, endDate)"
            matInput [matDatepicker]="endPicker">
          <mat-hint>MM/DD/YYYY</mat-hint>
          <button *ngIf="endDate" matSuffix mat-icon-button disableRipple (click)="clearDates('endDate')">
            <mat-icon svgIcon="close"></mat-icon>
          </button>
          <mat-datepicker-toggle matSuffix [for]="endPicker">
          </mat-datepicker-toggle>
          <mat-datepicker #endPicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="gig-report-table">
      <section class="gig-list-table table-responsive" [hidden]="!gigVisibilityDataSource.data.length">

        <table mat-table [dataSource]="gigVisibilityDataSource" matSort (matSortChange)="sortData($event)">
          <!-- gig host data start -->
          <ng-container matColumnDef="gigId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gig ID</th>
            <td mat-cell *matCellDef="let gigItem"><span [matTooltip]="gigItem.gigName"> {{gigItem.gigId}}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="gigName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gig Name</th>
            <td mat-cell *matCellDef="let gigItem"><span [matTooltip]="gigItem.gigName"> {{gigItem.gigName}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="gigStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gig Status</th>
            <td mat-cell *matCellDef="let gigItem"><span [matTooltip]="gigItem.gigStatus"> {{gigItem.gigStatus}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="gigHostName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gig Host Name</th>
            <td mat-cell *matCellDef="let gigItem"><span
                [matTooltip]="gigItem.gigHostName">{{gigItem.gigHostName}}</span> </td>
          </ng-container>
          <ng-container matColumnDef="gigHostEmployeeId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gig Host Employe ID</th>
            <td mat-cell *matCellDef="let gigItem"><span
                [matTooltip]="gigItem.gigHostEmployeeId">{{gigItem.gigHostEmployeeId}}</span> </td>
          </ng-container>
          <ng-container matColumnDef="gigHostEmailAddress">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gig Host Email</th>
            <td mat-cell *matCellDef="let gigItem"><span
                [matTooltip]="gigItem.gigHostEmailAddress">{{gigItem.gigHostEmailAddress}}</span> </td>
          </ng-container>
          <ng-container matColumnDef="gigHostFercClassification">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gig Host FERC</th>
            <td mat-cell *matCellDef="let gigItem" class="truncate-column"><span
                [matTooltip]="gigItem.gigHostFercClassification">{{gigItem.gigHostFercClassification}}</span> </td>
          </ng-container>
          <!-- gig application data start -->
          <ng-container matColumnDef="gigApplicantName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gig Applicant Name</th>
            <td mat-cell *matCellDef="let gigItem"><span
                [matTooltip]="gigItem.gigApplicantName">{{gigItem.gigApplicantName}}</span> </td>
          </ng-container>
          <ng-container matColumnDef="gigApplicantEmployeeId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gig Applicant Employee ID</th>
            <td mat-cell *matCellDef="let gigItem"><span
                [matTooltip]="gigItem.gigApplicantEmployeeId">{{gigItem.gigApplicantEmployeeId}}</span> </td>
          </ng-container>
          <ng-container matColumnDef="gigApplicantEmailAddress">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gig Applicant Email</th>
            <td mat-cell *matCellDef="let gigItem"><span
                [matTooltip]="gigItem.gigApplicantEmailAddress">{{gigItem.gigApplicantEmailAddress}}</span> </td>
          </ng-container>
          <ng-container matColumnDef="gigApplicantFercClassification">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gig Applicant FERC </th>
            <td mat-cell *matCellDef="let gigItem"><span
                [matTooltip]="gigItem.gigApplicantFercClassification">{{gigItem.gigApplicantFercClassification}}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="gigApplicationStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gig Applicant Status</th>
            <td mat-cell *matCellDef="let gigItem"><span
                [matTooltip]="gigItem.gigApplicationStatus">{{gigItem.gigApplicationStatus}}</span> </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="gigVisibilityColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: gigVisibilityColumns;"></tr>
          <tr class="mat-row no-data-table" *matNoDataRow>
            <td colspan="12" class="mat-cell no-data-found">No data matching the filter.</td>
          </tr>
        </table>

      </section>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" [length]="gigTotalRecord" [pageSize]="itemPerPage"
        (page)="loadPageData($event)" showFirstLastButtons>
      </mat-paginator>
    </div>
  </ng-container>
</mat-card>
import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatRadioChange } from '@angular/material/radio';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DeModalComponent } from '@de-electron/electron-angular-components';
import { Observable, Subscription, throwError } from 'rxjs';
import { map, startWith, distinctUntilChanged, mergeMap, catchError } from 'rxjs/operators';
import { AuthenticatedUser } from 'src/app/auth/models/authenticated-user';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { GigService } from 'src/app/services/gig/gig.service';
import { SkillService } from 'src/app/services/skills/skill.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { Employee } from 'src/app/shared/interfaces/employee/employee.interface';
import { GigVisibility } from 'src/app/shared/interfaces/employee/gig-visibility.interface';
import { PaginatedEmployeeList } from 'src/app/shared/interfaces/employee/paginated-employee-list';
import { GigCategory } from 'src/app/shared/interfaces/gig/gig-category.interface';
import { GigLocation } from 'src/app/shared/interfaces/gig/gig-location.interface';
import { Gig } from 'src/app/shared/interfaces/gig/gig.interface';
import { PaginatedSkillList } from 'src/app/shared/interfaces/skills/paginated-skill-list.interface';
import { Skill } from 'src/app/shared/interfaces/skills/skill.interface';


@Component({
  selector: 'app-create-gig',
  templateUrl: './create-gig.component.html',
  styleUrls: ['./create-gig.component.scss']
})
export class CreateGigComponent implements OnInit, AfterViewInit, OnDestroy {

  user: AuthenticatedUser = JSON.parse(sessionStorage.getItem('currentUser'));
  fileURL: any = null;
  isManager: boolean;
  isAdmin: boolean;
  imgBycat: string = "../../../../assets/icons/phase_01_photo.png";
  gigSkills: Skill[] = [];
  allSkills: Skill[] = [];

  @ViewChild('fileInput') fileInput: ElementRef;

  // Type can be GigFile[] or FileList
  selectedFile: any;

  // Type can be File or GigFile
  selectedFileName: any;


  previewMode: boolean = false;
  approvalMode: boolean = false;

  createGigForm = new UntypedFormGroup({
    gigName: new UntypedFormControl('', Validators.required),
    gigCategory: new UntypedFormControl('', Validators.required),
    gigDescription: new UntypedFormControl('', Validators.required),
    gigDeliverables: new UntypedFormControl('', Validators.required),
    gigSkills: new UntypedFormControl(this.gigSkills, Validators.required),
    gigDuration: new UntypedFormControl('', Validators.required),
    dateRange: new UntypedFormGroup({
      gigStartDate: new UntypedFormControl('', Validators.required),
      gigEndDate: new UntypedFormControl('', Validators.required),
    }),
    gigAvailability: new UntypedFormControl('', Validators.required),
    gigMobilityClassification: new UntypedFormControl('remote', Validators.required),
    gigLocation: new UntypedFormControl(''),
    gigTeamMemberTotal: new UntypedFormControl(1, Validators.required)
  });


  onFileSelected(event: any) {
    this.selectedFile = event.target.files;
    this.selectedFileName = this.selectedFile[0];
    this.uploadFile();
    // let bucket  = `talent-marketplace-apigw-bucket-sbx`
    // const formData = new FormData();


    // if(this.selectedFile){

    //     this.gigService.uploadFileInGig(this.selectedFileName,this.selectedFile[0]?.name,bucket).subscribe( 
    //       () => {
    //       },
    //       (error) => {
    //         throwError(error);
    //         console.log('error',error);
    //         this.utilitiesService.updateIsLoading(false);
    //         this.utilitiesService.showSnackbarMessage('warning', `There was an error retrieving this gig's details. Please try again later.`, 6000, true);
    //       },
    //       () => {
    //         this.utilitiesService.updateIsLoading(false);
    //       });
    // }

  }

  clearFile() {
    this.selectedFile = null;
    this.selectedFileName = null;
  }


  similarGigs: Gig[] = [];
  viewedGig: Gig;

  //For Gig Visibility modal
  visibilityFilterModalOpen: boolean = false;

  gigVisibilityColumns = ['employeeName', 'employeeId', 'select'];
  gigVisibilityDataSource = new MatTableDataSource<Employee>();



  @ViewChild(MatPaginator) paginator: MatPaginator;
  onSubmitSkillsValidation: boolean = false;

  gigCategories: GigCategory[] = [];

  // gigCategories: GigCategory[] = [
  //   {
  //     gigCategoryId: 1,
  //     gigCategoryName: 'Gig Category 1'
  //   },
  //   {
  //     gigCategoryId: 2,
  //     gigCategoryName: 'Gig Category 2'
  //   },
  //   {
  //     gigCategoryId: 3,
  //     gigCategoryName: "Job Shadowing"
  //   }
  // ];

  // gigLocations: GigLocation[] = [
  //   {
  //     locationId: 1,
  //     locationName: 'Charlotte'
  //   },
  //   {
  //     locationId: 2,
  //     locationName: 'Atlanta'
  //   },
  //   {
  //     locationId: 219,
  //     locationName: "Greenville SC Broad Street"
  //   }
  // ];
  gigLocations: GigLocation[] = [];

  chosenCategory: GigCategory;
  chosenLocation: GigLocation;
  filteredGigLocations: Observable<string[]>;

  submittedGigForm: boolean = false;

  showSubmitModal: boolean = false;
  showPostGigModal: boolean = false;
  showReviseGigModal: boolean = false;

  reviseGigComment: string = '';

  timeCommitmentOptions = [
    '1-5 hrs/wk',
    '5-10 hrs/wk',
    '10-20 hrs/wk',
    '20-30 hrs/wk',
    '30-40 hrs/wk'
  ];

  gigDurationOptions = [
    '1 to 3 months',
    '3 to 6 months',
    '6 to 9 months',
    '9 to 12 months',
    'Less than 1 month'
  ];

  noOfPositionOptions = [];

  selectedCheckbox: any;

  isCloned: boolean = false;

  @ViewChild('deModal') deModal: DeModalComponent;
  disableSkillChipField: boolean = false;

  isSpinnerLoading: boolean = false;

  gigVisibilitySelection = new SelectionModel<Employee>(true, []);

  toolbarOptions = [
    ['bold', 'italic', 'underline', 'link'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }]
  ];
  canSaveVisibilityChanges: boolean = false;

  activeSubscriptions: Subscription = new Subscription();

  minDate: Date;

  constructor(private authService: AuthenticationService,
    private router: Router,
    private utilitiesService: UtilitiesService,
    private gigService: GigService,
    private skillService: SkillService) {
    //Viewed gig is populated for managers and admins only
    if (this.router.getCurrentNavigation()?.extras.state) {
      this.viewedGig = this.router.getCurrentNavigation().extras.state;
    }
  }

  ngOnInit(): void {
    for (let i = 1; i <= 15; i++) {
      this.noOfPositionOptions.push(i);
    }
    this.authService.getRoles().then((roleList) => {
      this.isAdmin = this.authService.isUserAdmin(roleList);
      this.isManager = this.authService.isUserManager(roleList);

      //Enable managers to only edit a gig that's In Recruiting or Fully Staffed
      if ((!this.isAdmin && !this.viewedGig?.isCloned) && (this.viewedGig?.gigStatus === 'In Recruiting' || this.viewedGig?.gigStatus === 'Fully Staffed')) {
        this.disableSkillChipField = true;
        this.createGigForm.disable();
        this.createGigForm.get('dateRange').get('gigEndDate').enable();
      }

      // Admins can't edit number of positions once a gig is Fully Staffed
      if (this.isAdmin && this.viewedGig?.gigStatus === 'Fully Staffed') {
        this.createGigForm.get('gigTeamMemberTotal').disable();
      }

      if (this.isAdmin && this.viewedGig?.gigStatus === 'Pending Approval') {
        this.approvalMode = true;
        this.getGigDetails(this.viewedGig);
      }

      //If editing a gig, or cloning a gig, pre-populate fields with gig data
      if (this.viewedGig) {
        this.setGigFormValues();
      }

      //Set default value for gig location to remote
      else {
        this.createGigForm.get('gigMobilityClassification').setValue('remote');
      }

      this.filteredGigLocations = this.createGigForm.get('gigLocation').valueChanges.pipe(
        startWith(''),
        map(value => {
          const locationName = typeof value === 'string' ? value : value?.locationName;
          return locationName ? this._filterLocations(locationName as string) : this.gigLocations.slice();
        })
      );
    });

    this.getGigCategories();
    this.getGigLocations();
    // this.getAllSkills();

  }

  ngAfterViewInit(): void {
    this.gigVisibilityDataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.activeSubscriptions.unsubscribe();
  }

  getGigDetails(gig: Gig): void {

    this.activeSubscriptions.add(
      this.gigService.getGigDetails(gig.gigId).subscribe(
        (gig) => {
          this.viewedGig = gig;
          this.setGigFormValues();
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', `There was an error retrieving this gig's details. Please try again later.`, 6000, true);
        },
        () => {
          this.utilitiesService.updateIsLoading(false);
        })
    );
  }

  setGigFormValues(): void {
    if (this.viewedGig?.gigSkill) {
      for (let skill of this.viewedGig?.gigSkill) {
        this.gigSkills.push(skill);
      }
    }

    if (this.viewedGig?.isCloned) {
      this.viewedGig.gigManager.employeeId = this.user.idTokenClaims.employeeId;
      this.viewedGig.gigManager.emailAddress = this.user.idTokenClaims.preferred_username;
      this.viewedGig.gigManager.firstName = this.user.idTokenClaims.given_name;
      this.viewedGig.gigManager.lastName = this.user.idTokenClaims.family_name;

      //Setting this blank since it's not neccessarily needed for the gig submission request. Can be updated later if needed
      this.viewedGig.gigManager.jobTitle = '';
    }

    this.createGigForm.get('gigName').setValue(this.viewedGig?.gigName);
    this.createGigForm.get('gigDescription').setValue(this.viewedGig?.gigDescription);
    this.createGigForm.get('gigDeliverables').setValue(this.viewedGig?.deliverables);
    this.createGigForm.get('gigCategory').setValue(this.viewedGig?.gigCategory);
    this.createGigForm.get('gigSkills').setValue(this.gigSkills);
    this.createGigForm.get('gigDuration').setValue(this.viewedGig?.gigDuration);

    this.getByDefaultImg(this.viewedGig?.gigCategory);

    let formattedStartDate = this.utilitiesService.formatDateToFrontEndFormat(this.viewedGig?.startDate);
    let formattedEndDate = this.utilitiesService.formatDateToFrontEndFormat(this.viewedGig?.endDate);

    this.createGigForm.get('dateRange').get('gigStartDate').setValue(new Date(formattedStartDate));
    this.createGigForm.get('dateRange').get('gigEndDate').setValue(new Date(formattedEndDate));


    this.minDate = new Date(formattedStartDate);
    this.minDate.setDate(this.minDate.getDate() + 1);

    this.createGigForm.get('gigAvailability').setValue(this.viewedGig?.timeCommitment);

    this.createGigForm.get('gigMobilityClassification').setValue(this.viewedGig?.gigWorkerMobilityClassification);
    this.createGigForm.get('gigLocation').setValue(this.viewedGig?.gigLocation);
    this.createGigForm.get('gigTeamMemberTotal').setValue(this.viewedGig?.noOfPositions);

    if (this.viewedGig?.gigFile) {
      this.selectedFile = this.viewedGig?.gigFile[0] as unknown as FileList;
    }

  }

  togglePreviewMode(): void {
    this.chosenCategory = this.createGigForm.get('gigCategory').value;
    this.chosenLocation = this.createGigForm.get('gigLocation').value;
    this.previewMode = !this.previewMode;
  }

  toggleGigVisibilityModal(searchingForEmployee?: boolean): void {

    this.activeSubscriptions.add(
      this.gigVisibilitySelection.changed.pipe(distinctUntilChanged()).subscribe((change) => {
        this.canSaveVisibilityChanges = true;
      })
    );

    if (!this.visibilityFilterModalOpen) {
      this.getGigVisibility(this.viewedGig.gigId);
    }

    //Workaround for de-modal prematurely closing when user hits enter in employee search

    this.activeSubscriptions.add(
      this.gigVisibilitySelection.changed.pipe(distinctUntilChanged()).subscribe((change) => {
        this.canSaveVisibilityChanges = true;
      })
    );

    this.activeSubscriptions.add(
      this.deModal.close.pipe(distinctUntilChanged()).subscribe((event: any) => {
        if (searchingForEmployee) {
          this.visibilityFilterModalOpen = true;
        }
        else {
          this.visibilityFilterModalOpen = false;
        }
      })
    );

    this.visibilityFilterModalOpen = !this.visibilityFilterModalOpen;
  }

  private _filterLocations(value: string): any {
    const filterValue = value.toLowerCase();
    return this.gigLocations.filter((location) => location.locationName.toLowerCase().includes(filterValue));
  }

  displayLocations(location: GigLocation): string {
    return location && location.locationName ? location.locationName : '';
  }

  submitGig(): void {

    this.toggleSubmitModal();

    let user: AuthenticatedUser = JSON.parse(sessionStorage.getItem('currentUser'));

    //Format dates
    let startDate = this.utilitiesService.formatDateToFrontEndFormat(this.createGigForm.get('dateRange').get('gigStartDate').value);
    let formattedStartDate = this.utilitiesService.formatToDatabaseDateFormat(startDate);

    let endDate = this.utilitiesService.formatDateToFrontEndFormat(this.createGigForm.get('dateRange').get('gigEndDate').value);
    let formattedEndDate = this.utilitiesService.formatToDatabaseDateFormat(endDate);

    let date = this.utilitiesService.formatDateToFrontEndFormat(new Date());
    let todaysDate = this.utilitiesService.formatToDatabaseDateFormat(date);

    this.chosenCategory = this.createGigForm.get('gigCategory').value;
    this.chosenLocation = this.createGigForm.get('gigLocation').value;

    let gigRequest = [{
      gigName: this.createGigForm.get('gigName').value,
      categoryId: (this.chosenCategory?.gigCategoryId) ? this.chosenCategory?.gigCategoryId : null,
      gigDescription: this.createGigForm.get('gigDescription').value,
      gigManager: user.idTokenClaims.employeeId,
      deliverables: (this.createGigForm.get('gigDeliverables').value) ? this.createGigForm.get('gigDeliverables').value : null,
      gigSkills: this.gigSkills,

      startDate: formattedStartDate,
      endDate: formattedEndDate,
      timeCommitment: (this.createGigForm.get('gigAvailability').value) ? this.createGigForm.get('gigAvailability').value : null,
      gigWorkerMobilityClassification: this.createGigForm.get('gigMobilityClassification').value,

      //Casting to number for payload
      noOfPositions: +this.createGigForm.get('gigTeamMemberTotal').value,
      locationId: this.chosenLocation ? this.chosenLocation.locationId : null,
      gigDuration: (this.createGigForm.get('gigDuration').value) ? this.createGigForm.get('gigDuration').value : null,

      //TODO: follow-up w/ backend to remove these required hardcoded fields
      gigId: (this.viewedGig && !this.viewedGig.isCloned) ? this.viewedGig.gigId : null,
      gigStatus: 'Created',
      gigUrl: 'https://impl.workday.com/dukeenergy5/d/inst/25060$1362/rel-task/2998$37648.htmld',
      workdayId: 'a793853706481032380ec0c70cd30000',
      completionDate: todaysDate,
      filledDate: todaysDate,
      saved: false,
      //If selectedFile exists, add file info to request
      ...(this.selectedFile && {gigFile: [
        {
          gigFileUri: this.isAdmin && this.fileURL ? this.fileURL : null,
          gigFileKey: this.isAdmin && this.fileURL ? this.selectedFile[0].name : null
        }
      ]})
    }];

    this.saveGigData(gigRequest);
  }
  uploadFile(gigRequest?: any) {

    if (this.selectedFile) {
      this.fileURL = null;
      const formData = new FormData();
      formData.append('filename', this.selectedFileName as File);
      const payload = {
        file: (this.selectedFileName as File).name
      }
      this.gigService.uploadFileInGig(formData, this.selectedFileName, payload).subscribe(
        (response) => {
          this.fileURL = response;
        },
        (error) => {
          throwError(error);
          this.utilitiesService.updateIsLoading(false);
          this.utilitiesService.showSnackbarMessage('warning', `There was an error uploading this file. Please try again.`, 6000, true);
        },
        () => {
          this.utilitiesService.updateIsLoading(false);
        });
    }
  }

  saveGigData(gigRequest: any) {

    this.activeSubscriptions.add(
      this.gigService.createGig(gigRequest).subscribe(
        (response) => {
          this.submittedGigForm = true;
          this.utilitiesService.showSnackbarMessage('success', 'Gig Submitted Successfully', 6000);
          this.navigateHome();
          this.utilitiesService.updateIsLoading(false);
        },
        (error: HttpErrorResponse) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', 'There was an error submitting this gig. Please try again later.', 6000, true);
          this.utilitiesService.updateIsLoading(false);
        })
    );
  }

  getGigLocations(): void {

    this.activeSubscriptions.add(
      this.gigService.getGigLocations().subscribe(
        (locations) => {
          this.gigLocations = locations;
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', 'There was an error retrieving gig locations. Please try again later.', 6000, true);
        },
        () => {
          this.utilitiesService.updateIsLoading(false);
        })
    );

  }

  getGigCategories(): void {

    this.activeSubscriptions.add(
      this.gigService.getGigCategories().subscribe(
        (categories) => {
          this.gigCategories = categories;
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', 'There was an error retrieving gig categories. Please try again later.', 6000, true);
        }, () => {
          this.utilitiesService.updateIsLoading(false);
        })
    );

  }

  getAllSkills(): void {

    this.activeSubscriptions.add(
      this.skillService.getAllSkills().subscribe(
        (skillList: PaginatedSkillList) => {
          this.allSkills = skillList.entries;
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', 'There was an error retrieving all skills. Please try again later.', 6000, true);
          this.utilitiesService.updateIsLoading(false);
        })
    );

  }

  navigateHome(): void {
    this.router.navigateByUrl('/home');
  }

  onRadioChange(event: MatRadioChange): void {
    if (event.value == 'remote') {
      this.chosenLocation = null;
      this.createGigForm.get('gigLocation').setValue(null);
      this.createGigForm.get('gigLocation').setValidators([])
      this.createGigForm.get('gigLocation').updateValueAndValidity();
    }

    else if (event.value == 'onsite' || event.value == 'hybrid') {
      this.createGigForm.get('gigLocation').setValidators([Validators.required])
      this.createGigForm.get('gigLocation').updateValueAndValidity();
    }
  }

  toggleSubmitModal(): void {
    this.showSubmitModal = !this.showSubmitModal;
  }

  togglePostGigModal(): void {
    this.showPostGigModal = !this.showPostGigModal;
  }

  toggleReviseGigModal(): void {
    this.showReviseGigModal = !this.showReviseGigModal;
  }

  //Used to prepopulate categories and locations fields
  compareCategoryObjects(object1: any, object2: any): boolean {
    return object1 && object2 && object1.gigCategoryId == object2.gigCategoryId;
  }

  compareLocations(object1: any, object2: any): boolean {
    return object1 && object2 && object1.locationId == object2.locationId;
  }

  //Used to prepopulate other fields such as time commitment, team members, etc.
  compareFn(object1: any, object2: any): boolean {
    return object1 && object2 && object1 == object2;
  }

  postGig(): void {

    this.togglePostGigModal();

    let user: AuthenticatedUser = JSON.parse(sessionStorage.getItem('currentUser'));

    //Format dates
    let startDate = this.utilitiesService.formatDateToFrontEndFormat(this.createGigForm.get('dateRange').get('gigStartDate').value);
    let formattedStartDate = this.utilitiesService.formatToDatabaseDateFormat(startDate);

    let endDate = this.utilitiesService.formatDateToFrontEndFormat(this.createGigForm.get('dateRange').get('gigEndDate').value);
    let formattedEndDate = this.utilitiesService.formatToDatabaseDateFormat(endDate);

    let date = this.utilitiesService.formatDateToFrontEndFormat(new Date());
    let todaysDate = this.utilitiesService.formatToDatabaseDateFormat(date);

    this.chosenCategory = this.createGigForm.get('gigCategory').value;
    this.chosenLocation = this.createGigForm.get('gigLocation').value;

    let gigPostRequest = {
      action: 'Approved',
      actionComment: 'Gig approved successfully'
    };
    // If admin makes a change to the gig, save their changes. Otherwise the gig will just be posted
    if (this.createGigForm.dirty || (this.viewedGig?.gigFile && (this.viewedGig?.gigFile[0] !== this.selectedFile))) {
      let gigRequest = [{
        gigName: this.createGigForm.get('gigName').value,
        categoryId: (this.chosenCategory?.gigCategoryId) ? this.chosenCategory?.gigCategoryId : null,
        gigDescription: this.createGigForm.get('gigDescription').value,
        gigManager: user.idTokenClaims.employeeId,
        deliverables: (this.createGigForm.get('gigDeliverables').value) ? this.createGigForm.get('gigDeliverables').value : null,
        gigSkills: this.gigSkills,

        startDate: formattedStartDate,
        endDate: formattedEndDate,
        timeCommitment: (this.createGigForm.get('gigAvailability').value) ? this.createGigForm.get('gigAvailability').value : null,
        gigWorkerMobilityClassification: this.createGigForm.get('gigMobilityClassification').value,

        //Casting to number for payload
        noOfPositions: +this.createGigForm.get('gigTeamMemberTotal').value,
        locationId: this.chosenLocation ? this.chosenLocation.locationId : null,
        gigDuration: (this.createGigForm.get('gigDuration').value) ? this.createGigForm.get('gigDuration').value : null,

        //TODO: follow-up w/ backend to remove these required hardcoded fields
        gigId: (this.viewedGig && !this.viewedGig.isCloned) ? this.viewedGig.gigId : null,
        gigStatus: 'Approved',
        gigUrl: 'https://impl.workday.com/dukeenergy5/d/inst/25060$1362/rel-task/2998$37648.htmld',
        workdayId: 'a793853706481032380ec0c70cd30000',
        completionDate: todaysDate,
        filledDate: todaysDate,
        saved: false,
        //If selectedFile exists, add file info to request
        ...(this.selectedFile && {gigFile: [
        {
          gigFileUri: this.isAdmin && this.fileURL ? this.fileURL : null,
          gigFileKey: this.isAdmin && this.fileURL ? this.selectedFile[0].name : null,
        }
      ]})
      }];


      this.activeSubscriptions.add(
        this.gigService.updateGigDraft(gigRequest).pipe(
          mergeMap(() => this.gigService.postGig(gigPostRequest, this.viewedGig.gigId))).subscribe(
            (success) => {
              this.submittedGigForm = true;
              this.utilitiesService.showSnackbarMessage('success', 'Gig Posted Successfully', 6000, true);
              this.router.navigateByUrl('/home');
            },
            (error: HttpErrorResponse) => {
              throwError(error);
              this.utilitiesService.showSnackbarMessage('warning', 'There was an error posting this gig. Please try again later.', 6000, true);
            },
            () => {
              this.utilitiesService.updateIsLoading(false);
            })
      );
    }

    else {
      this.activeSubscriptions.add(
        this.gigService.postGig(gigPostRequest, this.viewedGig.gigId).subscribe(
          (success) => {
            this.submittedGigForm = true;
            this.utilitiesService.showSnackbarMessage('success', 'Gig Posted Successfully', 6000, true);
            this.router.navigateByUrl('/home');
          },
          (error: HttpErrorResponse) => {
            throwError(error);
            this.utilitiesService.showSnackbarMessage('warning', 'There was an error posting this gig. Please try again later.', 6000, true);
          },
          () => {
            this.utilitiesService.updateIsLoading(false);
          })
      );
    }


  }

  reviseGig(): void {
    let request = {
      action: 'Request Change',
      actionComment: this.reviseGigComment
    };

    this.activeSubscriptions.add(
      this.gigService.postGig(request, this.viewedGig.gigId).subscribe(
        (success) => {
          this.submittedGigForm = true;
        },
        (error: HttpErrorResponse) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', 'There was an error sending this gig for revision. Please try again later.', 6000, true);
        },
        () => {
          this.utilitiesService.updateIsLoading(false);
        })
    );

  }

  saveGigDraftState(): void {
    if (this.viewedGig && this.viewedGig.gigStatus == 'Draft') {
      this.updateGigDraftState();
    }

    else {
      let user: AuthenticatedUser = JSON.parse(sessionStorage.getItem('currentUser'));

      //Format dates
      let startDate = this.utilitiesService.formatDateToFrontEndFormat(this.createGigForm.get('dateRange').get('gigStartDate').value);
      let formattedStartDate = this.utilitiesService.formatToDatabaseDateFormat(startDate);

      let endDate = this.utilitiesService.formatDateToFrontEndFormat(this.createGigForm.get('dateRange').get('gigEndDate').value);
      let formattedEndDate = this.utilitiesService.formatToDatabaseDateFormat(endDate);

      let date = this.utilitiesService.formatDateToFrontEndFormat(new Date());
      let todaysDate = this.utilitiesService.formatToDatabaseDateFormat(date);


      this.chosenCategory = this.createGigForm.get('gigCategory').value;
      this.chosenLocation = this.createGigForm.get('gigLocation').value;

      let gigRequest = [{
        gigName: this.createGigForm.get('gigName').value,
        categoryId: (this.chosenCategory?.gigCategoryId) ? this.chosenCategory?.gigCategoryId : null,
        gigDescription: this.createGigForm.get('gigDescription').value,
        gigManager: user.idTokenClaims.employeeId,
        deliverables: (this.createGigForm.get('gigDeliverables').value) ? this.createGigForm.get('gigDeliverables').value : null,
        gigSkills: this.gigSkills,

        startDate: formattedStartDate,
        endDate: formattedEndDate,
        timeCommitment: (this.createGigForm.get('gigAvailability').value) ? this.createGigForm.get('gigAvailability').value : null,
        gigWorkerMobilityClassification: this.createGigForm.get('gigMobilityClassification').value,

        // Casting to number for payload
        noOfPositions: +this.createGigForm.get('gigTeamMemberTotal').value,
        locationId: this.chosenLocation ? this.chosenLocation.locationId : null,
        gigDuration: (this.createGigForm.get('gigDuration').value) ? this.createGigForm.get('gigDuration').value : null,

        // TODO: follow-up w/ backend to remove these required hardcoded fields
        gigId: null,
        gigStatus: 'Approved',
        gigUrl: 'https://impl.workday.com/dukeenergy5/d/inst/25060$1362/rel-task/2998$37648.htmld',
        workdayId: 'a793853706481032380ec0c70cd30000',
        completionDate: todaysDate,
        filledDate: todaysDate,
        saved: false,
      }];

      this.activeSubscriptions.add(
        this.gigService.saveGigDraft(gigRequest).subscribe(
          () => {
            //TODO: get details of saved gig and save it here, so that update save gig can be implemented
            this.utilitiesService.updateIsLoading(false);
            this.navigateHome();
          },
          (error: HttpErrorResponse) => {
            throwError(error);
            this.utilitiesService.showSnackbarMessage('warning', 'There was an error saving this gig draft. Please try again later.', 6000, true);
            this.utilitiesService.updateIsLoading(false);
            this.navigateHome();
          })
      );

    }
  }



  updateGigDraftState(): void {
    let user: AuthenticatedUser = JSON.parse(sessionStorage.getItem('currentUser'));

    //Format dates
    let startDate = this.utilitiesService.formatDateToFrontEndFormat(this.createGigForm.get('dateRange').get('gigStartDate').value);
    let formattedStartDate = this.utilitiesService.formatToDatabaseDateFormat(startDate);

    let endDate = this.utilitiesService.formatDateToFrontEndFormat(this.createGigForm.get('dateRange').get('gigEndDate').value);
    let formattedEndDate = this.utilitiesService.formatToDatabaseDateFormat(endDate);

    let date = this.utilitiesService.formatDateToFrontEndFormat(new Date());
    let todaysDate = this.utilitiesService.formatToDatabaseDateFormat(date);

    this.chosenCategory = this.createGigForm.get('gigCategory').value;
    this.chosenLocation = this.createGigForm.get('gigLocation').value;

    let gigRequest = [{
      gigName: this.createGigForm.get('gigName').value,
      categoryId: (this.chosenCategory?.gigCategoryId) ? this.chosenCategory?.gigCategoryId : null,
      gigDescription: this.createGigForm.get('gigDescription').value,
      gigManager: (this.viewedGig?.gigManager.employeeId) ? this.viewedGig?.gigManager.employeeId : user.idTokenClaims.employeeId,
      deliverables: (this.createGigForm.get('gigDeliverables').value) ? this.createGigForm.get('gigDeliverables').value : null,
      gigSkills: this.gigSkills,

      startDate: formattedStartDate,
      endDate: formattedEndDate,
      timeCommitment: (this.createGigForm.get('gigAvailability').value) ? this.createGigForm.get('gigAvailability').value : null,
      gigWorkerMobilityClassification: this.createGigForm.get('gigMobilityClassification').value,

      // Casting to number for payload
      noOfPositions: +this.createGigForm.get('gigTeamMemberTotal').value,
      locationId: this.chosenLocation ? this.chosenLocation.locationId : null,
      gigDuration: (this.createGigForm.get('gigDuration').value) ? this.createGigForm.get('gigDuration').value : null,

      // TODO: follow-up w/ backend to remove these required hardcoded fields
      gigId: this.viewedGig?.gigId,
      gigStatus: 'Approved',
      gigUrl: 'https://impl.workday.com/dukeenergy5/d/inst/25060$1362/rel-task/2998$37648.htmld',
      workdayId: 'a793853706481032380ec0c70cd30000',
      completionDate: todaysDate,
      filledDate: todaysDate,
      saved: false,
    }];

    this.activeSubscriptions.add(
      this.gigService.updateGigDraft(gigRequest).subscribe(
        () => {
          this.utilitiesService.updateIsLoading(false);
          this.navigateHome();
        },
        (error: HttpErrorResponse) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', 'There was an error updating this gig draft. Please try again later.', 6000, true);
          this.utilitiesService.updateIsLoading(false);
          this.navigateHome();
        })
    );
  }

  //Called from skill field output
  skillFieldValidator(shownSkills: Skill[]) {
    this.createGigForm.get('gigSkills').setValue(shownSkills);
    this.onSubmitSkillsValidation = this.createGigForm.get('gigSkills').value?.length > 0 ? false : true;
    let duplicateSkills = shownSkills.filter((item, index) => shownSkills.indexOf(item) !== index);

    //Return error if no skills are selected, or if duplicate skills are selected
    if (!shownSkills.length || duplicateSkills.length) {
      this.createGigForm.get('gigSkills').setErrors(Validators.required);
    }
    else {
      this.createGigForm.get('gigSkills').setErrors(null);
    }
  }

  onCategoriesChange(categories: any) {
    this.imgBycat = this.getImgUrl(categories.value.gigCategoryName);
  }

  getByDefaultImg(val: any) {
    this.imgBycat = this.getImgUrl(val?.gigCategoryName);
  }

  getImgUrl(category: string) {

    switch (category) {
      case 'Networking': {
        return "../../../../assets/images/home/Networking.png";
        break;
      }
      case 'Reskilling': {
        return "../../../../assets/images/home/Reskilling.png";
        break;
      }
      case 'Skill Development': {
        return "../../../../assets/images/home/Skill Development.png";
        break;
      }
      case 'Skill Sharing': {
        return "../../../../assets/images/home/Skill-Sharing.png";
        break;
      }
      case 'Job Shadowing': {
        return "../../../../assets/images/home/Job-Shadowing.png";
        break;
      }
      case 'Committee or Special Activity': {
        return "../../../../assets/images/home/Committee_Special-Activity.png";
        break;
      }
      case 'Test Category': {
        return "../../../../assets/icons/phase_01_photo.png";
        break;
      }
      default:
        return "../../../../assets/icons/phase_01_photo.png";
    }
  }

  searchForEmployee(searchText?: string): void {
    //Workaround for de-modal prematurely closing when user hits enter in employee search
    this.toggleGigVisibilityModal(true);
    this.visibilityFilterModalOpen = true;

    this.isSpinnerLoading = true;

    this.activeSubscriptions.add(
      this.gigService.searchForEmployee(searchText).subscribe(
        (results: PaginatedEmployeeList) => {
          this.gigVisibilityDataSource.data = results.entries;
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', `There was an error retrieving list of employees. Please try again later.`, 6000, true);
        },
        () => {
          this.isSpinnerLoading = false;
        }
      )
    );
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.gigVisibilitySelection.selected.length;
    const numRows = this.gigVisibilityDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {

    if (this.isAllSelected()) {
      this.gigVisibilitySelection.clear();
      return;
    }

    this.gigVisibilitySelection.select(...this.gigVisibilityDataSource.data);
  }

  getGigVisibility(gigId: number): void {
    this.activeSubscriptions.add(
      this.gigService.getGigVisibility(gigId).subscribe(
        (gigVisibility: GigVisibility) => {
          if (gigVisibility.employees) {
            this.gigVisibilityDataSource.data = gigVisibility.employees;
            this.gigVisibilitySelection.select(...this.gigVisibilityDataSource.data);

          }
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', `There was an error retrieving this gig's visibility data. Please try again later.`, 6000, true);
        },
        () => {
          this.utilitiesService.updateIsLoading(false);
        })
    );
  }

  saveGigVisibility(): void {

    this.toggleGigVisibilityModal();

    let employeesSelected: GigVisibility = {
      gigId: this.viewedGig.gigId,
      employees: this.gigVisibilitySelection.selected
    };

    this.activeSubscriptions.add(
      this.gigService.saveGigVisibility(this.viewedGig.gigId, employeesSelected).subscribe(
        () => {
          this.utilitiesService.showSnackbarMessage('success', 'Gig Visibility Updated', 6000);
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', `There was an error updating this gig's visibility. Please try again later.`, 6000, true);
        },
        () => {
          this.utilitiesService.updateIsLoading(false);
          this.resetSelectionData();
        })
    );
  }

  //Clear table selections upon closing gig visibility modal
  resetSelectionData(): void {
    this.gigVisibilityDataSource.data = [];
    this.gigVisibilitySelection.clear();
    this.canSaveVisibilityChanges = false;
  }

  //set skill validation error while clicking on submit button
  validationError(): void {
    this.createGigForm.markAllAsTouched();
    this.onSubmitSkillsValidation = this.createGigForm.get('gigSkills').value?.length > 0 ? false : true;
  }

  // function to save new gig data
  saveGig(): void {
    if (this.createGigForm.valid) {

      //If editing a gig, use /save endpoint, otherwise create a new gig on submit
      if (this.viewedGig && !this.viewedGig?.isCloned) {
        this.updateGigDraftState();
      }
      else {
        this.toggleSubmitModal();
      }
    } else {
      this.validationError();
    }
  }
}
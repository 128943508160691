import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatChipSelectionChange } from '@angular/material/chips';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DeModalComponent } from '@de-electron/electron-angular-components';
import { Subscription, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged } from 'rxjs/operators';
import { AuthenticatedUser } from 'src/app/auth/models/authenticated-user';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { GigApplicationService } from 'src/app/services/gig-application/gig-application.service';
import { GigService } from 'src/app/services/gig/gig.service';
import { SkillService } from 'src/app/services/skills/skill.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { EmployeeSearchComponent } from 'src/app/shared/components/employee-search/employee-search.component';
import { Employee } from 'src/app/shared/interfaces/employee/employee.interface';
import { GigVisibility } from 'src/app/shared/interfaces/employee/gig-visibility.interface';
import { PaginatedEmployeeList } from 'src/app/shared/interfaces/employee/paginated-employee-list';
import { FilterChip } from 'src/app/shared/interfaces/filter-chip.interface';
import { GigApplication } from 'src/app/shared/interfaces/gig/gig-application.interface';
import { GigFile } from 'src/app/shared/interfaces/gig/gig-file.interface';
import { Gig } from 'src/app/shared/interfaces/gig/gig.interface';
import { SharedGig } from 'src/app/shared/interfaces/gig/shared-gig.interface';
import { EmployeeSkillList } from 'src/app/shared/interfaces/skills/employee-skill-list.interface';
import { Skill } from 'src/app/shared/interfaces/skills/skill.interface';

@Component({
  selector: 'app-view-gig',
  templateUrl: './view-gig.component.html',
  styleUrls: ['./view-gig.component.scss']
})
export class ViewGigComponent implements OnInit, AfterViewInit, OnDestroy {

  isManager: boolean;
  isAdmin: boolean;
  applicationId: number;

  user: AuthenticatedUser = JSON.parse(sessionStorage.getItem('currentUser'));

  applicantFilters: FilterChip[] = [
    {
      name: 'All',
      selected: false
    },
    {
      name: 'Applied',
      selected: true
    },
    {
      name: 'Recommended',
      selected: false
    }
  ];

  //Preselect to filter all gigs
  selectedApplicantFilter: FilterChip = this.applicantFilters[1];

  gigSkills: Skill[] = [];
  userSkills: Skill[] = [];
  wantedSkillsToDevelop: Skill[] = [];

  similarGigs: Gig[] = [];

  // similarGigs: Gig[] = [
  //   {
  //     "score": 0.94,
  //     "gigId": 1193,
  //     "gigName": "Josh\u0027s Test Gig with Josh\u0027s Skills",
  //     "gigDescription": "Testing",
  //     "gigUrl": "https://impl.workday.com/dukeenergy5/d/inst/25060$1362/rel-task/2998$37648.htmld",
  //     "timeCommitment": "1-5 hrs/wk",
  //     "gigDuration": "1 to 3 months",
  //     "startDate": "2023-08-12",
  //     "gigPostedDate": "2023-08-03 19:57:58.122041",
  //     "gigStatus": "In Recruiting",
  //     "gigWorkerMobilityClassification": "remote",
  //     "appliedToGig": false,
  //     "savedGig": false,
  //     "hostedGig": true,
  //     "gigManager": {
  //       "employeeId": "482450",
  //       "emailAddress": "Joshua.Bennett2@duke-energy.com",
  //       "firstName": "Joshua",
  //       "lastName": "Bennett",
  //       "jobTitle": "IT Software Engineer"
  //     },
  //     "gigLocation": {
  //       "locationId": 52,
  //       "locationName": "Brooksville FL Ops Complex",
  //       "addressLine1": "648 Harvard St",
  //       "city": "Brooksville",
  //       "stateCode": "FL",
  //       "postalCode": "34601",
  //       "isPrimary": true,
  //       "referenceId": "LC_FL0003"
  //     },
  //     "gigCategory": {
  //       "gigCategoryId": 34,
  //       "gigCategoryName": "Committee or Special Activity"
  //     },
  //     "gigSkills": [
  //       {
  //         "skillId": 11508,
  //         "skillName": "Cascading Style Sheets (CSS)"
  //       },
  //       {
  //         "skillId": 15370,
  //         "skillName": "JavaScript"
  //       },
  //       {
  //         "skillId": 13758,
  //         "skillName": "Hyper Text Markup Language (HTML)"
  //       },
  //       {
  //         "skillId": 20941,
  //         "skillName": "Angular"
  //       }
  //     ]
  //   },
  //   {
  //     "score": 0.86,
  //     "gigId": 1196,
  //     "gigName": "Today\u0027s gig in QA Aug 7th",
  //     "gigDescription": "Today\u0027s gig in QA Aug 7th",
  //     "gigUrl": "https://impl.workday.com/dukeenergy5/d/inst/25060$1362/rel-task/2998$37648.htmld",
  //     "timeCommitment": "10-20 hrs/wk",
  //     "gigDuration": "9 to 12 months",
  //     "startDate": "2023-08-07",
  //     "gigStatus": "Pending Approval",
  //     "gigWorkerMobilityClassification": "onsite",
  //     "appliedToGig": false,
  //     "savedGig": false,
  //     "hostedGig": false,
  //     "gigManager": {
  //       "employeeId": "512981",
  //       "emailAddress": "Rinki.Singh@duke-energy.com",
  //       "firstName": "Rinki",
  //       "lastName": "Singh",
  //       "jobTitle": "Lead IT Software Engineer"
  //     },
  //     "gigLocation": {
  //       "locationId": 182,
  //       "locationName": "Gallagher Gen Station (New Albany IN)",
  //       "addressLine1": "30 Jackson Street",
  //       "city": "New Albany",
  //       "stateCode": "IN",
  //       "postalCode": "47150",
  //       "isPrimary": true,
  //       "referenceId": "LC_IN0020"
  //     },
  //     "gigCategory": {
  //       "gigCategoryId": 37,
  //       "gigCategoryName": "Reskilling"
  //     },
  //     "gigSkills": [
  //       {
  //         "skillId": 23525,
  //         "skillName": "Terraform"
  //       },
  //       {
  //         "skillId": 5975,
  //         "skillName": "•\tExcellent leadership and motivational skills"
  //       }
  //     ]
  //   },
  //   {
  //     "score": 0.73,
  //     "gigId": 1200,
  //     "gigName": "TEST GREENING EMPLOYEE CHANGES 1",
  //     "gigDescription": "TEST GREENING EMPLOYEE CHANGES 1",
  //     "gigUrl": "https://impl.workday.com/dukeenergy5/d/inst/25060$1362/rel-task/2998$37648.htmld",
  //     "timeCommitment": "1-5 hrs/wk",
  //     "gigDuration": "1 to 3 months",
  //     "startDate": "2023-08-12",
  //     "gigPostedDate": "2023-08-08 02:31:46.048469",
  //     "gigStatus": "In Recruiting",
  //     "gigWorkerMobilityClassification": "remote",
  //     "appliedToGig": false,
  //     "savedGig": false,
  //     "hostedGig": false,
  //     "gigManager": {
  //       "employeeId": "453727",
  //       "emailAddress": "Chris.Greening@duke-energy.com",
  //       "firstName": "Chris",
  //       "lastName": "Greening",
  //       "jobTitle": "Developmental Assignment"
  //     },
  //     "gigLocation": {
  //       "locationId": 52,
  //       "locationName": "Brooksville FL Ops Complex",
  //       "addressLine1": "648 Harvard St",
  //       "city": "Brooksville",
  //       "stateCode": "FL",
  //       "postalCode": "34601",
  //       "isPrimary": true,
  //       "referenceId": "LC_FL0003"
  //     },
  //     "gigCategory": {
  //       "gigCategoryId": 34,
  //       "gigCategoryName": "Committee or Special Activity"
  //     },
  //     "gigSkills": [
  //       {
  //         "skillId": 11508,
  //         "skillName": "Cascading Style Sheets (CSS)"
  //       }
  //     ]
  //   },
  //   {
  //     "score": 0.73,
  //     "gigId": 1201,
  //     "gigName": "TEST GREENING EMPLOYEE CHANGES 100",
  //     "gigDescription": "TEST GREENING EMPLOYEE CHANGES 1",
  //     "gigUrl": "https://impl.workday.com/dukeenergy5/d/inst/25060$1362/rel-task/2998$37648.htmld",
  //     "timeCommitment": "1-5 hrs/wk",
  //     "gigDuration": "1 to 3 months",
  //     "startDate": "2023-08-12",
  //     "gigStatus": "Pending Approval",
  //     "gigWorkerMobilityClassification": "remote",
  //     "appliedToGig": false,
  //     "savedGig": false,
  //     "hostedGig": false,
  //     "gigManager": {
  //       "employeeId": "453727",
  //       "emailAddress": "Chris.Greening@duke-energy.com",
  //       "firstName": "Chris",
  //       "lastName": "Greening",
  //       "jobTitle": "Developmental Assignment"
  //     },
  //     "gigLocation": {
  //       "locationId": 52,
  //       "locationName": "Brooksville FL Ops Complex",
  //       "addressLine1": "648 Harvard St",
  //       "city": "Brooksville",
  //       "stateCode": "FL",
  //       "postalCode": "34601",
  //       "isPrimary": true,
  //       "referenceId": "LC_FL0003"
  //     },
  //     "gigCategory": {
  //       "gigCategoryId": 34,
  //       "gigCategoryName": "Committee or Special Activity"
  //     },
  //     "gigSkills": [
  //       {
  //         "skillId": 11508,
  //         "skillName": "Cascading Style Sheets (CSS)"
  //       }
  //     ]
  //   },
  //   {
  //     "score": 0.89,
  //     "gigId": 1204,
  //     "gigName": "TMP Testing - Android Developer",
  //     "gigDescription": "Learn how to do Android development on the employee mobile app (MyDukeEnergy).",
  //     "gigUrl": "https://impl.workday.com/dukeenergy5/d/inst/25060$1362/rel-task/2998$37648.htmld",
  //     "timeCommitment": "1-5 hrs/wk",
  //     "gigDuration": "3 to 6 months",
  //     "startDate": "2023-09-05",
  //     "gigPostedDate": "2023-08-08 18:44:32.273061",
  //     "gigStatus": "In Recruiting",
  //     "gigWorkerMobilityClassification": "remote",
  //     "appliedToGig": false,
  //     "savedGig": false,
  //     "hostedGig": false,
  //     "gigManager": {
  //       "employeeId": "277884",
  //       "emailAddress": "Amber.Smith@duke-energy.com",
  //       "firstName": "Amber",
  //       "lastName": "Smith",
  //       "jobTitle": "HR Digital Experience \u0026 Transformation Manager"
  //     },
  //     "gigCategory": {
  //       "gigCategoryId": 38,
  //       "gigCategoryName": "Skill Development"
  //     },
  //     "gigSkills": [
  //       {
  //         "skillId": 24760,
  //         "skillName": "Kotlin"
  //       },
  //       {
  //         "skillId": 63699,
  //         "skillName": "Kotlin Programming Language"
  //       },
  //       {
  //         "skillId": 62782,
  //         "skillName": "Android Technology"
  //       },
  //       {
  //         "skillId": 17028,
  //         "skillName": "Android Software Development"
  //       },
  //       {
  //         "skillId": 63409,
  //         "skillName": "Android User Interface"
  //       },
  //       {
  //         "skillId": 25789,
  //         "skillName": "Android APIs"
  //       }
  //     ]
  //   },
  //   {
  //     "score": 0.75,
  //     "gigId": 1233,
  //     "gigName": "TMP to Workday Testing for Security 3",
  //     "gigDescription": "Testing the security for the TMP pilot",
  //     "gigUrl": "https://impl.workday.com/dukeenergy5/d/inst/25060$1362/rel-task/2998$37648.htmld",
  //     "timeCommitment": "1-5 hrs/wk",
  //     "gigDuration": "1 to 3 months",
  //     "startDate": "2023-08-28",
  //     "gigPostedDate": "2023-08-28 13:30:54.712986",
  //     "gigStatus": "In Recruiting",
  //     "gigWorkerMobilityClassification": "remote",
  //     "appliedToGig": false,
  //     "savedGig": false,
  //     "hostedGig": false,
  //     "gigManager": {
  //       "employeeId": "277884",
  //       "emailAddress": "Amber.Smith@duke-energy.com",
  //       "firstName": "Amber",
  //       "lastName": "Smith",
  //       "jobTitle": "HR Digital Experience \u0026 Transformation Manager"
  //     },
  //     "gigCategory": {
  //       "gigCategoryId": 38,
  //       "gigCategoryName": "Skill Development"
  //     },
  //     "gigSkills": [
  //       {
  //         "skillId": 9699,
  //         "skillName": "Workday Security Configuration"
  //       },
  //       {
  //         "skillId": 20968,
  //         "skillName": "Workday Integration"
  //       },
  //       {
  //         "skillId": 10376,
  //         "skillName": "System Testing"
  //       }
  //     ]
  //   }
  // ]

  skillsPanelOpen: boolean = false;

  //For managers
  applicantFeedPanelOpen: boolean = false;
  activeApplicantsSelected: number = 0;
  isHostedGig: boolean = false;

  //For admins
  isPendingApproval: boolean = true;

  selectedApplicants: GigApplication[] = [];
  applicantPendingSelectionList: GigApplication[] = [];
  applicantInterviewList: GigApplication[] = [];
  selectedApplicant: GigApplication;

  // applicantInterviewList: GigApplication[] = [
  //   {
  //     gigApplicationId: 123,
  //     status: 'Invited To Interview',
  //     submittedDate: '2023-04-03',
  //     gigEmployee: {
  //         employeeId: '482450',
  //         firstName: 'Josh',
  //         lastName: 'Bennett',
  //         jobTitle: 'Sr Software Engineer'
  //     }
  //   },
  //   {
  //     gigApplicationId: 456,
  //     status: 'Invited To Interview',
  //     submittedDate: '2023-04-03',
  //     gigEmployee: {
  //         employeeId: '482450',
  //         firstName: 'Joshua',
  //         lastName: 'Bennett',
  //         jobTitle: 'Sr Software Engineer'
  //     }
  //   }
  // ];

  applicantFeed: GigApplication[] = [];

  shareGigModalOpen: boolean = false;
  cancelGigModelOpen: boolean = false;
  closeGigModelOpen: boolean = false;
  withdrawGigModelOpen: boolean = false;

  viewedGig: Gig;

  applyButtonText: string = 'APPLY';
  savedText: string = 'SAVE';
  appliedToGig: boolean = false;

  interviewPopUp = false;
  selectionPopUp = false;

  inactiveGigStatuses: string[] = ['Completed', 'Cancelled', 'Draft', 'Pending Approval', 'Closed', 'Fully Staffed'];

  //Show basic gig details for gigs with these statuses
  showGigDetailsStatuses: string[] = ['Canceled', 'Draft', 'Pending Approval'];

  //Only show applicant feed for gigs with these statuses
  feedApplicantStatus: string[] = ['In Recruiting', 'Approved', 'Fully Staffed', 'Completed', 'Closed'];

  shareGigForm = new UntypedFormGroup({
    emailAddress: new UntypedFormControl('', Validators.required),
    employeeId: new UntypedFormControl('', Validators.required),
    message: new UntypedFormControl('', Validators.required),
  });

  @ViewChild('deModal') deModal: DeModalComponent;

  gigVisibilitySelection = new SelectionModel<Employee>(true, []);

  //For Gig Visibility modal
  visibilityFilterModalOpen: boolean = false;

  gigVisibilityColumns = ['employeeName', 'employeeId', 'select'];
  gigVisibilityDataSource = new MatTableDataSource<Employee>();

  canSaveVisibilityChanges: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  isSpinnerLoading: boolean = false;

  gigVisibilityMessage: string = '';

  activeSubscriptions: Subscription = new Subscription();

  @ViewChild('employeeSearch') employeeSearch: EmployeeSearchComponent;

  constructor(private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private gigService: GigService,
    private gigApplicationService: GigApplicationService,
    private utilitiesService: UtilitiesService,
    private skillService: SkillService) {
    if (this.router.getCurrentNavigation()?.extras.state) {
      let gig: Gig = this.router.getCurrentNavigation().extras.state;
      this.getGigDetails(gig.gigId);
    }
    else {
      this.getGigDetails(+this.route.snapshot.paramMap.get('id'));
    }
  }

  ngOnInit(): void {
    this.authService.getRoles().then((roleList) => {
      this.isAdmin = this.authService.isUserAdmin(roleList);
      this.isManager = this.authService.isUserManager(roleList);

      this.getRecommendedGigs();
      this.getEmployeeSkills();
    });
  }

  ngAfterViewInit(): void {
    this.gigVisibilityDataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.activeSubscriptions.unsubscribe();
  }

  onSelectedApplicantFilter($event: MatChipSelectionChange, applicantFilter: FilterChip) {
    if (applicantFilter.name !== this.selectedApplicantFilter.name) {
      applicantFilter.selected = $event.selected;

      if (applicantFilter.selected == true) {
        this.selectedApplicantFilter = applicantFilter;
      }
    }
  }

  interviewApplicant(): void {

    this.activeSubscriptions.add(
      this.gigApplicationService.inviteApplicanttoInterview(this.viewedGig.gigId, this.selectedApplicant.gigApplicationId).subscribe(
        () => {
          this.getGigDetails(this.viewedGig?.gigId);
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', `There was an error interviewing this applicant. Please try again later.`, 6000, true);
        },
        () => {
          this.utilitiesService.updateIsLoading(false);
        })
    );

    this.toggleInterviewModal();
  }

  toggleInterviewModal(applicant?: GigApplication): void {
    if (applicant) {
      this.selectedApplicant = applicant;
    }
    this.interviewPopUp = !this.interviewPopUp;
  }

  toggleSelectModal(applicant?: GigApplication): void {
    if (applicant) {
      this.selectedApplicant = applicant;
    }

    this.selectionPopUp = !this.selectionPopUp;
  }


  selectApplicantFromInterviewList(): void {

    this.activeSubscriptions.add(
      this.gigApplicationService.selectGigApplicant(this.viewedGig.gigId, this.selectedApplicant.gigApplicationId).subscribe(
        () => {

          this.getGigDetails(this.viewedGig?.gigId);
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', `There was an error interviewing this applicant. Please try again later.`, 6000, true);
        },
        () => {
          this.utilitiesService.updateIsLoading(false);
        })
    );
    this.toggleSelectModal();
  }

  deselectApplicant(applicant: GigApplication): void {

    this.activeSubscriptions.add(
      this.gigApplicationService.deselectApplicant(this.viewedGig.gigId, applicant.gigApplicationId).subscribe(
        (success) => {
          this.getGigDetails(this.viewedGig?.gigId);
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', `There was an error deselecting this applicant. Please try again later.`, 6000, true);
        },
        () => {
          this.utilitiesService.updateIsLoading(false);
        })
    );
  }

  viewEmployeeProfile(applicant: GigApplication): void {
    this.router.navigateByUrl('/view-profile', { state: applicant as GigApplication });
  }

  toggleShareGigModal(): void {
    this.shareGigModalOpen = !this.shareGigModalOpen;

    if (!this.shareGigModalOpen) {
      this.shareGigForm.reset();
      this.employeeSearch.employeeControl.reset();
      //Reset search filter results
      this.employeeSearch.filteredEmployees = of([]);
    }
  }

  toggleCancelGigModal(): void {
    this.cancelGigModelOpen = !this.cancelGigModelOpen;
  }

  cancelGigMessage: string = '';

  cancelGig(): void {

    let request = {
      message: this.cancelGigMessage
    };

    this.activeSubscriptions.add(
      this.gigApplicationService.cancelGig(this.viewedGig.gigId, request).subscribe(
        (success) => {
          this.utilitiesService.showSnackbarMessage('success', `Gig Successfully Cancelled`, 6000);
          this.router.navigateByUrl('/home');
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', `There was an error cancelling this gig. Please try again later.`, 6000, true);
        },
        () => {
          this.utilitiesService.updateIsLoading(false);
        })
    );

    this.toggleCancelGigModal();
  }

  getGigDetails(gigId: number): void {
    this.applicantFeed = [];
    this.applicantInterviewList = [];
    this.applicantPendingSelectionList = [];
    this.selectedApplicants = [];

    this.activeSubscriptions.add(
      this.gigService.getGigDetails(gigId).subscribe(
        (gigWithDetails: Gig) => {
          this.viewedGig = gigWithDetails;

          if (this.viewedGig.gigFile) {
            // this.getGigFileUrl(this.viewedGig.gigFile[0].gigFileKey);
            // this.getGigFileUrl('71027a89-a20d-40f7-b197-68e97e635afb');
          }

          this.cancelGigMessage = `${this.viewedGig.gigName} that you applied to was canceled because the resource is no longer needed. Thank you for expressing interest in the gig. You can continue to review Talent Marketplace for other gigs.`
          if (this.viewedGig?.gigSkill) {
            for (let skill of this.viewedGig?.gigSkill) {
              this.gigSkills.push(skill);
            }
          }

          if (this.isManager && this.viewedGig?.gigManager.employeeId == this.user.idTokenClaims.employeeId) {
            this.isHostedGig = true;
          }

          if (this.viewedGig.savedGig) {
            this.savedText = "UNSAVE";
          } else {
            this.savedText = "SAVE";
          }

          if (this.viewedGig?.gigApplication) {
            for (let gigApplication of this.viewedGig?.gigApplication) {

              if (this.isHostedGig || this.isAdmin) {

                this.skillService.highlightEmployeeSkillOverlap(this.gigSkills, gigApplication?.gigEmployee.skills, gigApplication);

                switch (gigApplication.status) {
                  case 'Applied':
                    this.applicantFeed.push(gigApplication);
                    break;

                  case 'Rejected - Manager Declined':
                    this.applicantInterviewList.push(gigApplication);
                    break;

                  case 'Rejected - Position Filled':
                    this.applicantFeed.push(gigApplication);
                    break;

                  case 'Rejected - Opted Out':
                    this.applicantInterviewList.push(gigApplication);
                    break;

                  case 'Invited To Interview':
                    this.applicantInterviewList.push(gigApplication);
                    break;

                  case 'Pending Selection':
                    this.applicantPendingSelectionList.push(gigApplication);
                    break;

                  case 'Waiting for manager approval':
                    this.applicantPendingSelectionList.push(gigApplication);
                    break;

                  case 'Waiting for employee approval':
                    this.applicantPendingSelectionList.push(gigApplication);
                    break;

                  case 'Approved':
                    this.applicantPendingSelectionList.push(gigApplication);
                    break;

                  case 'Selected':
                    this.selectedApplicants.push(gigApplication);
                    break;
                }

              }

              if (gigApplication.gigEmployee.employeeId == this.user.idTokenClaims.employeeId && gigApplication.status != "Withdrawn") {
                this.applyButtonText = 'WITHDRAW';
                this.applicationId = gigApplication.gigApplicationId;
              }
            }
          }
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', `There was an error retrieving this gig's details. Please try again later.`, 6000, true);
        },
        () => {
          setTimeout(() => {
            this.utilitiesService.updateIsLoading(false);
          }, 1000)
        })
    );

  }

  applyToGig(): void {
    if (this.applyButtonText == 'APPLY') {

      this.activeSubscriptions.add(
        this.gigApplicationService.applyToGig(this.viewedGig.gigId).subscribe(
          () => {
            this.applyButtonText = 'WITHDRAW';
            this.appliedToGig = true;
            this.utilitiesService.showSnackbarMessage('success', `Gig Application Submitted`, 6000);
            this.router.navigateByUrl('/home');
          },
          (error) => {
            throwError(error);
            this.utilitiesService.showSnackbarMessage('warning', `There was an error applying to this gig. Please try again later.`, 6000, true);
          },
          () => {
            this.utilitiesService.updateIsLoading(false);
          })
      );

    }
    else if (this.applyButtonText == 'WITHDRAW') {
      this.withdrawGigModel();
    }
  }

  withdrawGig(): void {
    this.withdrawGigModel();
    this.activeSubscriptions.add(
      this.gigApplicationService.withdrawGig(this.viewedGig.gigId, this.applicationId).subscribe(
        () => {
          this.applyButtonText = 'APPLY';
          this.appliedToGig = true;
          this.utilitiesService.showSnackbarMessage('success', `Gig Application Successfuly Withdrawn`, 6000);
          this.router.navigateByUrl('/home');
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', `There was an error withdrawing your application. Please try again later.`, 6000, true);
        },
        () => {
          this.utilitiesService.updateIsLoading(false);
        })
    );
  }

  modifyGig(): void {
    this.router.navigateByUrl('/create-gig', { state: this.viewedGig as Gig });
  }

  cloneGig(): void {
    this.viewedGig.isCloned = true;
    this.router.navigateByUrl('/create-gig', { state: this.viewedGig as Gig });
  }

  closeGigModel(): void {
    this.closeGigModelOpen = !this.closeGigModelOpen;
  }

  closeGig(): void {
    this.closeGigModel();
    this.activeSubscriptions.add(
      this.gigService.closeGig(this.viewedGig.gigId).subscribe(
        (success) => {
          this.getGigDetails(this.viewedGig.gigId);
        },
        (error) => {
          throwError(error);
        },
        () => {
          this.utilitiesService.updateIsLoading(false);
        })
    );
  }

  withdrawGigModel(): void {
    this.withdrawGigModelOpen = !this.withdrawGigModelOpen;
  }

  viewGig(gigData: Gig): void {
    this.router.navigateByUrl(`/view-gig/${gigData.gigId}`, { state: gigData as Gig });
  }

  getRecommendedGigs(): void {

    this.activeSubscriptions.add(
      this.gigService.getRecommendedGigs(this.user.idTokenClaims.employeeId).subscribe(
        (recommendations: Gig[]) => {
          this.similarGigs = recommendations;
        },
        (error) => {
          throwError(error);
        },
        () => {
          this.utilitiesService.updateIsLoading(false);
        })
    );

  }

  getImgUrl(categories: string) {

    switch (categories) {
      case 'Networking': {
        return "../../../../assets/images/home/Networking.png";
        break;
      }
      case 'Reskilling': {
        return "../../../../assets/images/home/Reskilling.png";
        break;
      }
      case 'Skill Development': {
        return "../../../../assets/images/home/Skill Development.png";
        break;
      }
      case 'Skill Sharing': {
        return "../../../../assets/images/home/Skill-Sharing.png";
        break;
      }
      case 'Job Shadowing': {
        return "../../../../assets/images/home/Job-Shadowing.png";
        break;
      }
      case 'Committee or Special Activity': {
        return "../../../../assets/images/home/Committee_Special-Activity.png";
        break;
      }
      case 'Test Category': {
        return "../../../../assets/icons/phase_01_photo.png";
        break;
      }
      default:
        return "../../../../assets/icons/phase_01_photo.png";
    }
  }

  addSkillToSkillsToDevelop(skillSelected: Skill): void {
    this.wantedSkillsToDevelop.push(skillSelected);

    let request: EmployeeSkillList = {
      employeeId: this.user.idTokenClaims.employeeId,
      skills: this.userSkills,
      skillInterests: this.wantedSkillsToDevelop
    };

    this.activeSubscriptions.add(
      this.skillService.addEmployeeSkillsOrInterests(request).subscribe(
        (success) => {
          this.utilitiesService.showSnackbarMessage('success', 'Skill Interests Updated', 6000);
        },
        (error) => {
          throwError(error);
        },
        () => {
          this.utilitiesService.updateIsLoading(false);
        })
    );

  }

  getEmployeeSkills(): void {
    this.activeSubscriptions.add(
      this.skillService.getEmployeeSkills(this.user.idTokenClaims.employeeId).subscribe(
        (skillList: EmployeeSkillList) => {
          this.userSkills = skillList.skills;
          this.wantedSkillsToDevelop = skillList.skillInterests;
        },
        (error) => {
          throwError(error);
        })
    );
  }

  saveGigReq: any = [];

  saveGig(): void {
    this.saveGigReq.push({ gigId: this.viewedGig.gigId });

    this.activeSubscriptions.add(
      this.gigApplicationService.savedGig(this.saveGigReq).subscribe(
        () => {
          this.utilitiesService.showSnackbarMessage('success', `Gig Saved`, 6000);
          this.router.navigateByUrl('/home');
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', `There was an error applying to this gig. Please try again later.`, 6000, true);
        },
        () => {
          this.utilitiesService.updateIsLoading(false);
        })
    );

  }

  navigateHome(): void {
    this.router.navigateByUrl('/home');
  }

  selectEmployeeToShareGig(employee: Employee): void {
    this.shareGigForm.get('emailAddress').setValue(employee.emailAddress);
    this.shareGigForm.get('employeeId').setValue(employee.employeeId);
  }

  shareGig(): void {

    //Passing as array for now, due to possibility of being able to share gigs with multiple people in the future
    let sharedGig: SharedGig[] = [{
      emailAddress: this.shareGigForm.get('emailAddress').value,
      employeeId: this.shareGigForm.get('employeeId').value,
      message: this.shareGigForm.get('message').value
    }];

    this.activeSubscriptions.add(
      this.gigService.shareGig(this.viewedGig.gigId, sharedGig).subscribe(
        () => {
          this.utilitiesService.showSnackbarMessage('success', `Gig Shared Successfully`, 6000);
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', `There was an error sharing this gig. Please try again later.`, 6000);
        },
        () => {
          this.utilitiesService.updateIsLoading(false);
          this.toggleShareGigModal();
        })
    );
  }

  searchForEmployee(searchText?: string): void {
    //Workaround for de-modal prematurely closing when user hits enter in employee search
    this.toggleGigVisibilityModal(true);
    this.visibilityFilterModalOpen = true;

    this.isSpinnerLoading = true;

    this.activeSubscriptions.add(
      this.gigService.searchForEmployee(searchText).subscribe(
        (results: PaginatedEmployeeList) => {
          this.gigVisibilityDataSource.data = results.entries;
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', `There was an error retrieving list of employees. Please try again later.`, 6000, true);
        },
        () => {
          this.isSpinnerLoading = false;
        })
    );
  }

  toggleGigVisibilityModal(searchingForEmployee?: boolean): void {

    this.activeSubscriptions.add(
      this.gigVisibilitySelection.changed.pipe(distinctUntilChanged()).subscribe((change) => {
        this.canSaveVisibilityChanges = true;
      })
    );

    if (!this.visibilityFilterModalOpen) {
      this.getGigVisibility(this.viewedGig.gigId);
    }

    //Workaround for de-modal prematurely closing when user hits enter in employee search
    this.activeSubscriptions.add(
      this.deModal.close.pipe(distinctUntilChanged()).subscribe((event: any) => {
        if (searchingForEmployee) {
          this.visibilityFilterModalOpen = true;
        }
        else {
          this.visibilityFilterModalOpen = false;
        }
      })
    );

    this.visibilityFilterModalOpen = !this.visibilityFilterModalOpen;
  }

  getGigVisibility(gigId: number): void {

    this.activeSubscriptions.add(
      this.gigService.getGigVisibility(gigId).subscribe(
        (gigVisibility: GigVisibility) => {
          if (gigVisibility.employees.length) {
            this.gigVisibilityDataSource.data = gigVisibility.employees;
            this.gigVisibilitySelection.select(...this.gigVisibilityDataSource.data);

            //Hide selection column for managers viewing gig visibility
            if (!this.isAdmin) {
              let selectColumnExists = this.gigVisibilityColumns.find((column) => column === 'select');

              if (selectColumnExists) {
                this.gigVisibilityColumns.pop();
              }
            }
          }

          else {
            this.gigVisibilityMessage = 'This gig is currently visible to every employee.';
          }
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', `There was an error retrieving this gig's visibility data. Please try again later.`, 6000, true);
        },
        () => {
          this.utilitiesService.updateIsLoading(false);
        })
    );
  }

  saveGigVisibility(): void {

    this.toggleGigVisibilityModal();

    let employeesSelected: GigVisibility = {
      gigId: this.viewedGig.gigId,
      employees: this.gigVisibilitySelection.selected
    };

    this.activeSubscriptions.add(
      this.gigService.saveGigVisibility(this.viewedGig.gigId, employeesSelected).subscribe(
        () => {
          this.utilitiesService.showSnackbarMessage('success', 'Gig Visibility Updated', 6000);
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', `There was an error updating this gig's visibility. Please try again later.`, 6000, true);
        },
        () => {
          this.utilitiesService.updateIsLoading(false);
          this.resetSelectionData();
        })
    );
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.gigVisibilitySelection.selected.length;
    const numRows = this.gigVisibilityDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {

    if (this.isAllSelected()) {
      this.gigVisibilitySelection.clear();
      return;
    }

    this.gigVisibilitySelection.select(...this.gigVisibilityDataSource.data);
  }

  //Clear table selections upon closing gig visibility modal
  resetSelectionData(): void {
    this.gigVisibilityDataSource.data = [];
    this.gigVisibilitySelection.clear();
    this.canSaveVisibilityChanges = false;
  }

  getGigFileUrl(fileKeyName: string): void {
    this.gigService.downloadGigFile(fileKeyName).subscribe(
      (fileInfo: GigFile[]) => {
        window.open(fileInfo[0].gigFileUri);
      },
      (error) => {
        throwError(error);
      });
  }

  viewFile(file: GigFile): void {
    //Isolate the file key from the file URI string
    let urlParts = file.gigFileUri.split('/');
    let fileKey = urlParts[3];
    this.getGigFileUrl(fileKey);
  }
}
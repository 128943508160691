import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DrawerGreeting, HeaderLink } from '@de-electron/electron-angular-components';
import { Subscription, catchError, distinctUntilKeyChanged, skip, throwError } from 'rxjs';
import { AuthenticatedUser } from './auth/models/authenticated-user';
import { AuthenticationService } from './auth/services/authentication.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Icons } from '../assets/icons/icons';
import { UtilitiesService } from './services/utilities.service';
import { UnionStatus } from './shared/interfaces/employee/union-status.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  activeSubscriptions: Subscription = new Subscription();

  user: AuthenticatedUser;
  userRoleList: string[];

  //Header links
  headerLinks: HeaderLink[] = [];
  drawerLinks: HeaderLink[] = [];

  homeLink: HeaderLink = { viewValue: 'Home', value: 'home', showUserIcon: false };
  signOutLink: HeaderLink = { viewValue: 'Sign Out', value: 'sign-out' };

  //For employees
  viewProfileLink: HeaderLink = { viewValue: 'My Profile', value: 'view-profile' };
  //for admin
  gigReportLink: HeaderLink = { viewValue: 'Gig Report', value: 'gig-report' };

  chatLink: HeaderLink = { viewValue: 'Chat', value: 'chat', iconSvgHref: '../assets/icons/chat/chat.svg#chat-icon', showUserIcon: false };

  drawerGreeting: DrawerGreeting;

  feedbackModalOpen: boolean = false;
  isAdmin: boolean = false;

  icons = Icons;

  constructor(private authService: AuthenticationService, public router: Router, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, private utilitiesService: UtilitiesService) {
    this.addSvgIcons(iconRegistry, sanitizer);
  }

  ngOnInit(): void {

    this.activeSubscriptions.add(
      this.authService.currentUser$.pipe(skip(1), distinctUntilKeyChanged('name')).subscribe((user: AuthenticatedUser) => {
        this.user = user;
        this.userRoleList = this.user?.idTokenClaims.roles;
        this.isAdmin = this.authService.isUserAdmin(this.userRoleList);
        sessionStorage.setItem('currentUser', JSON.stringify(this.user));
        this.checkForUnionWorkerOrContractor();
      },
        (error) => {
          catchError(error);
        })
    );
  }

  ngOnDestroy(): void {
    this.activeSubscriptions.unsubscribe();
  }

  onNavLinkClick(headerLink: HeaderLink): void {
    switch (headerLink.value) {
      //navigation to sublinks
      case 'home': {
        this.router.navigateByUrl('home');
        break;
      }
      case 'view-profile': {
        this.router.navigateByUrl('view-profile');
        break;
      }
      case 'sign-out': {
        this.authService.signOut();
        break;
      }
      case 'gig-report': {
        this.router.navigateByUrl('gig-report');
        break;
      }
      case 'chat': {
        this.router.navigateByUrl('chat');
        break;
      }
    }
  }

  checkForUnionWorkerOrContractor(): void {

    if (this.authService.isUserContractor(this.userRoleList)) {
      this.navigateToUnauthorized();
    }

    else {
      this.authService.isUserUnionEmployee(this.user?.idTokenClaims.employeeId).subscribe(
        (userInfo: UnionStatus) => {

          if (userInfo.isUnionWorker) {
            this.navigateToUnauthorized();
          }

          else {
            this.headerLinks = [
              this.chatLink,
              this.homeLink,
              { viewValue: `${this.user?.idTokenClaims.name}`, value: 'user', showUserIcon: true, subLinks: this.generateHeaderSubLinks() }
            ];

            this.drawerGreeting = {
              viewValue: `${this.user?.idTokenClaims.name}`
            };

            this.drawerLinks = this.generateDrawerLinks();
          }
        },
        (error) => {
          throwError(error);
        }
      );
    }

  }

  navigateToUnauthorized(): void {
    this.headerLinks = [];
    this.router.navigateByUrl('/unauthorized');
    this.utilitiesService.updateIsLoading(false);
    sessionStorage.removeItem('currentUser')
  }

  navigateHome(): void {
    this.router.navigateByUrl('home');
  }

  private generateHeaderSubLinks(): HeaderLink[] {
    let subLinks: HeaderLink[] = [];
    subLinks.push(this.viewProfileLink);
    if (this.isAdmin) {
      subLinks.push(this.gigReportLink);
    }
    subLinks.push(this.signOutLink);

    return subLinks;
  }

  private generateDrawerLinks(): HeaderLink[] {
    let drawerLinks: HeaderLink[] = [];
    drawerLinks.push(this.homeLink);
    drawerLinks.push(this.viewProfileLink);
    if (this.isAdmin) {
      drawerLinks.push(this.gigReportLink);
    }
    drawerLinks.push(this.signOutLink);
    return drawerLinks;
  }

  private addSvgIcons(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    this.icons.forEach(icon => {
      iconRegistry.addSvgIcon(
        `${icon['iconName']}`,
        sanitizer.bypassSecurityTrustResourceUrl(`${icon['iconPath']}`)
      );
    });
  }

  toggleFeedbackModal(): void {
    this.feedbackModalOpen = !this.feedbackModalOpen;
  }

}

export const Icons = [
    {
        iconName: 'filter',
        iconPath: './assets/icons/general/filter.svg'
    },
    {
        iconName: 'arrow_solid_down_base',
        iconPath: './assets/icons/general/arrow_solid_down_base.svg'
    },
    {
        iconName: 'add',
        iconPath: './assets/icons/general/add.svg'
    },
    {
        iconName: 'cancel',
        iconPath: './assets/icons/general/cancel.svg'
    },
    {
        iconName: 'saved',
        iconPath: './assets/icons/general/saved.svg'
    },
    {
        iconName: 'rating_1',
        iconPath: './assets/icons/general/rating_1.svg'
    },
    {
        iconName: 'rating_2',
        iconPath: './assets/icons/general/rating_2.svg'
    },
    {
        iconName: 'rating_3',
        iconPath: './assets/icons/general/rating_3.svg'
    },
    {
        iconName: 'rating_4',
        iconPath: './assets/icons/general/rating_4.svg'
    },
    {
        iconName: 'rating_5',
        iconPath: './assets/icons/general/rating_5.svg'
    },
    {
        iconName: 'search',
        iconPath: './assets/icons/general/search.svg'
    },
    {
        iconName: 'close',
        iconPath: './assets/icons/general/close.svg'
    },
    {
        iconName: 'send',
        iconPath: './assets/icons/chat/send.svg'
    }
]
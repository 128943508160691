export class Message {
    constructor(public author?: string, public content?: string | InteractiveMessage[], public responseType?: string, public showAvatar?: boolean) {
    }
    contentType?: string;
    inputText?: string;
    imageResponseCard?: null;
}

export enum MessageCreator {
    Bot = 'bot',
    User = 'user'
}

export enum ResponseType {
    Readonly = 'readonly',
    Interactive = 'interactive'
}

export interface ChatMessage {
    content: string | InteractiveMessage[];
    createdBy: MessageCreator;
    responseType?: ResponseType;
}

export interface InteractiveMessage {
    disabled?: boolean;
    text: string;
    value: string;
}
export const environments = {
        local: {
            production: false,
            envName: 'local',
            apiBaseUrl: 'https://talentmarketplace-api-dev.duke-energy.app',
            scopes: ['api://94952bd2-9120-4bf6-a6c7-fe2a04497dcf/Assets.Read'],
            azureClientId: '94952bd2-9120-4bf6-a6c7-fe2a04497dcf',
            azureTenant: '2ede383a-7e1f-4357-a846-85886b2c0c4d',
            redirectUrl: 'http://localhost:4200',
            postLogoutRedirectUri: 'https://dukeenergy.sharepoint.com/sites/portal_home/_layouts/15/SignOut.aspx'
        },
        sbx: {
            production: false,
            envName: 'sbx',
            apiBaseUrl: 'https://talentmarketplace-api-sbx.duke-energy.app',
            scopes: ['api://94952bd2-9120-4bf6-a6c7-fe2a04497dcf/Assets.Read'],
            azureClientId: '94952bd2-9120-4bf6-a6c7-fe2a04497dcf',
            azureTenant: '2ede383a-7e1f-4357-a846-85886b2c0c4d',
            redirectUrl: 'https://talentmarketplace-sbx.duke-energy.app',
            postLogoutRedirectUri: 'https://dukeenergy.sharepoint.com/sites/portal_home/_layouts/15/SignOut.aspx'
        },
        dev: {
            production: false,
            envName: 'dev',
            apiBaseUrl: 'https://talentmarketplace-api-dev.duke-energy.app',
            scopes: ['api://94952bd2-9120-4bf6-a6c7-fe2a04497dcf/Assets.Read'],
            azureClientId: '94952bd2-9120-4bf6-a6c7-fe2a04497dcf',
            azureTenant: '2ede383a-7e1f-4357-a846-85886b2c0c4d',
            redirectUrl: 'https://talentmarketplace-dev.duke-energy.app',
            postLogoutRedirectUri: 'https://dukeenergy.sharepoint.com/sites/portal_home/_layouts/15/SignOut.aspx'
        },
        qa: {
            production: false,
            envName: 'qa',
            apiBaseUrl: 'https://talentmarketplace-api-qa.duke-energy.app',
            scopes: ['api://d1eae41b-e964-49b3-b45d-bb1a447e4b9b/Assets.Read'],
            azureClientId: 'd1eae41b-e964-49b3-b45d-bb1a447e4b9b',
            azureTenant: '2ede383a-7e1f-4357-a846-85886b2c0c4d',
            redirectUrl: 'https://talentmarketplace-qa.duke-energy.app',
            postLogoutRedirectUri: 'https://dukeenergy.sharepoint.com/sites/portal_home/_layouts/15/SignOut.aspx'
        },
        prod: {
            production: true,
            envName: 'prod',
            apiBaseUrl: 'https://talentmarketplace-api.duke-energy.app',
            scopes: ['api://dcfd6498-a2f3-47a7-ba7c-d675f8aa11ea/Assets.Read'],
            azureClientId: 'dcfd6498-a2f3-47a7-ba7c-d675f8aa11ea',
            azureTenant: '2ede383a-7e1f-4357-a846-85886b2c0c4d',
            redirectUrl: 'https://talentmarketplace.duke-energy.app',
            postLogoutRedirectUri: 'https://dukeenergy.sharepoint.com/sites/portal_home/_layouts/15/SignOut.aspx'
        }
};

<!-- TODO: Refactor home and my profile templates to app component -->
<div class="main-page-container">
    <mat-card class="notifications-container">
        <tmp-notifications></tmp-notifications>
    </mat-card>
    <mat-card class="main-content-container">
        <div class="feed-title">
            <h1><span *ngIf="!previewMode">My</span> Profile</h1>
        </div>
          <mat-card-content *ngIf="previewMode">
            <div class="employee-name-container-top">

              <div class="employee-info-wrapper">
                <div mat-card-avatar class="employee-avatar-top"></div>

                <div class="employee-info">
                    <mat-card-title class="employee-name">{{viewedApplicant?.gigEmployee.firstName}} {{viewedApplicant?.gigEmployee.lastName}}</mat-card-title>
                    <mat-card-subtitle class="employee-title">{{viewedApplicant?.gigEmployee.jobTitle}}</mat-card-subtitle>
                </div>
              </div>
              <de-button *ngIf="previewMode" (buttonClick)="navigateBack()" type="secondary" size="small">BACK TO GIG</de-button>
            </div>
          </mat-card-content>
        <div class="main-content-actions">

          <ng-container *ngIf="!previewMode">  
            <de-button (buttonClick)="navigateBack()" type="secondary" size="small">GO BACK HOME</de-button>
            <de-button [disabled]="undoDisabled" (buttonClick)="undoFormChanges()" type="primary" size="small">UNDO</de-button>
          </ng-container>

        </div>

        <div class="expansion-panels">

            <mat-accordion>
                <mat-expansion-panel [expanded]="true" (opened)="skillsPanelOpen = true"
                (closed)="skillsPanelOpen = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Skills
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <!-- Employee skills field -->
                    <skill-chip-field (updatedSkills)="skillFieldValidator($event)" [skillsFieldRequired]="!previewMode" [chipOnlyInput]="previewMode" class="skill-chip-field" [fieldLabel]="userSkillsLabel" [shownSkills]="userSkills" [allSkills]="allSkills"></skill-chip-field>
                    <h3 class="skill-development-title">Skill Development</h3>

                    <!-- Employee Skill Interests field -->
                    <skill-chip-field (updatedSkills)="skillFieldValidator($event)" [chipOnlyInput]="previewMode" class="skill-chip-field" [fieldLabel]="userInterestedSkillsLabel" [shownSkills]="wantedSkillsToDevelop" [allSkills]="allSkills"></skill-chip-field>

                    <!-- Suggested Skill Interests -->
                    <skill-chip-field chipOnlyInput *ngIf="!previewMode" (skillIWantToDevelop)="addToSkillsToDevelop($event)"  class="skill-chip-field" [fieldLabel]="userSuggestedSkillsLabel" [shownSkills]="suggestedSkills"></skill-chip-field>
                </mat-expansion-panel>
            </mat-accordion>

            <mat-accordion>
                <mat-expansion-panel [expanded]="true" (opened)="careerProfilePanelOpen = true"
                (closed)="careerProfilePanelOpen = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Career Profile
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="tab-container">
                        <de-tab-control [options]="careerProfileOptions" (optionSelected)="onSelectedTab($event)" [selectedOption]="defaultOption" centered="true"></de-tab-control>
                        <div class="tab-content" *ngIf="showGigHistory">
                            <table *ngIf="employeeCareerProfile?.gigsGroup?.length" mat-table [dataSource]="employeeCareerProfile?.gigsGroup">
                      
                                <ng-container matColumnDef="title">
                                  <th mat-header-cell *matHeaderCellDef>Gig Title</th>
                                  <td mat-cell *matCellDef="let gig"> {{gig.gigName}} </td>
                                </ng-container>
                              
                                <ng-container matColumnDef="startDate">
                                  <th mat-header-cell *matHeaderCellDef>Start Date</th>
                                  <td mat-cell *matCellDef="let gig"> {{gig.gigStartDate | date: 'MM/dd/yyyy' }} </td>
                                </ng-container>
                              
                                <ng-container matColumnDef="endDate">
                                  <th mat-header-cell *matHeaderCellDef>End Date</th>
                                  <td mat-cell *matCellDef="let gig"> {{gig.gigEndDate | date: 'MM/dd/yyyy' }} </td>
                                </ng-container>
                              
                                <tr mat-header-row *matHeaderRowDef="gigHistoryColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: gigHistoryColumns;"></tr>
                            </table>

                            <p class="no-data-msg" *ngIf="!employeeCareerProfile?.gigsGroup?.length">No Gig History.</p>
                        </div>
                        <div class="tab-content" *ngIf="showJobHistory">
                            <table  *ngIf="employeeCareerProfile?.jobHistoryGroup?.length" mat-table [dataSource]="employeeCareerProfile?.jobHistoryGroup">
                      
                                <ng-container matColumnDef="Job Title">
                                  <th mat-header-cell *matHeaderCellDef>Job Title</th>
                                  <td mat-cell *matCellDef="let job"> {{job.job_title}} </td>
                                </ng-container>
                              
                                <ng-container matColumnDef="Start Date">
                                  <th mat-header-cell *matHeaderCellDef>Start Date</th>
                                  <td mat-cell *matCellDef="let job"> {{job.start_date | date: 'MM/dd/yyyy' }} </td>
                                </ng-container>
                              
                                <ng-container matColumnDef="End Date">
                                  <th mat-header-cell *matHeaderCellDef>End Date</th>
                                  <td mat-cell *matCellDef="let job"> {{job.end_date | date: 'MM/dd/yyyy' }} </td>
                                </ng-container>

                                <ng-container matColumnDef="Responsibilities and Achievements">
                                  <th mat-header-cell *matHeaderCellDef>Responsibilities and Achievements</th>
                                  <td mat-cell *matCellDef="let job"> {{job.Responsibilities_and_Achievements}} </td>
                                </ng-container>

                                <ng-container matColumnDef="Location">
                                  <th mat-header-cell *matHeaderCellDef>Location</th>
                                  <td mat-cell *matCellDef="let job"> {{job.Location}} </td>
                                </ng-container>
                              
                                <ng-container matColumnDef="Company Hierarchy">
                                  <th mat-header-cell *matHeaderCellDef>Company Hierarchy</th>
                                  <td mat-cell *matCellDef="let job"> {{job.Company_Hierarchy}} </td>
                                </ng-container>
                              
                                <ng-container matColumnDef="Reference">
                                  <th mat-header-cell *matHeaderCellDef>Reference</th>
                                  <td mat-cell *matCellDef="let job"> {{job.Reference}} </td>
                                </ng-container>
                              
                                <tr mat-header-row *matHeaderRowDef="jobHistoryColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: jobHistoryColumns;"></tr>
                            </table>

                            <p class="no-data-msg" *ngIf="!employeeCareerProfile?.jobHistoryGroup?.length">No Job History.</p>

                        </div>
                        <div class="tab-content" *ngIf="showEducation">
                            <table *ngIf="employeeCareerProfile?.educationGroup?.length" mat-table [dataSource]="employeeCareerProfile?.educationGroup">
                      
                                <ng-container matColumnDef="Degree">
                                  <th mat-header-cell *matHeaderCellDef>Degree</th>
                                  <td mat-cell *matCellDef="let education"> {{education.Degree}} </td>
                                </ng-container>
                              
                                <ng-container matColumnDef="Field Of Study">
                                  <th mat-header-cell *matHeaderCellDef>Field Of Study</th>
                                  <td mat-cell *matCellDef="let education"> {{education.Field_of_Study}} </td>
                                </ng-container>
                              
                                <ng-container matColumnDef="School">
                                  <th mat-header-cell *matHeaderCellDef>School</th>
                                  <td mat-cell *matCellDef="let education"> {{education.School}} </td>
                                </ng-container>

                                <ng-container matColumnDef="Year Degree Received">
                                  <th mat-header-cell *matHeaderCellDef>Year Degree Received</th>
                                  <td mat-cell *matCellDef="let education"> {{education.Year_Degree_Received | date: 'MM/dd/yyyy' }}</td>
                                </ng-container>
                              
                                <tr mat-header-row *matHeaderRowDef="educationColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: educationColumns;"></tr>
                            </table>

                            <p *ngIf="!employeeCareerProfile?.educationGroup?.length" class="no-data-msg">No Education History.</p>

                        </div>
                        <div class="tab-content" *ngIf="showCertifications">
                            <table *ngIf="employeeCareerProfile?.certificationGroup?.length" mat-table [dataSource]="employeeCareerProfile?.certificationGroup">
                      
                                <ng-container matColumnDef="Certification Name">
                                  <th mat-header-cell *matHeaderCellDef>Certification Name</th>
                                  <td mat-cell *matCellDef="let certification"> {{certification.Certification_Name}} </td>
                                </ng-container>
                              
                                <ng-container matColumnDef="Certifier or Issuer">
                                  <th mat-header-cell *matHeaderCellDef>Certifier or Issuer</th>
                                  <td mat-cell *matCellDef="let certification"> {{certification.Certifier_or_Issuer}} </td>
                                </ng-container>
                              
                                <ng-container matColumnDef="Issued Date">
                                  <th mat-header-cell *matHeaderCellDef>Issued Date</th>
                                  <td mat-cell *matCellDef="let certification"> {{certification.Issued_Date | date: 'MM/dd/yyyy' }} </td>
                                </ng-container>
                              
                                <tr mat-header-row *matHeaderRowDef="certificationColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: certificationColumns;"></tr>
                            </table>

                            <p *ngIf="!employeeCareerProfile?.certificationGroup?.length" class="no-data-msg">No Certifications.</p>
                        </div>
                        <!-- <div class="tab-content" *ngIf="showInternalProjects">
                            <table mat-table [dataSource]="gigHistory">
                      
                                <ng-container matColumnDef="title">
                                  <th mat-header-cell *matHeaderCellDef>Gig Title</th>
                                  <td mat-cell *matCellDef="let gig"> {{gig.tmGigId}} </td>
                                </ng-container>
                              
                                <ng-container matColumnDef="startDate">
                                  <th mat-header-cell *matHeaderCellDef>Start Date</th>
                                  <td mat-cell *matCellDef="let gig"> {{gig.tmGigStartDate}} </td>
                                </ng-container>
                              
                                <ng-container matColumnDef="endDate">
                                  <th mat-header-cell *matHeaderCellDef>End Date</th>
                                  <td mat-cell *matCellDef="let gig"> {{gig.tmGigEndDate}} </td>
                                </ng-container>
                              
                                <tr mat-header-row *matHeaderRowDef="gigHistoryColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: gigHistoryColumns;"></tr>
                            </table>
                        </div> -->
                        <div class="tab-content" *ngIf="showAchievements">
                            <table *ngIf="employeeCareerProfile?.achievements?.length" mat-table [dataSource]="employeeCareerProfile?.achievements">

                                <ng-container matColumnDef="Achievements">
                                  <th mat-header-cell *matHeaderCellDef>Achievements</th>
                                  <td mat-cell *matCellDef="let achievement"><span [innerText]="employeeCareerProfile?.achievements"></span></td>
                                </ng-container>
                              
                                <tr mat-header-row *matHeaderRowDef="achievementsColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: achievementsColumns;"></tr>
                            </table>

                            <p *ngIf="!employeeCareerProfile?.achievements?.length" class="no-data-msg">No Achievements Listed.</p>
                        </div>
                        <div class="tab-content" *ngIf="showCareerInterests">
                            <table *ngIf="employeeCareerProfile?.careerInterests?.length" mat-table [dataSource]="employeeCareerProfile?.careerInterests">
                      
                                <ng-container matColumnDef="Career Interests">
                                  <th mat-header-cell *matHeaderCellDef>Career Interests</th>
                                  <td mat-cell *matCellDef="let careerInterest"><span [innerText]="employeeCareerProfile?.careerInterests"></span></td>
                                </ng-container>
                              
                                <tr mat-header-row *matHeaderRowDef="careerInterestsColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: careerInterestsColumns;"></tr>
                            </table>

                            <p *ngIf="!employeeCareerProfile?.careerInterests?.length" class="no-data-msg">No Career Interests Listed.</p>
                        </div>
                        <div class="tab-content" *ngIf="showJobInterests">
                            <table *ngIf="employeeCareerProfile?.jobInterests?.length" mat-table [dataSource]="employeeCareerProfile?.jobInterests">
                      
                                <ng-container matColumnDef="Job Interests">
                                  <th mat-header-cell *matHeaderCellDef>Job Interests</th>
                                  <td mat-cell *matCellDef="let jobInterest"> {{employeeCareerProfile?.jobInterests}} </td>
                                </ng-container>
                              
                                <tr mat-header-row *matHeaderRowDef="jobInterestsColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: jobInterestsColumns;"></tr>
                            </table>
                            <p *ngIf="!employeeCareerProfile?.jobInterests?.length" class="no-data-msg">No Job Interests Listed.</p>
                        </div>
                    </div>
                    

                </mat-expansion-panel>
            </mat-accordion>

        </div>

        <section *ngIf="!previewMode && similarGigList.length">
          <h2 class="recommended-gigs-title">Recommended Gigs</h2>

          <div class="recommended-gig-feed">
              <mat-card *ngFor="let gig of similarGigList">
                  <mat-card-header class="gig-header-container">
                      <div class="gig-header">
                          <mat-card-title>{{gig?.gigName}}</mat-card-title>
                          <mat-card-title class="gig-duration">{{gig?.gigDuration}}</mat-card-title>
                      </div>
                      <div class="gig-header">   
                          <mat-card-subtitle>{{gig?.gigTeam}}</mat-card-subtitle>
                          <mat-card-subtitle>Starts {{gig.tmGigStartDate | date: 'MM/dd/yyyy'}}</mat-card-subtitle>
                      </div>
  
                  </mat-card-header>
                  <img mat-card-image src="../../../../assets/icons/phase_01_photo.png">
                  <mat-card-content>
                      <div class="employee-name-container">
                          <div mat-card-avatar class="employee-avatar"></div>
  
                          <div class="employee-info">
                              <mat-card-title class="employee-name">{{gig?.gigManager.firstName}} {{gig?.gigManager.lastName}}</mat-card-title>
                              <mat-card-subtitle class="employee-title">{{gig?.gigManager.jobTitle}}</mat-card-subtitle>
                          </div>
                      </div>
  
                      <div class="gig-status-container">
                          <mat-card-subtitle class="gig-posted-date">{{(gig?.gigPostedDate) ? 'Posted ' : ''}} {{gig?.gigPostedDate | date: 'MM/dd/yyyy'}}</mat-card-subtitle>
                      </div>
  
                  </mat-card-content>
                  <mat-card-actions>
                    <div>
                      <p>{{gig?.gigCategory?.gigCategoryName}}</p>
                      <ng-container *ngIf="gig.gigLocation">
                        <p>{{gig?.gigLocation?.locationName}} ({{gig?.gigLocation?.city}} {{gig?.gigLocation?.stateCode}})</p>
                      </ng-container>
                      <p>{{gig?.gigWorkerMobilityClassification | titlecase}}</p>
                    </div>
                    <de-button (buttonClick)="viewGig(gig)" type="secondary" size="small">VIEW GIG</de-button>
                  </mat-card-actions> 
              </mat-card>
          </div>
        </section>
    </mat-card>
</div>
<router-outlet></router-outlet>
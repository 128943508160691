import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from 'src/app/config/app.config';
import { GigApplication } from 'src/app/shared/interfaces/gig/gig-application.interface';
import { EmployeeCareerProfile } from 'src/app/shared/interfaces/skills/career-profile/employee-career-profile.interface';
import { EmployeeSkillList } from 'src/app/shared/interfaces/skills/employee-skill-list.interface';
import { PaginatedSkillList } from 'src/app/shared/interfaces/skills/paginated-skill-list.interface';
import { Skill } from 'src/app/shared/interfaces/skills/skill.interface';

@Injectable({
  providedIn: 'root'
})
export class SkillService {

  constructor(private http: HttpClient, private appConfig: AppConfig) { }

  getAllSkills(): Observable<PaginatedSkillList> {
    let url: string = this.appConfig.apiBaseUrl + '/skills?pageNumber=1&pageSize=25';
    return this.http.get<PaginatedSkillList>(url);
  }

  getEmployeeSkills(employeeId: string): Observable<EmployeeSkillList> {
    let url: string = this.appConfig.apiBaseUrl +  `/employee/skills?employeeId=${employeeId}`;
    return this.http.get<EmployeeSkillList>(url);
  }

  getRecommendedEmployeeSkills(employeeId: string): Observable<PaginatedSkillList> {
    let url: string = this.appConfig.apiBaseUrl +  `/recommendation/skills?employeeId=${employeeId}`;
    return this.http.get<PaginatedSkillList>(url);
  }

  searchForSkills(searchText: string): Observable<Skill[]> {
    let url: string = this.appConfig.apiBaseUrl + `/skills/search?searchText=${searchText}`;
    return this.http.get<Skill[]>(url);
  }

  addEmployeeSkillsOrInterests(skillList: EmployeeSkillList): Observable<EmployeeSkillList>  {
    let url: string = this.appConfig.apiBaseUrl + '/employee/skills';
    return this.http.post<EmployeeSkillList>(url, skillList);
  }

  getEmployeeCareerProfile(employeeId: string): Observable<EmployeeCareerProfile>  {
    let url: string = this.appConfig.apiBaseUrl + `/employee/gigProfile?employeeId=${employeeId}`;
    return this.http.get<EmployeeCareerProfile>(url);
  }

  //For highlighting a gig's required skills that a given employee has
  highlightEmployeeSkillOverlap(givenSkills: Skill[], employeeSkills: Skill[], employeeApplication?: GigApplication): void {
    if (employeeApplication) {
      if(employeeSkills){
        for (let employeeSkill of employeeSkills) {
          let skillOverlap = givenSkills.some(gigSkill => gigSkill.skillName === employeeSkill.skillName);
          if (skillOverlap) {
            employeeSkill.skillOverlaps = true;
            employeeApplication.gigEmployee.hasSkillOverlap = true;
          }
        }
      }
    }

    else {
      for (let gigSkill of givenSkills) {
        let skillOverlap = employeeSkills.some(employeeSkill => employeeSkill.skillName === gigSkill.skillName);
        if (skillOverlap) {
          gigSkill.skillOverlaps = true;
        }
      }
    }

  }
}

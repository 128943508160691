import { Injectable } from '@angular/core';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalService
} from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus
} from '@azure/msal-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';
import { AppConfig } from 'src/app/config/app.config';

@Injectable({
  providedIn: 'root'
})
export class AzureHelperService {
  azureLoggedIn = false;
  interactionStatus: InteractionStatus;

  private clientId: string;

  private activeAccount$: BehaviorSubject<AccountInfo> = new BehaviorSubject<AccountInfo>(null);

  private activeAuthResult$: BehaviorSubject<AuthenticationResult> = new BehaviorSubject<AuthenticationResult>(null);

  constructor(
    public msalSvc: MsalService,
    public msalGuard: MsalGuard,
    private msalBroadcastService: MsalBroadcastService,
    public appConfig: AppConfig,
  ) {

    this.clientId = this.appConfig.getConfig('azureClientId');
    
    // Subscribe to interaction events. When no status update current account.
    this.msalBroadcastService.inProgress$
      .pipe(
        tap((status: InteractionStatus) => {
          this.interactionStatus = status;
        }),
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.azureLoggedIn = this.msalSvc.instance.getAllAccounts().length > 0;
        this.checkAndSetActiveAccount();
      });
    // Subscribe to msal events. Update active account when login occurs.
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.activeAccount = payload.account;
      });
    // Subscribe to redirect events. Update active account and forward message.
    this.msalSvc.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
        if (result && result.account) {
          this.activeAccount = result.account;
        }
        if (result && result.idToken) {
          this.activeAuthResult$.next(result);
        }
      },
      error: (error) => {
        console.error('Msal Redirect Error:');
        console.error(error);
      }
    });
  }

  get activeAccount(): AccountInfo {
    return this.msalSvc.instance.getActiveAccount();
  }

  set activeAccount(accountInfo: AccountInfo) {
    this.activeAccount$.next(accountInfo);
    this.msalSvc.instance.setActiveAccount(accountInfo);
  }

  getActiveAccountObservable(): BehaviorSubject<AccountInfo> {
    return this.activeAccount$;
  }

  getActiveAuthResult(): BehaviorSubject<AuthenticationResult> {
    return this.activeAuthResult$;
  }

  isInteracting(): boolean {
    return this.interactionStatus != InteractionStatus.None;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.msalSvc.instance.getActiveAccount();
    if (this.msalSvc.instance.getAllAccounts().length > 0) {
      const accounts = this.msalSvc.instance.getAllAccounts();
      this.activeAccount = accounts[0];
    }
  }

  loginRedirect() {
    // if (this.msalGuardConfig.authRequest){
    //   this.msalSvc.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    // } else {
    //   this.msalSvc.loginRedirect();
    // }
  }

  loginPopup(): Observable<AuthenticationResult> {
    let loginPopup: Observable<AuthenticationResult>;
    // if (this.msalGuardConfig.authRequest){
    //   loginPopup = this.msalSvc.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest);
    // } else {
    //   loginPopup = this.msalSvc.loginPopup();
    // }
    return loginPopup.pipe(
      tap((response: AuthenticationResult) => {
        //  this.logger.logMessage(LogLevel.INFO, 'Refreshed token from azure with popup', 'reveal -> AzureHelperService -> loginPopup()');
        this.msalSvc.instance.setActiveAccount(response.account);
      })
    );
  }

  acquireTokenSilent(): Observable<AuthenticationResult> {
    const renewIdTokenRequest = { scopes: [this.clientId] };
    return this.msalSvc.acquireTokenSilent(renewIdTokenRequest).pipe(
      tap((_) => {
        //  this.logger.logMessage(LogLevel.INFO, 'Refreshed token from azure silently', 'reveal -> AzureHelperService -> acquireTokenSilent()');
      })
    );
  }

  loginSilentOrPopup(): Observable<AuthenticationResult> {
    if (this.azureLoggedIn && !this.isInteracting()) {
      return this.acquireTokenSilent().pipe(
        catchError((error, caught) =>
          //   this.logger.logMessage(LogLevel.INFO, 'Error refreshing silently', 'reveal -> AzureHelperService -> loginSilentOrPopup()');
          this.loginPopup()
        )
      );
    } else {
      return this.loginPopup();
    }
  }

  logout(popup?: boolean) {
    if (popup) {
      this.msalSvc.logoutPopup({
        mainWindowRedirectUri: '/'
      });
    } else {
      this.msalSvc.logoutRedirect();
    }
  }
}
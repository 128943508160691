import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { ChatMessage, InteractiveMessage, Message, MessageCreator, ResponseType } from 'src/app/models/chatbot/message';
import { ChatService } from 'src/app/services/chat/chat.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {

  public messages: Message[] = [];


  showMessageAvatar: boolean = false;
  isAdmin: boolean = false;
  isManager: boolean = false;

  sessionExpiringModalOpen: boolean = false;
  userExtendedSession: boolean = false;
  sessionTerminated: boolean = false;

  sessionTimeout: any;
  idleTimeout: any;

  customMessage: ChatMessage = {
    createdBy: MessageCreator.User,
    content: '',
    responseType: ResponseType.Readonly
  };

  constructor(private chatService: ChatService, private utilitiesService: UtilitiesService, private authService: AuthenticationService, private router: Router) {
    chatService.idle$.subscribe((event) => {
      this.idleTimeout = setTimeout(() => {
        this.userExtendedSession = false;
        this.toggleSessionExpiringModal(true);
      }, 90000);
    }
    );
    chatService.wake$.subscribe((event) => {
      clearTimeout(this.idleTimeout);
    });
  }

  ngOnInit(): void {
    this.chatService.clearMessages$.subscribe(() => (this.messages = []));
    this.chatService.clearMessages();

    this.authService.getRoles().then((roleList) => {
      this.isAdmin = this.authService.isUserAdmin(roleList);
      this.isManager = this.authService.isUserManager(roleList);

      this.chatService.conversation$.subscribe((val) => {

        this.utilitiesService.updateIsLoading(false);

        if (!val) {
          return;
        }

        const author = val[0]?.createdBy;
        const content = val[0]?.content;
        const type = val[0]?.responseType;
        const msg = new Message(author, '', type);

        if (content?.length) {
          for (let i = 0; i < content.length; i++) {
            setTimeout(() => {
              msg.content = content;
              this.scrollDownChatWindow();
            }, i * 10);
          }
          this.messages = this.messages.concat(msg);
        }

        this.messages.map((message, index) => {
          const next = this.messages[index + 1];
          message.showAvatar = this.shouldShowAvatar(next, message);


          //Disable interactive buttons earlier in the conversation, upon sending a message
          if (message.author === 'user') {
            for (let i = 0; i < index; i++) {
              if (this.messages[i].responseType === 'interactive') {

                for (let message of this.messages[i].content) {
                  let interactiveMessage: InteractiveMessage = message as InteractiveMessage;
                  interactiveMessage.disabled = true;
                }
              }
            }
          }

        });
      });

      this.chatService.startSession();
    });

  }

  ngOnDestroy(): void {
    if (!this.sessionTerminated) {
      this.terminateSession();
    }
    this.chatService.clearMessages();
  }

  private shouldShowAvatar(next: Message, message: Message): boolean {
    if ((!next) || (next && message.author !== next.author) || (message.responseType === 'interactive')) {
      return true;
    }
    else {
      return false;
    }

  }

  private scrollDownChatWindow(): void {
    const chatWindow = document.getElementById('chat-window');

    if (!chatWindow) {
      return;
    }

    else {
      chatWindow.scrollTop = chatWindow.scrollHeight;
    }

  }

  public sendMessage(message: InteractiveMessage | ChatMessage): void {
    this.chatService.getBotAnswer(message);
    this.customMessage.content = '';
  }

  public isWaitingOnResponse(): boolean {
    return this.messages.length && this.messages[this.messages.length - 1].author === 'user';
  }

  public toggleSessionExpiringModal(open?: boolean): void {

    if (!this.sessionExpiringModalOpen && open) {
      this.sessionExpiringModalOpen = true;

      this.sessionTimeout = setTimeout(() => {
        if (!this.sessionTerminated) {
          this.terminateSession();
        }
      }, 30000);
    }

    else if (!open) {
      this.sessionExpiringModalOpen = false;
    }

  }

  public extendSession(): void {
    this.sessionExpiringModalOpen = false;
    this.resetTimeout();
    this.chatService.extendSession();
  }

  public terminateSession(): void {

    this.sessionTerminated = true;
    this.toggleSessionExpiringModal(false);
    this.chatService.terminateSession();
    this.chatService.idle$.complete();
  }

  public resetTimeout(): void {
    clearTimeout(this.sessionTimeout);
  }

  public navigateHome(): void {
    this.router.navigateByUrl('/home');
  }

}
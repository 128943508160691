<section class="feedback-option-container" *ngIf="feedbackMode =='general'">
    <h1 class="feedback-header">Your feedback helps our continuous efforts to improve the experience.</h1>
    <div class="feedback-buttons">
        <de-button (buttonClick)="displayReportProblem()" [type]="(showReportProblem) ? 'primary': 'secondary'">REPORT A PROBLEM</de-button>
        <de-button (buttonClick)="displayRateExperience()" [type]="(showRateExperience) ? 'primary': 'secondary'">RATE THE EXPERIENCE</de-button>
    </div>

    <div *ngIf="showReportProblem">
        <h1 class="feedback-header">What problem are you having? Please provide as much detail as possible.</h1>
        <mat-form-field appearance="outline">
            <textarea #text required matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5" maxlength="255" [(ngModel)]="problemText"></textarea>
            <mat-hint align="end">{{ problemText?.length }}/255 </mat-hint>
        </mat-form-field>
    </div>

    <div *ngIf="showRateExperience">
        <h1 class="feedback-header">How do you feel about your experience using the Talent Marketplace?</h1>
        <div class="emotion-icon">
            <span *ngFor="let emotionIcon of emotionIcons">
                <button disableRipple mat-flat-button class="inline-icon" (click)="onRatingClick(emotionIcon)" [ngStyle]="{'color': emotionIcon.selected ? '#006a9d': 'black'}">
                    <mat-icon [svgIcon]="emotionIcon.icon" [ngStyle]="{'background-color': emotionIcon.selected  ? '#006a9d' :'white'}"></mat-icon>
                </button>
                <span>{{emotionIcon?.text}}</span>
            </span>
        </div>
        
        <div class="suggestion-section">
            <h1 class="feedback-header">Do you have any suggestions?</h1>
            <mat-form-field appearance="outline">
                <textarea #text matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5" maxlength="255" [(ngModel)]="suggestionText"></textarea>
                <mat-hint align="end">{{ suggestionText?.length }}/255 </mat-hint>
            </mat-form-field>
        </div>
    </div>
</section>


<section class="feedback-option-container" *ngIf="feedbackMode == 'participant'">
    <h1 class="feedback-header-blue">{{notificationData?.notificationTitle}}</h1>
    <h1 class="feedback-description">{{notificationData?.notificationText}}</h1>

    <mat-form-field appearance="outline">
        <textarea #text required matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5" maxlength="255" [(ngModel)]="participantFeedbackText"></textarea>
    </mat-form-field>

</section>

<section class="feedback-option-container" *ngIf="feedbackMode == 'manager'">
    <h1 class="feedback-header-blue">{{notificationData?.notificationTitle}}</h1>
    <h1 class="feedback-description">
        {{notificationData?.notificationText}}
    </h1>

    <mat-form-field appearance="outline">
        <textarea #text required matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5" maxlength="255" [(ngModel)]="managerFeedbackText"></textarea>
    </mat-form-field>
</section>

<div class="submit-feedback" *ngIf="feedbackMode == 'general'">
    <de-button [disabled]="(showReportProblem && !problemText) || (showRateExperience && !emotionChoice)" (buttonClick)="submitFeedback()" type="primary" size="small">SUBMIT</de-button>
</div>
<div class="submit-feedback" *ngIf="feedbackMode === 'manager' || feedbackMode === 'participant'">
    <de-button [disabled]="(feedbackMode === 'manager' && !managerFeedbackText) || (feedbackMode === 'participant' && !participantFeedbackText)" (buttonClick)="submitFeedback()" type="primary" size="small">SUBMIT</de-button>
</div>

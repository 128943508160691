import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatInputModule} from '@angular/material/input';
import { AppComponent } from './app.component';
import { AppConfig } from './config/app.config';
import { DeButtonModule, DeFooterModule, DeHeaderModule, DeLoaderModule, DeModalModule, DePageLevelMessageModule, DeTabControlModule } from '@de-electron/electron-angular-components';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { UnauthorizedComponent } from './auth/components/unauthorized/unauthorized.component';
import { AppRoutingModule } from './app-routing.module';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalService, MsalModule, MsalInterceptorConfiguration, MsalGuardConfiguration, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { HomeComponent } from './pages/home/home.component';
import { MatCardModule } from '@angular/material/card';
import { NotificationsComponent } from './shared/components/notifications/notifications.component';
import { MatTableModule } from '@angular/material/table';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { ViewProfileComponent } from './pages/view-profile/view-profile.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SkillChipFieldComponent } from './shared/components/skill-chip-field/skill-chip-field.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { CreateGigComponent } from './pages/gig/create-gig/create-gig.component';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ViewGigComponent } from './pages/gig/view-gig/view-gig.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { GlobalHttpInterceptor } from './services/global-http.inteceptor';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FeedbackComponent } from './shared/components/feedback/feedback.component';
import { BrowserCacheLocation, ILoggerCallback, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { DatePipe } from './shared/pipes/date/date.pipe';
import { EmployeeSearchComponent } from './shared/components/employee-search/employee-search.component';
import { QuillModule } from 'ngx-quill';
import { GigReportComponent } from './pages/gig-report/gig-report.component';
import { MatSortModule } from '@angular/material/sort';
import { TruncatewordsPipe } from './shared/pipes/truncateWords/truncatewords.pipe';
import { ChatComponent } from './pages/chat/chat.component';
import { ChatLoaderComponent } from './pages/chat/components/chat-loader/chat-loader.component';
import { SafeHtmlPipe } from './shared/pipes/safe-html/safe-html.pipe';
import { NotificationDialogComponent } from './shared/components/notification-dialog/notification-dialog.component';
import { NotificationsService } from './services/notifications/notifications.service';
import { NotFoundComponent } from './pages/not-found/not-found.component';

export function MSALInstanceFactory(config: AppConfig): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: config.getConfig('azureClientId'),
      authority: 'https://login.microsoftonline.com/' + config.getConfig('azureTenant'),
      redirectUri: config.getConfig('redirectUrl'),
      postLogoutRedirectUri: config.getConfig('postLogoutRedirectUri')
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(config: AppConfig): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(config.getConfig('apiBaseUrl'), [
    `api://${config.getConfig('azureClientId')}/Assets.Read`
  ]);
  protectedResourceMap.set(`https://graph.microsoft.com/v1.0/me`, ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(config: AppConfig): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ["user.read"]
    },
    loginFailedRoute: '/unauthorized'
  };
}

export const loggerCallback: ILoggerCallback = (level: number, message: string) => {
  console.log(`From MSAL: ${message}`);
};

@NgModule({
  declarations: [
    AppComponent,
    UnauthorizedComponent,
    HomeComponent,
    NotificationsComponent,
    ViewProfileComponent,
    SkillChipFieldComponent,
    CreateGigComponent,
    ViewGigComponent,
    LoaderComponent,
    FeedbackComponent,
    DatePipe,
    EmployeeSearchComponent,
    GigReportComponent,
    TruncatewordsPipe,
    ChatComponent,
    ChatLoaderComponent,
    SafeHtmlPipe,
    NotificationDialogComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    DeFooterModule,
    DeHeaderModule,
    DePageLevelMessageModule,
    FormsModule,
    HttpClientModule,
    MatInputModule,
    MatFormFieldModule,
    AppRoutingModule,
    DeLoaderModule,
    MatCardModule,
    MatTableModule,
    MatBadgeModule,
    MatButtonModule,
    MatIconModule,
    DeButtonModule,
    MatChipsModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatChipsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatMenuModule,
    DeTabControlModule,
    MatTabsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatDividerModule,
    DeModalModule,
    MatPaginatorModule,
    MatSortModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDialogModule,
    QuillModule.forRoot()
  ],
  providers: [ AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: getUseFactory,
      deps: [AppConfig],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS, 
      useClass: GlobalHttpInterceptor, 
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [AppConfig]
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [AppConfig]
    },
    NotificationsComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function getUseFactory(config: AppConfig) {
  return () => {
    config.load();
  };
}

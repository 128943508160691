import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { DeSnackbarService } from '@de-electron/electron-angular-components';
import { DeSnackbarType } from '@de-electron/electron-angular-components/lib/de-snackbar/de-snackbar-config';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  private isLoading = new BehaviorSubject<boolean>(false);

  readonly FRONTEND_DATE_FORMAT = 'M/dd/yyyy';
  readonly DATABASE_DATE_FORMAT = 'yyyy-MM-dd';
  readonly DATE_LOCALE = 'en-US';


  constructor(private snackbar: DeSnackbarService) { }

  updateIsLoading(isLoading: boolean): void  { 
    this.isLoading.next(isLoading);
  }

  subscribeIsLoading(): Observable<boolean> {
    return this.isLoading.asObservable();
  }

  formatDateToFrontEndFormat(dateToConvert: string | Date) : string {
    if (dateToConvert)
      return formatDate(dateToConvert, this.FRONTEND_DATE_FORMAT, this.DATE_LOCALE);
    return '';
  }

  formatToDatabaseDateFormat(date: string): string {
    //MM/DD/YYYY or M/DD/YYYY
    let datePattern = /^(0\d{1}|1[0-2]|[1-9])[/](0\d{1}|(1|2)?\d|30|31)[/]\d{4}$/;

    if (date.match(datePattern)) {
      return formatDate(date, this.DATABASE_DATE_FORMAT, this.DATE_LOCALE);
    }
  }

  showSnackbarMessage(messageType: DeSnackbarType, message: string, duration?: number, showCloseIcon?: boolean) {
    this.snackbar.show({
      type: messageType,
      text: message, 
      duration: (duration) ? duration : 6000, 
      showCloseIcon: (showCloseIcon) ? showCloseIcon : false,
      desktopPosition: 'top',
      mobilePosition: 'top' 
    });
  }
}

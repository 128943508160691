<ng-container *ngIf="user">
  <de-header [headerLinks]="headerLinks" [hamburgerMenuMobile]="true" [isExternal]="false"
    (secondaryLogoClick)="navigateHome()" [closeSublinksOnClick]="true" (navigation)="onNavLinkClick($event)"
    siteTitle="Talent Marketplace"> </de-header>

  <div class="main-container">
    <router-outlet></router-outlet>
    <de-button *ngIf="router.url !== '/unauthorized'" class="feedback-button" type="primary-reversed" size="small"
      (buttonClick)="toggleFeedbackModal()">
      FEEDBACK
    </de-button>
  </div>

  <de-footer [showSlogan]="false"></de-footer>
</ng-container>
<app-loader></app-loader>

<de-modal modalTitle="Feedback" [open]="feedbackModalOpen" showMask="true" (close)="toggleFeedbackModal()"
  theme="secondary" width="auto">
  <div position="centered">
    <tmp-feedback feedbackMode="general" (submitedFeedback)="toggleFeedbackModal()"></tmp-feedback>
  </div>
</de-modal>
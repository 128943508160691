
import { Injectable } from '@angular/core';
import { environments } from '../../config/env.config';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppConfig {
  private config: any;
  currentEnvironment = environment.environmentName;
  environmentConfigs = environments;
  
  constructor() {
    this.load();
  }
  /*
   * Use to get the data found in the second file (config file)
   */
  public getConfig(key: any) {
    return this.config[key];
  }

  public load() {
    if (!this.currentEnvironment)
      this.currentEnvironment = environment.environmentName;
    if (!this.environmentConfigs) 
      this.environmentConfigs = environments;
    this.config = this.environmentConfigs[this.currentEnvironment];
  }

  public get apiBaseUrl(): string {
    return this.config.apiBaseUrl;
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AppComponent } from './app.component';
import { UnauthorizedComponent } from './auth/components/unauthorized/unauthorized.component';
import { HomeComponent } from './pages/home/home.component';
import { ViewProfileComponent } from './pages/view-profile/view-profile.component';
import { CreateGigComponent } from './pages/gig/create-gig/create-gig.component';
import { ViewGigComponent } from './pages/gig/view-gig/view-gig.component';
import { RoleGuardService } from './auth/services/role-guard-service';
import { GigReportComponent } from './pages/gig-report/gig-report.component';
import { ChatComponent } from './pages/chat/chat.component';
import { DialogEntryComponent } from './shared/components/dialog-entry/dialog-entry.component';
import { NotificationsService } from './services/notifications/notifications.service';
import { NotFoundComponent } from './pages/not-found/not-found.component';

const routes: Routes = [
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: 'notification/:notificationId',
        component: DialogEntryComponent
      }
    ]
  },
  {
    path: 'view-profile',
    component: ViewProfileComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: 'notification/:notificationId',
        component: DialogEntryComponent
      }
    ]
  },
  {
    path: 'create-gig',
    component: CreateGigComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      roles: ['tmpManager', 'tmpAdmin']
    },
    children: [
      {
        path: 'notification/:notificationId',
        component: DialogEntryComponent
      }
    ]
  },
  {
    path: 'view-gig/:id',
    component: ViewGigComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: 'notification/:notificationId',
        component: DialogEntryComponent
      }
    ]
  },
  {
    path: 'gig-report',
    component: GigReportComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      roles: ['tmpAdmin']
    }
  },
  {
    path: 'chat',
    component: ChatComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: 'notification/:notificationId',
        component: DialogEntryComponent
      }
    ]
  },
  {
    path: '**',
    canActivate: [MsalGuard],
    component: NotFoundComponent,
    pathMatch: 'full'
  }
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: isInIframe() ? 'disabled' : undefined,
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled'
    })
  ],
  declarations: [
    DialogEntryComponent
  ],
  exports: [RouterModule],
  providers: [
    NotificationsService
  ]
})
export class AppRoutingModule { }

export function isInIframe() {
  return window !== window.parent && !window.opener;
}
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatChipSelectionChange } from '@angular/material/chips';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription, throwError } from 'rxjs';
import { AuthenticatedUser } from 'src/app/auth/models/authenticated-user';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { GigApplicationService } from 'src/app/services/gig-application/gig-application.service';
import { GigService } from 'src/app/services/gig/gig.service';
import { SkillService } from 'src/app/services/skills/skill.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { FilterChip } from 'src/app/shared/interfaces/filter-chip.interface';
import { GigCategory } from 'src/app/shared/interfaces/gig/gig-category.interface';
import { Gig } from 'src/app/shared/interfaces/gig/gig.interface';
import { EmployeeSkillList } from 'src/app/shared/interfaces/skills/employee-skill-list.interface';
import { Skill } from 'src/app/shared/interfaces/skills/skill.interface';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  constructor(private gigService: GigService, 
              private gigApplicationService: GigApplicationService, 
              private router: Router, 
              private utilitiesService: UtilitiesService,
              private authService: AuthenticationService,
              private skillService: SkillService) { }

  activeSubscriptions: Subscription = new Subscription();

  isManager: boolean;
  isAdmin: boolean;
  imgBycat : string = "../../../../assets/icons/phase_01_photo.png";


  gigFilters: FilterChip[] = [
    {
      name: 'All',
      selected: true
    },
    {
      name: 'Applied',
      selected: false
    },
    {
      name: 'Saved',
      selected: false
    }
  ];

  selectedGigsFilter: FilterChip = { name: 'Selected', selected: false };
  hostedGigsFilter: FilterChip = { name: 'Hosted', selected: false };
  submittedGigsFilter: FilterChip = { name: 'Submitted', selected: false };

  searchText: string;

  //Preselect to filter all gigs
  selectedGigFilter: FilterChip = this.gigFilters[0];

  selectedMenuItem: string;
  selectedParentMenuItem: string;

  selectedSortOption: string;

  // gigList: Gig[] = [
  //   {
  //     gigId: 1,
  //     gigName: "HR - Spring 2023 Campus Ambassadors",
  //     gigDescription: "Currently, this gig is intended for participants who have already been identified in our Student Hiring programs for the current year and is not open for new employee interest.\n\nSchool ambassadors are identified by their corresponding business unit and are committed to assisting with Talent Acquisition’s student hiring sourcing strategy. Ambassadors may provide insight into schools and recommend areas of opportunity for Duke Energy to recruit effectively at targeted schools.\n\nWhile attending campus events for their specific BU, school ambassadors are also considered cross-functional representatives for enterprise hiring, sourcing, and diversity needs. Ambassadors can vary in role level and job function.\n\nServing as a school ambassador is an outstanding accomplishment and value add to the organization, showcases cross department collaboration, and an excellent opportunity to share knowledge and further develop communication skills while helping Duke Energy capture exceptional student talent!",
  //     gigDuration: "1 to 3 months",
  //     timeCommitment: "10-20 hrs/wk",
  //     deliverables: "Attending on-site or virtual events such as campus career fairs, information sessions, and/or various engagement opportunities\nInteract with students at events to provide insights into Duke Energy’s business units, projects, strategies, etc. and answer questions about the company\nHelping Talent Acquisition and BU Program Coordinators with executing recruitment strategies",
  //     gigStatus: "In Recruiting",
  //     gigUrl: "https://impl.workday.com/dukeenergy5/d/inst/25060$1317/rel-task/2998$37648.htmld",
  //     gigWorkerMobilityClassification: "Onsite",
  //     gigPostedDate: "2023-06-28 19:28:46.993",
  //     gigTeam: "2023-06-28 19:28:46.993",
  //     startDate: "2023-06-28 19:28:46.993",
  //     endDate: "2023-06-28 19:28:46.993",
  //     completionDate: "2023-06-28 19:28:46.993",
  //     filledDate: "2023-06-28 19:28:46.993",
  //     gigManager: {
  //         employeeId: "249630",
  //         firstName: "Isobelle",
  //         lastName: "Fox",
  //         jobTitle: "Principal Branding Developer"
  //     },
  //     gigLocation: {
  //         locationId: 219,
  //         locationName: "Greenville SC Broad Street",
  //         addressLine1: "40 W Broad St",
  //         addressLine2: "",
  //         city: "Greenville",
  //         stateCode: "SC",
  //         postalCode: "29601",
  //         isPrimary: true,
  //         referenceId: "LC_SC0017"
  //     },
  //     gigCategory: {
  //         gigCategoryId: 3,
  //         gigCategoryName: "Job Shadowing"
  //     }
  //   },
  //   {
  //     gigId: 1,
  //     gigName: "HR - Spring 2023 Campus Ambassadors",
  //     gigDescription: "Currently, this gig is intended for participants who have already been identified in our Student Hiring programs for the current year and is not open for new employee interest.\n\nSchool ambassadors are identified by their corresponding business unit and are committed to assisting with Talent Acquisition’s student hiring sourcing strategy. Ambassadors may provide insight into schools and recommend areas of opportunity for Duke Energy to recruit effectively at targeted schools.\n\nWhile attending campus events for their specific BU, school ambassadors are also considered cross-functional representatives for enterprise hiring, sourcing, and diversity needs. Ambassadors can vary in role level and job function.\n\nServing as a school ambassador is an outstanding accomplishment and value add to the organization, showcases cross department collaboration, and an excellent opportunity to share knowledge and further develop communication skills while helping Duke Energy capture exceptional student talent!",
  //     gigDuration: "1 to 3 months",
  //     timeCommitment: "10-20 hrs/wk",
  //     deliverables: "Attending on-site or virtual events such as campus career fairs, information sessions, and/or various engagement opportunities\nInteract with students at events to provide insights into Duke Energy’s business units, projects, strategies, etc. and answer questions about the company\nHelping Talent Acquisition and BU Program Coordinators with executing recruitment strategies",
  //     gigStatus: "In Recruiting",
  //     gigUrl: "https://impl.workday.com/dukeenergy5/d/inst/25060$1317/rel-task/2998$37648.htmld",
  //     gigWorkerMobilityClassification: "Onsite",
  //     gigPostedDate: "2023-06-28 19:28:46.993",
  //     gigTeam: "2023-06-28 19:28:46.993",
  //     startDate: "2023-06-28 19:28:46.993",
  //     endDate: "2023-06-28 19:28:46.993",
  //     completionDate: "2023-06-28 19:28:46.993",
  //     filledDate: "2023-06-28 19:28:46.993",
  //     gigManager: {
  //         employeeId: "249630",
  //         firstName: "Isobelle",
  //         lastName: "Fox",
  //         jobTitle: "Principal Branding Developer"
  //     },
  //     gigLocation: {
  //         locationId: 219,
  //         locationName: "Greenville SC Broad Street",
  //         addressLine1: "40 W Broad St",
  //         addressLine2: "",
  //         city: "Greenville",
  //         stateCode: "SC",
  //         postalCode: "29601",
  //         isPrimary: true,
  //         referenceId: "LC_SC0017"
  //     },
  //     gigCategory: {
  //         gigCategoryId: 3,
  //         gigCategoryName: "Job Shadowing"
  //     }
  //   },
  //   {
  //     gigId: 1,
  //     gigName: "HR - Spring 2023 Campus Ambassadors",
  //     gigDescription: "Currently, this gig is intended for participants who have already been identified in our Student Hiring programs for the current year and is not open for new employee interest.\n\nSchool ambassadors are identified by their corresponding business unit and are committed to assisting with Talent Acquisition’s student hiring sourcing strategy. Ambassadors may provide insight into schools and recommend areas of opportunity for Duke Energy to recruit effectively at targeted schools.\n\nWhile attending campus events for their specific BU, school ambassadors are also considered cross-functional representatives for enterprise hiring, sourcing, and diversity needs. Ambassadors can vary in role level and job function.\n\nServing as a school ambassador is an outstanding accomplishment and value add to the organization, showcases cross department collaboration, and an excellent opportunity to share knowledge and further develop communication skills while helping Duke Energy capture exceptional student talent!",
  //     gigDuration: "1 to 3 months",
  //     timeCommitment: "10-20 hrs/wk",
  //     deliverables: "Attending on-site or virtual events such as campus career fairs, information sessions, and/or various engagement opportunities\nInteract with students at events to provide insights into Duke Energy’s business units, projects, strategies, etc. and answer questions about the company\nHelping Talent Acquisition and BU Program Coordinators with executing recruitment strategies",
  //     gigStatus: "In Recruiting",
  //     gigUrl: "https://impl.workday.com/dukeenergy5/d/inst/25060$1317/rel-task/2998$37648.htmld",
  //     gigWorkerMobilityClassification: "Onsite",
  //     gigPostedDate: "2023-06-28 19:28:46.993",
  //     gigTeam: "2023-06-28 19:28:46.993",
  //     startDate: "2023-06-28 19:28:46.993",
  //     endDate: "2023-06-28 19:28:46.993",
  //     completionDate: "2023-06-28 19:28:46.993",
  //     filledDate: "2023-06-28 19:28:46.993",
  //     gigManager: {
  //         employeeId: "249630",
  //         firstName: "Isobelle",
  //         lastName: "Fox",
  //         jobTitle: "Principal Branding Developer"
  //     },
  //     gigLocation: {
  //         locationId: 219,
  //         locationName: "Greenville SC Broad Street",
  //         addressLine1: "40 W Broad St",
  //         addressLine2: "",
  //         city: "Greenville",
  //         stateCode: "SC",
  //         postalCode: "29601",
  //         isPrimary: true,
  //         referenceId: "LC_SC0017"
  //     },
  //     gigCategory: {
  //         gigCategoryId: 3,
  //         gigCategoryName: "Job Shadowing"
  //     }
  //   },
  //   {
  //     gigId: 1,
  //     gigName: "HR - Spring 2023 Campus Ambassadors",
  //     gigDescription: "Currently, this gig is intended for participants who have already been identified in our Student Hiring programs for the current year and is not open for new employee interest.\n\nSchool ambassadors are identified by their corresponding business unit and are committed to assisting with Talent Acquisition’s student hiring sourcing strategy. Ambassadors may provide insight into schools and recommend areas of opportunity for Duke Energy to recruit effectively at targeted schools.\n\nWhile attending campus events for their specific BU, school ambassadors are also considered cross-functional representatives for enterprise hiring, sourcing, and diversity needs. Ambassadors can vary in role level and job function.\n\nServing as a school ambassador is an outstanding accomplishment and value add to the organization, showcases cross department collaboration, and an excellent opportunity to share knowledge and further develop communication skills while helping Duke Energy capture exceptional student talent!",
  //     gigDuration: "1 to 3 months",
  //     timeCommitment: "10-20 hrs/wk",
  //     deliverables: "Attending on-site or virtual events such as campus career fairs, information sessions, and/or various engagement opportunities\nInteract with students at events to provide insights into Duke Energy’s business units, projects, strategies, etc. and answer questions about the company\nHelping Talent Acquisition and BU Program Coordinators with executing recruitment strategies",
  //     gigStatus: "Pending Approval",
  //     gigUrl: "https://impl.workday.com/dukeenergy5/d/inst/25060$1317/rel-task/2998$37648.htmld",
  //     gigWorkerMobilityClassification: "Onsite",
  //     gigPostedDate: "2023-06-28 19:28:46.993",
  //     gigTeam: "2023-06-28 19:28:46.993",
  //     startDate: "2023-06-28 19:28:46.993",
  //     endDate: "2023-06-28 19:28:46.993",
  //     completionDate: "2023-06-28 19:28:46.993",
  //     filledDate: "2023-06-28 19:28:46.993",
  //     gigManager: {
  //         employeeId: "249630",
  //         firstName: "Isobelle",
  //         lastName: "Fox",
  //         jobTitle: "Principal Branding Developer"
  //     },
  //     gigLocation: {
  //         locationId: 219,
  //         locationName: "Greenville SC Broad Street",
  //         addressLine1: "40 W Broad St",
  //         addressLine2: "",
  //         city: "Greenville",
  //         stateCode: "SC",
  //         postalCode: "29601",
  //         isPrimary: true,
  //         referenceId: "LC_SC0017"
  //     },
  //     gigCategory: {
  //         gigCategoryId: 3,
  //         gigCategoryName: "Job Shadowing"
  //     },
  //     noOfPositions: 5
  //   },
  //   {
  //     gigId: 234,
  //     gigName: "Greening Awesome Gig Part 1",
  //     gigDescription: "Greening Awesome Gig Part 1",
  //     gigDuration: "3 to 6 months",
  //     timeCommitment: "5-10 hrs/wk",
  //     deliverables: "Greening",
  //     gigStatus: "Pending Approval",
  //     gigUrl: "https://impl.workday.com/dukeenergy5/d/inst/25060$1362/rel-task/2998$37648.htmld",
  //     startDate: "2023-07-14",
  //     endDate: "2023-07-21",
  //     noOfPositions: 3,
  //     gigManager: {
  //         employeeId: "453727",
  //         firstName: "Christopher",
  //         lastName: "Greening",
  //         jobTitle: "Sr Software Engineer"
  //     },
  //     gigLocation: {
  //         locationId: 6,
  //         locationName: "400 South Tryon (Charlotte NC)",
  //         addressLine1: "400 South Tryon Street",
  //         addressLine2: "",
  //         city: "Charlotte",
  //         stateCode: "NC",
  //         postalCode: "28202",
  //         isPrimary: true,
  //         referenceId: "LC_NC0001"
  //     },
  //     gigCategory: {
  //         gigCategoryId: 2,
  //         gigCategoryName: "Skill Development"
  //     },
  //     gigSkills: [
  //       {
  //         skillId: 42330,
  //         skillName: "052 Reporting",
  //       },
  //       {
  //         skillId: 41976,
  //         skillName: "100G",
  //       },
  //     ]
  // }
  // ];

  gigList: Gig[] = [];
  filterGigList: Gig[] = [];

  gigSortOptions: string[] = ['Newest', 'A to Z', 'Z to A'];

  filterGigMenuOptions: any = 
  {
    skillsIHaveOption: { value: 'Best Match - Skills I Have',  checked: false },
    skillsIWantToDevelopOption:  { value: 'Best Match - Skills I Want To Develop', checked: false },
    locationMenu: {
      info: {
        name: 'Location',
        value: 'gigWorkerMobilityClassification',
        checked: false
      },
      options: [      
        { value: 'onsite', checked: false }, 
        { value: 'remote', checked: false }, 
        { value: 'hybrid', checked: false }
      ]
    },
    gigDurationMenu: {
      info: {
        name: 'Gig Duration',
        value: 'gigDuration',
        checked: false
      },
      options: [    
        { value: '1 to 3 months', checked: false }, 
        { value: '3 to 6 months', checked: false }, 
        { value: '6 to 9 months', checked: false },
        { value: '9 to 12 months', checked: false }, 
        { value: 'Less than 1 month', checked: false },
      ]
    },
    gigAvailabilityMenu: {
      info: {
        name: 'Gig Availability',
        value: 'timeCommitment',
        checked: false
      },
      options: [      
        { value: '1-5 hrs/wk', checked: false },
        { value: '5-10 hrs/wk', checked: false },
        { value: '10-20 hrs/wk', checked: false },
        { value: '20-30 hrs/wk', checked: false },
        { value: '30-40 hrs/wk', checked: false }
      ]
    },
    gigCategoryMenu: {
      info: {
        name: 'Gig Category',
        value: 'gigCategory',
        checked: false
      },
      options: [      
        { value: 'Committee or Special Activity', checked: false },
        { value: 'Job Shadowing', checked: false },
        { value: 'Networking', checked: false },
        { value: 'Reskilling', checked: false },
        { value: 'Skill Development', checked: false },
        { value: 'Skill Sharing', checked: false }
      ]
    },
    gigStatusMenu: {
      info: {
        name: 'Gig Status',
        value: 'gigStatus',
        checked: false
      },
      options: [      
        { value: 'In Recruiting', checked: false },
        { value: 'Fully Staffed', checked: false },
        { value: 'Cancelled', checked: false },
        { value: 'Closed', checked: false }
      ]
    }
  };


  pageLoaded: boolean = false;

  user: AuthenticatedUser;

  userSkills: Skill[] = [];
  wantedSkillsToDevelop: Skill[] = [];

  filterValues: any = {};

  //For filtering
  dataSource: MatTableDataSource<Gig>;

  ngOnInit(): void {
    this.utilitiesService.updateIsLoading(true);
    
    this.authService.getRoles().then((roleList) => {
      this.isAdmin = this.authService.isUserAdmin(roleList);
      this.isManager = this.authService.isUserManager(roleList);
      this.createGigFilters();
    });

    this.authService.currentUser$.subscribe((user: AuthenticatedUser) => {
      this.user = user;
      this.getAllGigs();
      this.getEmployeeSkills(this.user?.idTokenClaims.employeeId);
    });
  }

  ngOnDestroy(): void {
    this.activeSubscriptions.unsubscribe();
  }

  onSelectedGigFilter($event: MatChipSelectionChange, gigFilter: FilterChip): void {
    if (gigFilter.name !== this.selectedGigFilter.name) {
      gigFilter.selected = $event.selected;
      if (gigFilter.selected) {
        this.selectedGigFilter = gigFilter;
        this.filterGigList = this.gigList.filter((gig) => {        
          if (gigFilter.name === 'All') {
            return true;
          } else if (gigFilter.name === 'Applied') {
            return gig.appliedToGig === true;
          } else if (gigFilter.name === 'Saved') {
            return gig.savedGig === true;
          } else if (gigFilter.name === 'Selected') {
            return gig.savedGig === true;
          } else if (gigFilter.name === 'Hosted') {
            return gig.hostedGig === true;
          } else if (gigFilter.name === 'Submitted') {
            return gig.gigStatus === 'Pending Approval';
          }
        })
      }
    }
  }

  getImgUrl(categories : string){
    switch (categories) {
      case 'Networking': {
       return  "../../../../assets/images/home/Networking.png";
        break;
      }
      case 'Reskilling': {
        return   "../../../../assets/images/home/Reskilling.png";
        break;
      }
      case 'Skill Development': {
        return  "../../../../assets/images/home/Skill Development.png";
        break;
      }
      case 'Skill Sharing': {
        return  "../../../../assets/images/home/Skill-Sharing.png";
        break;
      }
      case 'Job Shadowing': {
        return  "../../../../assets/images/home/Job-Shadowing.png";
        break;
      }
      case 'Committee or Special Activity': {
        return  "../../../../assets/images/home/Committee_Special-Activity.png";
        break;
      }
      case 'Test Category': {
        return  "../../../../assets/icons/phase_01_photo.png";
        break;
      }
      default :
       return  "../../../../assets/icons/phase_01_photo.png";
    }
  }

  createGigFilters(): void {

    if (this.isManager || this.isAdmin) {
      this.gigFilters.push(this.hostedGigsFilter);
      let pendingApprovalOption = { value: 'Pending Approval', checked: false };
      this.filterGigMenuOptions.gigStatusMenu.options.unshift(pendingApprovalOption);
    }
    
    if (this.isAdmin) {
      this.gigFilters.push(this.submittedGigsFilter);
    }

  }

  navigateToCreateGig(): void {
    this.router.navigateByUrl('/create-gig');
  }

  viewGig(gigData: Gig): void {
    if (this.isAdmin && gigData.gigStatus == 'Pending Approval') {
      this.router.navigateByUrl('/create-gig', { state: gigData as any });
    }
    else {
      this.router.navigateByUrl(`/view-gig/${gigData.gigId}`, { state: gigData as any });
    }
  }

  sortByOption(gigSortOption: string): void {
    switch (gigSortOption) {
      case 'Newest': {
        if (this.selectedSortOption !== gigSortOption) {
          this.setActiveSortOption(gigSortOption);
          this.filterGigList.sort(this.sortBy('startDate'));
          this.filterGigList.reverse();
        }
        break;
      }
      case 'A to Z': {
        if (this.selectedSortOption !== gigSortOption) {
          this.setActiveSortOption(gigSortOption);
          this.filterGigList.sort(this.sortBy('gigName'));
        }
        break;
      }
      case 'Z to A': {
        if (this.selectedSortOption !== gigSortOption) {
          this.setActiveSortOption(gigSortOption);
          this.filterGigList.sort(this.sortBy('gigName'));
          this.filterGigList.reverse();
        }
        break;
      }

    }
  }

  sortBy(property: string): any {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a: Object, b: Object): number {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
  }

  setActiveSortOption(sortOption: string): void {
    //Deselect if selecting same filter option
    if (this.selectedSortOption === sortOption) {
      this.selectedSortOption = null;
    }
    else {
      this.selectedSortOption = sortOption;
    }
  }

  getAllGigs(): void {
    this.activeSubscriptions.add(
      this.gigService.getAllGigs().subscribe((gigs: Gig[]) => {
        this.gigList = gigs;
        this.filterGigList = gigs;     
        this.pageLoaded = true;
        this.utilitiesService.updateIsLoading(false);

        this.dataSource = new MatTableDataSource(this.gigList);

        this.dataSource.filterPredicate = (
          data: Gig,
          filter: string
        ): boolean => {
          const filterValues = JSON.parse(filter);
          let conditions = true;
    
          for (let filterKey in filterValues) {
            if (filterValues[filterKey].length) {
              if (filterKey === 'gigWorkerMobilityClassification') {
                conditions =
                  conditions &&
                  filterValues[filterKey].includes(
                    data[filterKey]?.trim().toLowerCase()
                  );
              }
  
              else if (filterKey.includes('gigCategory') && data[filterKey]) {
                conditions =
                conditions &&
                filterValues[filterKey].includes(
                  data[filterKey]['gigCategoryName']?.trim()
                );
              }
  
              else {
                conditions =
                conditions &&
                filterValues[filterKey].includes(
                  data[filterKey]?.trim()
                );
              }
            }
            
          
          }
    
          return conditions;
        };
      },
      (error: Error) => {
        throw new Error(error.message);
      })
    );
  }

  searchGigs(): void {
    if (!this.searchText?.length) {
      return;
    }

    else {

      this.activeSubscriptions.add(
        this.gigService.searchGigs(this.searchText).subscribe(
          (gigResults: Gig[]) => {
            this.gigList = gigResults;
            this.filterGigList = gigResults;
            this.utilitiesService.updateIsLoading(false);
          },
          (error: HttpErrorResponse) => {
            throwError(error);
            this.utilitiesService.showSnackbarMessage('warning', 'There was an error returning search results. Please try again later.', 6000, true);
            this.utilitiesService.updateIsLoading(false);
          })
      );

    }
  }

  clearSearchResults(): void {
    this.searchText = '';
    this.getAllGigs();
  }

  // Set bold styling for given filter selection
  setActiveFilter(parentMenuItem: any): void {
    this.selectedSortOption = null;

    let childSelectionChecked = parentMenuItem.options.find((option) => option.checked);

    if (childSelectionChecked) {
      parentMenuItem.info.checked = true;
    }

    else {
      parentMenuItem.info.checked = false;
    }
  }

  filterGigBy(filterValue: string): void {
    this.filterGigList = this.gigList.filter((gig: Gig) => {

      switch (filterValue) {

        //Skill matches
        case 'Best Match - Skills I Have': {
          if (!gig.gigSkills) {
            return;
          }
          else {
            this.skillService.highlightEmployeeSkillOverlap(gig?.gigSkills, this.userSkills);  
            return gig.gigSkills.some((matchedSkill) => matchedSkill.skillOverlaps === true);
          }
        }
        case 'Best Match - Skills I Want To Develop': {
          if (!gig.gigSkills) {
            return;
          }

          else {
            this.skillService.highlightEmployeeSkillOverlap(gig?.gigSkills, this.wantedSkillsToDevelop);  
            return gig.gigSkills.some((matchedSkill) => matchedSkill.skillOverlaps === true);
          }
        }
      }});
  };

  updateFilter(column: string, filter: string, childMenuItem?: string, parentMenuItem?: string): void {

      if (childMenuItem && parentMenuItem) {
        this.setActiveFilter(parentMenuItem);
      }
      
      if (!this.filterValues.hasOwnProperty(column)) {
        this.filterValues[column] = [filter];
      } else {
        this.filterValues[column].includes(filter)
          ? (this.filterValues[column] = this.filterValues[column].filter(
              (filterValue) => filterValue !== filter
            ))
          : this.filterValues[column].push(filter);
      }
  }

  applyFilter(): void {

    let appliedFilter: string = JSON.stringify(this.filterValues);
    let selectedSubmenuItem: boolean = appliedFilter.includes('gigWorkerMobilityClassification') || appliedFilter.includes('gigDuration') || appliedFilter.includes('timeCommitment') || appliedFilter.includes('gigCategory') || appliedFilter.includes('gigStatus');

    if (appliedFilter.includes('Best Match')) {

      // Either index value is 'Best Match - Skills I Have' or Best Match - Skills I Want To Develop'
      let skillMatchSelection: string = this.filterValues['Best Match'][0];

      // If selecting an additional filter value from one of the submenu options, filter by skill match first, then apply an additional filter based on chosen submenu option
      if (selectedSubmenuItem) {
        this.filterGigBy(skillMatchSelection);
        this.dataSource.data = this.filterGigList;
        delete this.filterValues['Best Match'];
        this.dataSource.filter = JSON.stringify(this.filterValues);
        this.filterGigList = this.dataSource.filteredData;
      }

      else {
        this.filterGigBy(skillMatchSelection);
      }
    }
    
    else {
      this.dataSource.filter = JSON.stringify(this.filterValues);
      this.filterGigList = this.dataSource.filteredData;
    }

  }

  clearFilters(): void {


    for (let submenu in this.filterGigMenuOptions) {
      
      // Uncheck skill match options
      if (this.filterGigMenuOptions[submenu].checked) {
        this.filterGigMenuOptions[submenu].checked = false;
        for (let gig of this.gigList) {
          if (gig.gigSkills) {
            for (let skill of gig.gigSkills) {
              if (skill.skillOverlaps) {
                skill.skillOverlaps = false;
              }
            }
          }
        }
      }

      // Uncheck submenu options
      else if (this.filterGigMenuOptions[submenu].options) {
        this.filterGigMenuOptions[submenu].info.checked = false;
        for (let option of this.filterGigMenuOptions[submenu].options) {
          if (option.checked) {
            option.checked = false;
          }
        }
      }
    }

    for (let filterValue in this.filterValues) {
      this.filterValues[filterValue] = [];
    }

    this.filterGigList = this.gigList;
  }


  saveGigReq : any =[];

  unSavedGig(gigId :string){
    this.saveGigReq.push({ gigId : gigId});

    this.activeSubscriptions.add(
      this.gigApplicationService.savedGig(this.saveGigReq).subscribe(
        () => {
          this.utilitiesService.showSnackbarMessage('success', `Gig Unsaved`, 6000);
          this.getAllGigs();
           this.selectedGigFilter= this.gigFilters[0];
          const index = this.gigFilters.findIndex(item => item.name === 'All');
           if(index !== -1){
            this.gigFilters[index].selected = true;
           }
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', `There was an error applying to this gig. Please try again later.`, 6000, true);
        },
        () => {
          this.utilitiesService.updateIsLoading(false);
        })
    );
  }

  getEmployeeSkills(employeeId: string) {
    this.activeSubscriptions.add(
      this.skillService.getEmployeeSkills(employeeId).subscribe(
        (skillList: EmployeeSkillList) => {
          this.userSkills = skillList.skills;
          this.wantedSkillsToDevelop = skillList.skillInterests;
        },
        (error) => {
          throwError(error);
        })
    );
  }
}
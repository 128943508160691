import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AppConfig } from 'src/app/config/app.config';
import { Notification } from 'src/app/shared/interfaces/notifications-interface';



@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  

  constructor(private http: HttpClient, private appConfig: AppConfig) { }

  public unreadNotifications$ = new BehaviorSubject<number>(0);

  activeNotification: Notification;

  getUserNotifications(): Observable<Notification[]> {
    let url: string = this.appConfig.apiBaseUrl + '/employee/notifications';
    return this.http.get<Notification[]>(url);
  }

  employeeManagerApproval(gigId: number, applicationId: number,request): Observable<any> {
    let url: string = this.appConfig.apiBaseUrl + `/gigs/${gigId}/applications/${applicationId}/action/EmployeeManagerApproval`;
    return this.http.post<any>(url ,request , { responseType: 'text' as 'json' });
  }

  applicantApproval(gigId: number, applicationId: number,request): Observable<any> {
    let url: string = this.appConfig.apiBaseUrl + `/gigs/${gigId}/applications/${applicationId}/action/ApplicantApproval`;
    return this.http.post<any>(url,request,  { responseType: 'text' as 'json' });
  }

  updateNotificationStatus(notificationId: number){
    let url: string = this.appConfig.apiBaseUrl + `/notifications/${notificationId}/update`;
    return this.http.post<any>(url,{},{ responseType: 'text' as 'json' });
  }

}

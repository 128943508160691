import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { EMPTY, Observable, throwError } from 'rxjs';
import { map, startWith, distinctUntilChanged, debounceTime, filter, finalize, tap, switchMap, catchError } from 'rxjs/operators';
import { PaginatedEmployeeList } from '../../interfaces/employee/paginated-employee-list';
import { GigService } from 'src/app/services/gig/gig.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Employee } from '../../interfaces/employee/employee.interface';



@Component({
  selector: 'tmp-employee-search',
  templateUrl: './employee-search.component.html',
  styleUrls: ['./employee-search.component.scss']
})
export class EmployeeSearchComponent implements OnInit {

  filteredEmployees: Observable<Employee[]>;
  employeeControl = new UntypedFormControl('');

  _isAutocompleteField: boolean;

  @Input()
  get isAutocompleteField() {
    return this._isAutocompleteField;
  }

  set isAutocompleteField(value: any) {
    this._isAutocompleteField = this.coerceBooleanProperty(value);
  }

  @Output() searchText = new EventEmitter<string>();
  @Output() selectedEmployee = new EventEmitter<Employee>();

  inputValue: string = '';

  @Input() isSpinnerLoading: boolean = false;

  searchResults: Employee[];

  constructor(private gigService: GigService) { 
    this.filteredEmployees = this.employeeControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.firstName;
        return name ? this._filter(name as string) : this.searchResults.slice();
      }),

    );
  }

  ngOnInit(): void {
    this.filteredEmployees = this.employeeControl.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(1000),
      filter((text) => !!text),
      tap(() => {
        this.isSpinnerLoading = true;
      }),
      switchMap((text) => this.searchForEmployee(text)
        .pipe(
          finalize(() => {
            this.isSpinnerLoading = false;
          })
        )
      )
    );

  }

  searchForEmployee(searchQuery: any): Observable<Employee[]> {

    if (typeof searchQuery == 'string' && searchQuery.trim() !== '' && searchQuery.length >= 3) {
      return this.gigService.searchForEmployee(searchQuery).pipe(
        distinctUntilChanged(),
        map((searchResults: PaginatedEmployeeList) => {
          return searchResults.entries;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );   
    }
    else {
      this.emitEmployeeValue(searchQuery);
      return EMPTY;
    }
  }

  private _filter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.searchResults.filter(employee => employee.firstName.toLowerCase().includes(filterValue));
  }

  displayFn(employee: Employee): string {
    return employee && employee.firstName ? employee.firstName + ' ' + employee.lastName : '';
  }

  emitSearchValue(): void {
    this.searchText.emit(this.inputValue);
  }

  emitEmployeeValue(employee: Employee): void {
    this.selectedEmployee.emit(employee);
  }

  coerceBooleanProperty(value: any): boolean {
    return value != null && `${value}` !== 'false';
  }

}
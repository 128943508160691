import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, map, startWith, switchMap, tap } from 'rxjs/operators';
import { SkillService } from 'src/app/services/skills/skill.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { Skill } from 'src/app/shared/interfaces/skills/skill.interface';

@Component({
  selector: 'skill-chip-field',
  templateUrl: './skill-chip-field.component.html',
  styleUrls: ['./skill-chip-field.component.scss']
})
export class SkillChipFieldComponent implements OnInit, OnChanges {

  skillControl = new UntypedFormControl(null);
  filteredSkills: Observable<Skill[]>;
  NoResultModalOpen:boolean=false;

  @ViewChild('skillInput') skillInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { read: MatAutocompleteTrigger }) auto: MatAutocompleteTrigger;

  @Input() onSubmitSkillsValidation: boolean = false;
  @Input() fieldLabel: string;

  //Skills to show, whether for a gig or suggested skills to add or develop
  @Input() shownSkills;

  //All skills to choose from, in a dropdown list
  @Input() allSkills: Skill[]; 

  //For skills that the user/employee has
  @Input() userSkills;

  //Autocomplete input disabled
  _chipOnlyInput: boolean;

  @Input()
  get chipOnlyInput() {
    return this._chipOnlyInput;
  }

  set chipOnlyInput(value: any) {
    this._chipOnlyInput = this.coerceBooleanProperty(value);
  }

  @Input() disabled: boolean = false;

  @Output() updatedSkills = new EventEmitter<Skill[]>();

  //If viewing a gig, highlight a skill if employee has the skill that the gig is asking for
  isEmployeeSkill: boolean = false;

  //Only emits when adding a skill, from "suggested skills"
  @Output() skillIWantToDevelop = new EventEmitter<string>();

  @ViewChild('skillFormField') skillFormField;

  isSpinnerLoading: boolean = false;


  private _skillsFieldRequired: boolean;

  @Input()
  get skillsFieldRequired() {
    return this._skillsFieldRequired;
  }

  set skillsFieldRequired(value: any) {
    this._skillsFieldRequired = this.coerceBooleanProperty(value);
  }

  constructor(private skillService: SkillService, private utilitiesService: UtilitiesService) {
    this.filteredSkills = this.skillControl.valueChanges.pipe(
      //Using startWith(null) results in a false deprecation warning: https://github.com/ReactiveX/rxjs/issues/4772
       startWith(''),
        map(skillName => skillName ? this._filter(skillName) : this.allSkills?.slice())
      );
  }

  ngOnInit(): void {

    if (this.skillsFieldRequired) {
      this.skillControl.setValidators(Validators.required);
    }
    this.filteredSkills = this.skillControl.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(1000),
      filter((text) => !!text),
      tap(() => {
        this.isSpinnerLoading = true;
      }),
      switchMap((text) => this.searchForSkills(text)
        .pipe(
          finalize(() => {
            this.isSpinnerLoading = false;
          })
        )
      )
    );
   
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.allSkills = changes.allSkills?.currentValue;
    this.disabled = changes.disabled?.currentValue;

    if (changes.userSkills?.currentValue.length) {
      this.skillService.highlightEmployeeSkillOverlap(this.shownSkills, this.userSkills);
    }
    //set default skills input validation 
    if(this.onSubmitSkillsValidation){
      this.onSubmitValidation();
    }

    if (this.disabled) {
      this.skillControl.disable();
    }
  }

  searchForSkills(searchText: string): Observable<Skill[]> {
    if (searchText.trim() !== '' && searchText.length >= 3) {
      return this.skillService.searchForSkills(searchText).pipe(
        distinctUntilChanged(),
        map((skillResults: Skill[]) => {
          this.NoResultModalOpen = skillResults?.length >0 ? false:true;
          if(this.NoResultModalOpen){
            this.skillInput.nativeElement.value = '';
            this.skillControl.setValue(null);
          }
          
          return skillResults;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );   
    }
    else {
      return EMPTY;
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add skill
    if (value) {
      this.shownSkills.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.skillControl.setValue(null);

    this.updatedSkills.emit(this.shownSkills);
    
  }

  sendToSkillsToDevelop(event: string) {
    this.skillIWantToDevelop.emit(event);
  }

  remove(chip: string): void {
    const index = this.shownSkills.indexOf(chip);

    if (index >= 0) {
      this.shownSkills.splice(index, 1);
    }

    this.updatedSkills.emit(this.shownSkills);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.shownSkills.push(event.option.value);
    this.skillInput.nativeElement.value = '';
    this.skillControl.setValue(null);
    this.updatedSkills.emit(this.shownSkills);
  }

  private _filter(value: any) {

    if (value.skillName) {
      return this.allSkills.filter(skill => skill.skillName.toLowerCase().includes(value.skillName.toLowerCase()));
    }
    else {
      return this.allSkills.filter(skill => skill.skillName.toLowerCase().includes(value.toLowerCase()));
    }
  }

  getErrorMessage() {

    //Return error state if skill field is required but empty
    if (this.skillsFieldRequired) {
      if (!this.shownSkills.length && !this.skillControl.value) {
        this.skillControl.setErrors(Validators.required);
        return 'Please search and select a skill';
      }
  
      //Reset validator upon entered value
      if (this.skillControl.hasError('required') && this.shownSkills.length > 0) {
        this.skillControl.setErrors(null);
      }
    }

    if (!this.chipOnlyInput || this.skillsFieldRequired) {
      //Return error if duplicate skills are selected
      const uniqueValues = new Set(this.shownSkills.map((skill: Skill) => skill.skillName));

      if (uniqueValues.size < this.shownSkills.length) {
        this.skillControl.markAsTouched();
        this.skillControl.setErrors(Validators.required);
        return 'Duplicate skills selected';
      }
      else {
        this.skillControl.setErrors(null);
      }
    }

  }

  coerceBooleanProperty(value: any): boolean {
    return value != null && `${value}` !== 'false';
  }


  //set skill input as touched on save buttion clicked if it is empty
  onSubmitValidation(){
    this.skillControl.markAsTouched();
  }

  emptyResultModel(){
    this.NoResultModalOpen = !this.NoResultModalOpen;
  }
}
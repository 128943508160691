import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datePipe'
})
export class DatePipe implements PipeTransform {

  transform(value: any): any {

    if (value) {

      //Value comes as YYYY-MM-DD, followed by 12 hr time and AM/PM w/ spaces between each value
      value = value.split(' ');

      let date = value[0];
      let time = value[1] + ' ' + value[2];

      //Remove leading zero from time
      time = time.replace(/^0+/, '');


      if (this.isDateInThePast(date)) {
        let notificationDate = new Date(date);
        let notificationDay = notificationDate.getDate();
        let notificationMonth = notificationDate.toLocaleString('default', {month: 'short'});

        return notificationMonth + ' ' + notificationDay;   
      }

      else {
        return time;
      }


    } 
    else {
      return null;
    }
  }

  isDateInThePast(validatedDate: string | Date): boolean {
    if (validatedDate) {
      let today = new Date();
      let checkedDate = new Date(formatDate(validatedDate,  'yyyy-MM-dd', 'en-US'));
      let currentDate = new Date(formatDate(today,  'yyyy-MM-dd', 'en-US'));
      currentDate.setHours(0, 0, 0, 0);
      checkedDate.setHours(0, 0, 0, 0);
      if (checkedDate < currentDate) {
        return true;
      } 
      return false;
    }
  }

}
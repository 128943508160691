import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {

  // notLoading: boolean = true;

  isLoading: boolean = false;
  subscriptions: Subscription = new Subscription();
  
  constructor(private utilitiesService: UtilitiesService) { }

  ngOnInit(): void {
    this.subscriptions.add(
      this.utilitiesService.subscribeIsLoading().subscribe((loading: boolean) => {
        this.isLoading = loading;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ControlOption } from '@de-electron/electron-angular-components';
import { Subscription, throwError } from 'rxjs';
import { AuthenticatedUser } from 'src/app/auth/models/authenticated-user';
import { GigService } from 'src/app/services/gig/gig.service';
import { SkillService } from 'src/app/services/skills/skill.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { GigApplication } from 'src/app/shared/interfaces/gig/gig-application.interface';
import { Gig } from 'src/app/shared/interfaces/gig/gig.interface';
import { EmployeeCareerProfile } from 'src/app/shared/interfaces/skills/career-profile/employee-career-profile.interface';
import { JobHistory } from 'src/app/shared/interfaces/skills/career-profile/job-history-interface';
import { EmployeeSkillList } from 'src/app/shared/interfaces/skills/employee-skill-list.interface';
import { PaginatedSkillList } from 'src/app/shared/interfaces/skills/paginated-skill-list.interface';
import { Skill } from 'src/app/shared/interfaces/skills/skill.interface';

@Component({
  selector: 'app-view-profile',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.scss']
})
export class ViewProfileComponent implements OnInit, OnDestroy {

  skillsPanelOpen: boolean = false;
  careerProfilePanelOpen: boolean = false;

  userSkills: Skill[] = [];
  originalUserSkills: Skill[] = [];

  wantedSkillsToDevelop: Skill[] = [];
  originalWantedSkillsToDevelop: Skill[] = [];

  undoDisabled: boolean = false;

  suggestedSkills: Skill[] = [];

  allSkills: Skill[] = [];

  similarGigList: Gig[] = [];

  employeeCareerProfile: EmployeeCareerProfile = {};

  gigHistoryColumns: string[] = ['title', 'startDate', 'endDate'];
  jobHistoryColumns: string[] = ['Job Title', 'Start Date', 'End Date', 'Responsibilities and Achievements', 'Location', 'Company Hierarchy', 'Reference'];
  educationColumns: string[] = ['Degree', 'Field Of Study', 'School', 'Year Degree Received'];
  certificationColumns: string[] = ['Certification Name', 'Certifier or Issuer', 'Issued Date'];
  // internalProjectsColumns: string[] = ['title', 'startDate', 'endDate'];
  achievementsColumns: string[] = ['Achievements'];
  careerInterestsColumns: string[] = ['Career Interests'];
  jobInterestsColumns: string[] = ['Job Interests'];


  careerProfileOptions: ControlOption[] = [
    { value: 'gigs', viewValue: 'Gigs' },
    { value: 'job-history', viewValue: 'Job History' },
    { value: 'education', viewValue: 'Education' },
    { value: 'certifications', viewValue: 'Certifications' },
    // { value: 'internal-projects', viewValue: 'Internal Projects' },
    { value: 'achievements', viewValue: 'Achievements' },
    { value: 'career-interests', viewValue: 'Career Interests' },
    { value: 'job-interests', viewValue: 'Job Interests' }
  ]

  showGigHistory : boolean = true;
  showJobHistory: boolean = false;
  showEducation: boolean = false;
  showCertifications: boolean = false;
  showInternalProjects: boolean = false;
  showAchievements: boolean = false;
  showCareerInterests: boolean = false;
  showJobInterests: boolean = false;

  defaultOption = 'gigs';

  //For managers if viewing an employee's profile
  previewMode: boolean = false;

  user: AuthenticatedUser = JSON.parse(sessionStorage.getItem('currentUser'));
  viewedApplicant: GigApplication;

  userSkillsLabel = 'Skills I Have';
  userInterestedSkillsLabel = 'Skills I Want To Develop';
  userSuggestedSkillsLabel = 'Suggested Skills I May Want To Add';

  duplicateSkillsSelected: boolean = false;

  activeSubscriptions: Subscription = new Subscription();

  constructor(private utilitiesService: UtilitiesService, private skillService: SkillService, private router: Router, private gigService: GigService) { 
    if (this.router.getCurrentNavigation()?.extras.state) {
      this.viewedApplicant = this.router.getCurrentNavigation().extras.state as GigApplication;
      this.userSkillsLabel = `Skills ${this.viewedApplicant.gigEmployee.firstName} Has`;
      this.userInterestedSkillsLabel = `Skills ${this.viewedApplicant.gigEmployee.firstName} Wants To Develop`;
      this.previewMode = true;
    }
  }

  ngOnInit(): void {
    this.undoDisabled = true;

    if (this.previewMode) {
      this.getEmployeeCareerProfile(this.viewedApplicant.gigEmployee.employeeId);
    }

    else {
      this.getEmployeeCareerProfile(this.user.idTokenClaims.employeeId);
    }

    if (this.viewedApplicant) {
      this.getEmployeeSkills(this.viewedApplicant.gigEmployee.employeeId);
    }
    else {
      this.getEmployeeSkills(this.user.idTokenClaims.employeeId);
      this.getRecommendedGigs();
      this.getRecommendedSkills();
    }

  }

  ngOnDestroy(): void {
    this.activeSubscriptions.unsubscribe();
  }

  onSelectedTab(tab: string) {
    switch (tab) {
      case 'gigs':
        this.showGigHistory = true;
        this.showJobHistory = false;
        this.showEducation = false;
        this.showCertifications = false;
        this.showInternalProjects = false;
        this.showAchievements = false;
        this.showCareerInterests = false;
        this.showJobInterests = false;
        break;
        
      case 'job-history':
        this.showGigHistory = false;
        this.showJobHistory = true;
        this.showEducation = false;
        this.showCertifications = false;
        this.showInternalProjects = false;
        this.showAchievements = false;
        this.showCareerInterests = false;
        this.showJobInterests = false;
        break;
        
      case 'education':
        this.showGigHistory = false;
        this.showJobHistory = false;
        this.showEducation = true;
        this.showCertifications = false;
        this.showInternalProjects = false;
        this.showAchievements = false;
        this.showCareerInterests = false;
        this.showJobInterests = false;
        break;

      case 'certifications':
        this.showGigHistory = false;
        this.showJobHistory = false;
        this.showEducation = false;
        this.showCertifications = true;
        this.showInternalProjects = false;
        this.showAchievements = false;
        this.showCareerInterests = false;
        this.showJobInterests = false;
        break;
        
      case 'internal-projects':
        this.showGigHistory = false;
        this.showJobHistory = false;
        this.showEducation = false;
        this.showCertifications = false;
        this.showInternalProjects = true;
        this.showAchievements = false;
        this.showCareerInterests = false;
        this.showJobInterests = false;
        break;
        
      case 'achievements':
        this.showGigHistory = false;
        this.showJobHistory = false;
        this.showEducation = false;
        this.showCertifications = false;
        this.showInternalProjects = false;
        this.showAchievements = true;
        this.showCareerInterests = false;
        this.showJobInterests = false;
        break;

      case 'career-interests':
        this.showGigHistory = false;
        this.showJobHistory = false;
        this.showEducation = false;
        this.showCertifications = false;
        this.showInternalProjects = false;
        this.showAchievements = false;
        this.showCareerInterests = true;
        this.showJobInterests = false;
        break;

      case 'job-interests':
        this.showGigHistory = false;
        this.showJobHistory = false;
        this.showEducation = false;
        this.showCertifications = false;
        this.showInternalProjects = false;
        this.showAchievements = false;
        this.showCareerInterests = false;
        this.showJobInterests = true;
        break;
    }
  }

  addToSkillsToDevelop(skill: Skill): void {
    this.wantedSkillsToDevelop.push(skill);
    this.skillFieldValidator(this.wantedSkillsToDevelop);
  }

  getEmployeeSkills(employeeId: string) {
    this.activeSubscriptions.add(
      this.skillService.getEmployeeSkills(employeeId).subscribe(
        (skillList: EmployeeSkillList) => {
          this.originalUserSkills = JSON.parse(JSON.stringify(skillList.skills));
          this.userSkills = skillList.skills;
  
          this.originalWantedSkillsToDevelop = JSON.parse(JSON.stringify(skillList.skillInterests));
          this.wantedSkillsToDevelop = skillList.skillInterests;
  
          this.utilitiesService.updateIsLoading(false);
        },
        (error) => {
          throwError(error);
          this.utilitiesService.updateIsLoading(false);
        })
    );
  }

  viewGig(gigData: Gig): void {
    this.router.navigateByUrl(`/view-gig/${gigData.gigId}`, { state: gigData as Gig });
  }

  getRecommendedGigs(): void {
    this.activeSubscriptions.add(
      this.gigService.getRecommendedGigs(this.user.idTokenClaims.employeeId).subscribe(
        (recommendations: Gig[]) => {
          this.similarGigList = recommendations;
          this.utilitiesService.updateIsLoading(false);
        },
        (error) => {
          throwError(error);
          this.utilitiesService.updateIsLoading(false);
        })
    );
  }

  getRecommendedSkills(): void {
    this.activeSubscriptions.add(
      this.skillService.getRecommendedEmployeeSkills(this.user.idTokenClaims.employeeId).subscribe(
        (recommendations: PaginatedSkillList) => {
          this.suggestedSkills = recommendations.entries;
          this.utilitiesService.updateIsLoading(false);
        },
        (error) => {
          throwError(error);
          this.utilitiesService.updateIsLoading(false);
        })
    );
  }

  addEmployeeSkillsOrInterests(): void {
    let request: EmployeeSkillList = {
      employeeId: this.user.idTokenClaims.employeeId,
      skills: this.userSkills,
      skillInterests: this.wantedSkillsToDevelop
    };

    this.activeSubscriptions.add(
      this.skillService.addEmployeeSkillsOrInterests(request).subscribe(
        (success) => {
          this.utilitiesService.showSnackbarMessage('success', 'Skills Updated', 6000);
          this.utilitiesService.updateIsLoading(false);
          this.undoDisabled = false;
        },
        (error) => {
          throwError(error);
          this.utilitiesService.updateIsLoading(false);
        })
    );


  }

  undoFormChanges(): void {

    let request: EmployeeSkillList = {
      employeeId: this.user.idTokenClaims.employeeId,
      skills: this.originalUserSkills,
      skillInterests: this.originalWantedSkillsToDevelop
    };

    this.activeSubscriptions.add(
      this.skillService.addEmployeeSkillsOrInterests(request).subscribe(
        (success) => {
          this.utilitiesService.showSnackbarMessage('success', 'Skills Updated', 6000);
          this.getEmployeeSkills(this.user.idTokenClaims.employeeId);
          this.undoDisabled = true;
        },
        (error) => {
          throwError(error);
        },
        () => {
          this.utilitiesService.updateIsLoading(false);
        })
    );
  }

  getEmployeeCareerProfile(employeeId: string) {
    
    this.activeSubscriptions.add(
      this.skillService.getEmployeeCareerProfile(employeeId).subscribe(
        (employeeCareerProfile: EmployeeCareerProfile) => {
          this.employeeCareerProfile = employeeCareerProfile;
          //Display strings as array to be compatible with mat-table formatting
          this.employeeCareerProfile.achievements = new Array(this.employeeCareerProfile.achievements) as unknown as string;
          this.employeeCareerProfile.careerInterests = new Array(this.employeeCareerProfile.careerInterests) as unknown as string;
          this.employeeCareerProfile.jobInterests = new Array(this.employeeCareerProfile.jobInterests) as unknown as string;
  
          //Sort job history by end date, descending
          this.sortByEndDate(this.employeeCareerProfile.jobHistoryGroup);
          this.employeeCareerProfile.jobHistoryGroup.reverse();
          
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', 'There was an error retrieving the career profile. Please try again later.', 6000);
          this.utilitiesService.updateIsLoading(false);
        })
    );

  }

  sortByEndDate(jobHistory: JobHistory[]) {
    const distantFuture = new Date();
    const firstSort = 'end_date';

    jobHistory.sort((a, b) => {
      let dateA = a[firstSort] ? new Date(a[firstSort]) : distantFuture;
      let dateB = b[firstSort] ? new Date(b[firstSort]) : distantFuture;
      return dateA.getTime() - dateB.getTime();
    });
  }

  navigateBack(): void {
    window.history.back();
  }

  //Called from skill field output
  skillFieldValidator(shownSkills: Skill[]) {
    const uniqueValues = new Set(shownSkills.map((skill: Skill) => skill.skillName));

    if (uniqueValues.size < shownSkills.length) {
      this.duplicateSkillsSelected = true;
    }
    else {
      if (this.duplicateSkillsSelected) {
        this.duplicateSkillsSelected = false;
      }
      else {
        this.duplicateSkillsSelected = false;
        this.addEmployeeSkillsOrInterests();
      }

    }
    
  }

}

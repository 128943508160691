import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, finalize, fromEvent, takeWhile } from 'rxjs';
import { AppConfig } from 'src/app/config/app.config';
import { ChatMessage, InteractiveMessage, Message, MessageCreator, ResponseType } from 'src/app/models/chatbot/message';
import { UtilitiesService } from '../utilities.service';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { Conversation } from 'src/app/models/chatbot/conversation';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  public conversation$: Observable<ChatMessage[]>;
  public clearMessages$: Observable<null>;

  private _conversation$ = new BehaviorSubject<ChatMessage[]>([]);
  private _clearMessages$ = new Subject<null>();

  public isAdmin: boolean;
  public isManager: boolean;

  public idle$: Subject<boolean> = new Subject();
  public wake$: Subject<boolean> = new Subject();

  isIdle: boolean = false;
  private _idleAfterSeconds: number = 2;
  private _countDown: any;

  constructor(private http: HttpClient, private appConfig: AppConfig, private utilitiesService: UtilitiesService, private authService: AuthenticationService) {
    this.conversation$ = this._conversation$.asObservable();
    this.clearMessages$ = this._clearMessages$.asObservable();

    this.authService.getRoles().then((roleList) => {
      this.isAdmin = this.authService.isUserAdmin(roleList);
      this.isManager = this.authService.isUserManager(roleList);
    });

    // Track user interaction with chatbot using these events
    fromEvent(document, 'mousemove')
      .pipe(takeWhile(result => !this.idle$.closed))
      .subscribe(() => this.onChatInteraction());

    fromEvent(document, 'touchstart')
      .pipe(takeWhile(result => !this.idle$.closed))
      .subscribe(() => this.onChatInteraction());

    fromEvent(document, 'keydown')
      .pipe(takeWhile(result => !this.idle$.closed))
      .subscribe(() => this.onChatInteraction());
  }

  public getBotAnswer(payload: any): void {
    //payload can be a ChatMessage or an InteractiveMessage

    let messageToSend: ChatMessage = {
      createdBy: MessageCreator.User,
      content: (payload.content) ? payload.content : payload.value,
      responseType: ResponseType.Readonly,
    }

    this._conversation$.next([messageToSend]);

    const request: Message = this.formatRequest(messageToSend);
    let url: string = this.appConfig.apiBaseUrl + `/chatbot/chat`;

    this.http.post<any>(url, request)
    .pipe(
      finalize(() => {
        this.startConversation();
      })
    ).subscribe(
      (response: Conversation) => {
        let botResponse: ChatMessage = {
          createdBy: MessageCreator.Bot,
          content: response.messages[response.messages.length - 1].content,
          responseType: ResponseType.Readonly
        }
        this._conversation$.next([botResponse]);

      },

      (error) => {
        this.utilitiesService.showSnackbarMessage('warning', 'The chatbot service is experiencing an issue. Please try again later.');
      });
  }

  private formatRequest(message: ChatMessage): Message {
    return {
      inputText: message.content as string
    };
  }

  public clearMessages(): void {
    this._clearMessages$.next(null);
    this._conversation$.next(null);
  }

  public onChatInteraction(): void {

    if (this.isIdle) {
      this.isIdle = false;
      this.wake$.next(true);
    }

    // Upon user interaction, reset idle timer
    clearTimeout(this._countDown);

    this._countDown = setTimeout(() => {
      // If countdown is done without interaction - emit idle event
      this.isIdle = true;
      this.idle$.next(true);
    }, this._idleAfterSeconds * 1_000);
  }

  public terminateSession(): void {
    let messageToSend: ChatMessage = {
      createdBy: MessageCreator.Bot,
      content: 'Session Ended',
      responseType: ResponseType.Readonly
    }

    this._conversation$.next([messageToSend]);


    let url: string = this.appConfig.apiBaseUrl + `/chatbot/session/end`;


    this.http.post<any>(url, {}).subscribe(
      () => {},

      (error) => {
        this.utilitiesService.showSnackbarMessage('warning', 'The chatbot service is experiencing an issue. Please try again later.');
    });


  }

  public startSession(): void {
    let url: string = this.appConfig.apiBaseUrl + `/chatbot/session/start`;

    this.http.post<any>(url, {}).subscribe(
      (response) => {
        let botResponse: ChatMessage = {
          createdBy: MessageCreator.Bot,
          content: response.messages[0].content,
          responseType: ResponseType.Readonly
        }
        this._conversation$.next([botResponse]);

        let botFollowUpQuestions: ChatMessage = {
          createdBy: MessageCreator.Bot,
          content: response.messages[1].content,
          responseType: ResponseType.Interactive
        }

        this._conversation$.next([botFollowUpQuestions]);

      },
      (error) => {
        this.utilitiesService.showSnackbarMessage('warning', 'The chatbot service is experiencing an issue. Please try again later.');
      });
  }

  public extendSession(): void {
    let url: string = this.appConfig.apiBaseUrl + `/chatbot/session/extend`;

    this.http.post<any>(url, {}).subscribe(
      () => {},
      (error) => {
        this.utilitiesService.showSnackbarMessage('warning', 'The chatbot service is experiencing an issue. Please try again later.');
      });
  }

  public startConversation(): void {
    let url: string = this.appConfig.apiBaseUrl + `/chatbot/conversation/start`;

    this.http.post<any>(url, {}).subscribe(
      (response) => {
        let botResponse: ChatMessage = {
          createdBy: MessageCreator.Bot,
          content: response.messages[0].content,
          responseType: ResponseType.Readonly
        }
        this._conversation$.next([botResponse]);

        let botFollowUpQuestions: ChatMessage = {
          createdBy: MessageCreator.Bot,
          content: response.messages[1].content,
          responseType: ResponseType.Interactive
        }

        this._conversation$.next([botFollowUpQuestions]);

      },
      (error) => {
        this.utilitiesService.showSnackbarMessage('warning', 'The chatbot service is experiencing an issue. Please try again later.');
      });
  }

}

<mat-form-field *ngIf="isAutocompleteField">
    <mat-label>Search for User by Employee Name or ID</mat-label>
    <input type="text"
           matInput
           [formControl]="employeeControl"
           [matAutocomplete]="auto">
    <mat-spinner matSuffix *ngIf="isSpinnerLoading" diameter="25"></mat-spinner>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let filteredEmployee of filteredEmployees | async" [value]="filteredEmployee">
        <div>{{filteredEmployee.firstName}} {{filteredEmployee.lastName}}</div>
        <small class="employee-email">{{filteredEmployee.emailAddress}}</small>
      </mat-option>
    </mat-autocomplete>
</mat-form-field>


<mat-form-field  *ngIf="!isAutocompleteField">
    <mat-label>Search for User by Employee Name</mat-label>
    <input (keydown.enter)="emitSearchValue()" matInput [(ngModel)]="inputValue">
    <mat-spinner matSuffix *ngIf="isSpinnerLoading" diameter="25"></mat-spinner>
</mat-form-field>
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationDialogComponent } from '../notification-dialog/notification-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';

@Component({
  selector: 'app-dialog-entry',
  templateUrl: './dialog-entry.component.html',
  styleUrls: ['./dialog-entry.component.scss']
})
export class DialogEntryComponent implements OnInit {

  notificationID: number;

  constructor(public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationsService) {
      
      if (!this.notificationService.activeNotification) {

        this.route.params.subscribe(params => {
          this.notificationID = params.notificationId;
        });

        this.notificationService.getUserNotifications().subscribe((notifications) => {
          let foundNotification = notifications.find((notification) => notification.notificationID == this.notificationID);
          if (!foundNotification) {
            this.router.navigateByUrl('/home');
          }
          else {
            this.notificationService.activeNotification = foundNotification;
            this.openDialog();
          }
        });
      }

      else {
        this.openDialog();
      }

   }

  ngOnInit(): void {
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(NotificationDialogComponent, {
      width: '50vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.router.navigate(['../../'], { relativeTo: this.route });
    });
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'src/app/config/app.config';
import { MsalService } from '@azure/msal-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticatedUser } from '../models/authenticated-user';
import { AzureHelperService } from './azure-helper-service';
import { filter } from 'rxjs/operators';
import { AccountInfo } from '@azure/msal-common';
import { UserProfile } from '../models/user-profile';
import { UnionStatus } from 'src/app/shared/interfaces/employee/union-status.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public currentUser$: BehaviorSubject<AuthenticatedUser> = new BehaviorSubject(null);
  public loggedIn$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(private config: AppConfig, private msalService: MsalService, private azureService: AzureHelperService, private http: HttpClient) {
        // Get active logged in account info
        this.azureService
        .getActiveAccountObservable()
        .pipe(
          filter(
            (accountInfo: AccountInfo) =>
              accountInfo != null && accountInfo.username != null
          )
        )
        .subscribe((accountInfo: AccountInfo) => {
          this.currentUser$.next(accountInfo as unknown as AuthenticatedUser);
          this.loggedIn$.next(true);
        });
  }


  async signOut(): Promise<void> {
    try {
      await this.msalService.logout();
      localStorage.clear();
      sessionStorage.clear();
    }
    catch (error) {
      console.error('Error signing out: ', error);
    }
  }

  getUserProfile(): Observable<UserProfile> {
    let url: string = `https://graph.microsoft.com/v1.0/me`;
    return this.http.get<UserProfile>(url);
  }

  getUserPhoto(): Observable<any> {
    let url: string = `https://graph.microsoft.com/v1.0/me/photo/$value`;
    return this.http.get<any>(url, {responseType: 'blob' as 'json'});
  }

  async getRoles(): Promise<string[]> {
    let user: AuthenticatedUser;
    try {
      await this.currentUser$.subscribe( async (authenticatedUser: AuthenticatedUser) => {
        user = authenticatedUser;
      });
      return (user) ? user.idTokenClaims.roles : null;
    } 
    catch (error) {
      throw new Error(error);
    }
  }

  isUserManager(roles: string[]): boolean {   
    if(roles?.includes("tmpManager")) {
      return true;
    }
    else {
      return false;
    }
  }

  isUserAdmin(roles: string[]): boolean {   
    if(roles?.includes("tmpAdmin")) {
      return true;
    }
    else {
      return false;
    }
  }

  isUserContractor(roles: string[]): boolean {
      if (!roles?.includes('tmpEmployee')) {
        return true;
      }
      else {
        return false;
      }
  }

  isUserUnionEmployee(employeeId: string): Observable<UnionStatus> {
    let url: string = this.config.apiBaseUrl + `/employee/status?employeeId=${employeeId}`;
    return this.http.get<UnionStatus>(url);
  }
}
<div class="notification-modal" position="centered">
    <div class="notification-modal-header">
        <h1 class="notification-modal-header-title">{{notificationHeaderTitle}}</h1>
    </div>
    <div class="notification-modal-body">
      <ng-container *ngIf="!isFeedback || (isFeedback) && (isEmployeeApprovalDialog || isManagerApprovalDialog)">
            <h2 class="notification-modal-title">{{activeNotification?.notificationTitle}}
            </h2>
            <p class="notification-desc" [innerText]="activeNotification?.notificationText"></p>
        </ng-container>

        <ng-container *ngIf="isFeedback">
            <tmp-feedback [feedbackMode]='notificationFeedbackType' [notificationData]='activeNotification'
            (notificationClick)="refreshNotifications()" (submitedFeedback)="closeDialog()"></tmp-feedback>
        </ng-container>
    </div>
    <div class="button-div-center">

        <de-button *ngIf="!isManagerApprovalDialog && !isEmployeeApprovalDialog && !isFeedback" mat-dialog-close
        (buttonClick)="readNotification()" class="notification-modal-button" type="primary"
        size="small">DONE</de-button>

        <ng-container *ngIf="isManagerApprovalDialog">
            <de-button mat-dialog-close (buttonClick)="denyApproval()"
                class="notification-modal-button" type="secondary" size="small" [disabled]="activeNotification.readFlag">DENY</de-button>

            <de-button mat-dialog-close (buttonClick)="submitApproval()"
                class="notification-modal-button" type="primary" size="small" [disabled]="activeNotification.readFlag">APPROVE</de-button>

        </ng-container>

        <ng-container *ngIf="isEmployeeApprovalDialog">
            <de-button mat-dialog-close (buttonClick)="declineApproval()"
                class="notification-modal-button" type="secondary" size="small" [disabled]="activeNotification.readFlag">DECLINE</de-button>

            <de-button mat-dialog-close (buttonClick)="acceptApproval()"
                class="notification-modal-button" type="primary" size="small" [disabled]="activeNotification.readFlag">ACCEPT</de-button>
        </ng-container>

    </div>
</div>
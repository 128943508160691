import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, finalize, retry } from 'rxjs/operators';
import { UtilitiesService } from '../services/utilities.service';

@Injectable()
export class GlobalHttpInterceptor implements HttpInterceptor {

  constructor(private utilitiesService: UtilitiesService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestUrl: string = (request.urlWithParams) ? request.urlWithParams : "N/A";
      this.addLoader(requestUrl);
      return next.handle(request).pipe(
        // retry(3),
        delay(1500),
        catchError((error) => {
          return throwError(error);
        }),
        finalize(() => {
          // this.removeLoader(requestUrl); 
        })
      ) as Observable<HttpEvent<any>>;
    
  }

  private addLoader(requestUrl: string): void {
    if (this.isUrlValidForLoader(requestUrl)) {
      this.utilitiesService.updateIsLoading(true);
    }
  }

  private isUrlValidForLoader(requestUrl: string): boolean {
    if (requestUrl.includes("/skills/search") || requestUrl.includes("notifications") || requestUrl.includes("employee") || requestUrl.includes("chat") || requestUrl.includes("/assets")) {
      return false;
    }
    else {
      return true;
    }
  }
}
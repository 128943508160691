import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from 'src/app/config/app.config';
import { Feedback } from 'src/app/shared/interfaces/feedback/feedback-interface';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private http: HttpClient, private appConfig: AppConfig) { }

  reportProblem(feedback: Feedback): Observable<Feedback> {
    let url: string = this.appConfig.apiBaseUrl + '/feedback/reportProblem';
    return this.http.post<Feedback>(url, feedback, {responseType: 'text' as 'json'});
  }

  submitRating(feedback: Feedback): Observable<Feedback> {
    let url: string = this.appConfig.apiBaseUrl + '/feedback/submitRating';
    return this.http.post<Feedback>(url, feedback, {responseType: 'text' as 'json'});
  }

  submitGigParticipant(feedback: Feedback): Observable<Feedback> {
    let url: string = this.appConfig.apiBaseUrl + '/feedback/submitGigParticipant';
    return this.http.post<Feedback>(url, feedback, {responseType: 'text' as 'json'});
  }

  submitGigHost(feedback: Feedback): Observable<Feedback> {
    let url: string = this.appConfig.apiBaseUrl + '/feedback/submitGigHost';
    return this.http.post<Feedback>(url, feedback, {responseType: 'text' as 'json'});
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncatewords'
})
export class TruncatewordsPipe implements PipeTransform {

  transform(value: string, words : number): string {
    if(!value) return "";
    const result = value.length > words ? value.slice(0, words) + "..." : value;
    return result;
  }

}

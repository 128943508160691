import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationsComponent } from '../notifications/notifications.component';
import { Notification } from '../../interfaces/notifications-interface';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent implements OnInit {

  // constructor(@Inject(MAT_DIALOG_DATA) public data: { notification: Notification }, public notificationsComponent: NotificationsComponent,
  //   private dialogRef: MatDialogRef<NotificationDialogComponent>) { }

  constructor(public notificationsComponent: NotificationsComponent,
    private dialogRef: MatDialogRef<NotificationDialogComponent>, private notificationService: NotificationsService) { 
    }

  isManagerApprovalDialog: boolean = false;
  isEmployeeApprovalDialog: boolean = false;
  notificationHeaderTitle: string = 'Notification';
  notificationFeedbackType: string;
  isFeedback: boolean = false;
  activeNotification: Notification;

  ngOnInit(): void {

    this.activeNotification = this.notificationService.activeNotification;

    if(this.activeNotification.notificationType){
      if (this.activeNotification.notificationType === 'ManagerApproval') {
        this.isManagerApprovalDialog = true;
        this.notificationHeaderTitle = 'Approval Requested';
      }
      else if (this.activeNotification.notificationType === 'EmployeeApproval') {
        this.isEmployeeApprovalDialog = true;
        this.notificationHeaderTitle = 'Confirmation Requested';
      }
  
      else if (this.activeNotification.notificationType === 'Feedback') {
        this.isFeedback = true;
        this.notificationHeaderTitle = 'Feedback';
        if (this.activeNotification.applicantEmployeeID === this.notificationsComponent.user?.idTokenClaims.employeeId) {
          this.notificationFeedbackType = 'participant'
        }
        else {
          this.notificationFeedbackType = 'manager';
        }
      }
    }
  }

  readNotification(): void {
    if (!this.activeNotification.readFlag) {
      this.activeNotification.readFlag = true;
      this.notificationsComponent.notificationStatusUpdate(this.activeNotification.notificationID);
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  refreshNotifications(): void {
    this.notificationsComponent.getUserNotifications();
  }

  //For managers
  submitApproval(): void {
    this.notificationsComponent.submitApproval();
  }

  denyApproval(): void {
    this.notificationsComponent.denyApproval();
  }

  //For employees
  acceptApproval(): void {
    this.notificationsComponent.acceptApproval();
  }

  declineApproval(): void {
    this.notificationsComponent.declineApproval();
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from 'src/app/config/app.config';
import { GigApplication } from 'src/app/shared/interfaces/gig/gig-application.interface';
import { Gig } from 'src/app/shared/interfaces/gig/gig.interface';

@Injectable({
  providedIn: 'root'
})
export class GigApplicationService {

  constructor(private http: HttpClient, private appConfig: AppConfig) { }

  applyToGig(gigId: number): Observable<Gig[]> {
    let url: string = this.appConfig.apiBaseUrl + `/gigs/${gigId}/applications`;
    return this.http.post<Gig[]>(url, {});
  }

  withdrawGig(gigId: number, withdrawId : number): Observable<Gig[]> {
    let url: string = this.appConfig.apiBaseUrl + `/gigs/${gigId}/applications/${withdrawId}/withdraw`;
    return this.http.post<Gig[]>(url, {});
  }

  getGigApplicationDetails(gigId: number): Observable<GigApplication[]> {
    let url: string = this.appConfig.apiBaseUrl + `/gigs/${gigId}/applications`;
    return this.http.get<GigApplication[]>(url);
  }

  selectGigApplicant(gigId: number, applicationId: number): Observable<any> {
    let url: string = this.appConfig.apiBaseUrl + `/gigs/${gigId}/applications/${applicationId}/action/SelectionRequest`;
    return this.http.post<any>(url, {}, { responseType: 'text' as 'json' });
  }

  inviteApplicanttoInterview(gigId: number, applicationId: number): Observable<any> {
    let url: string = this.appConfig.apiBaseUrl + `/gigs/${gigId}/applications/${applicationId}/action/InviteToInterview`;
    return this.http.post<any>(url , {}, { responseType: 'text' as 'json' });
  }

  savedGig(request : any): Observable<any> {
    let url: string = this.appConfig.apiBaseUrl + `/gigs/bookmark`;
    return this.http.post<any>(url , request, { responseType: 'text' as 'json' });
  }

  deselectApplicant(gigId: number, gigApplicationId: number): Observable<any> {
    let url: string = this.appConfig.apiBaseUrl + `/gigs/${gigId}/applications/${gigApplicationId}/action/deselect`;
    return this.http.post<any>(url , {}, { responseType: 'text' as 'json' });
  }

  cancelGig(gigId,request : any): Observable<any> {
    let url: string = this.appConfig.apiBaseUrl + `/gigs/${gigId}/cancel`;
    return this.http.post<any>(url , request, { responseType: 'text' as 'json' });
  }
}

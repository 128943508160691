<ng-container *ngIf="userProfile">
    <div class="avatar-wrapper">
        <img class="avatar-background" src="../../../../assets/images/home/Avatar bkgd.png">

        <div class="avatar-img-wrapper">
            <img class="avatar-img" [src]="userPhoto">
        </div>

        <div class="avatar-title-wrapper" *ngIf="userProfile">
            <h1 class="avatar-title avatar-title-bold">{{userProfile?.givenName}} {{userProfile?.surname}}</h1>
            <h4 class="avatar-title">{{userProfile.jobTitle}}</h4>
        </div>

    </div>
    <div class="notification-wrapper">
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="name">
                <th class="notification-table-header" *matHeaderCellDef>
                    <div class="header-text" [matBadge]="unreadNotifications" [matBadgeHidden]="!unreadNotifications"
                        matBadgeOverlap="false">
                        Notifications
                    </div>
                    <div>
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                            <mat-icon svgIcon="filter"></mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button
                                [ngStyle]="{ 'font-weight': selectedFilterOption === filterOption ? 'bold' : 'normal' }"
                                *ngFor="let filterOption of notificationFilterOptions"
                                (click)="filterNotifications(filterOption)" mat-menu-item>{{filterOption}}</button>
                        </mat-menu>
                    </div>
                </th>
                <td mat-cell *matCellDef="let notification">
                    <div>
                        <div class="notification-header">
                            <h2 [ngClass]="{'notification-title': !notification.readFlag}">
                                {{notification.notificationTitle | truncatewords:40 }}</h2>
                            <span class="notification-time">{{notification.createDate | datePipe }}</span>
                        </div>
                        <p class="notification-desc"> {{notification.notificationText | truncatewords :60}}
                        </p>
                        <de-button [routerLink]="['notification', notification.notificationID]" (buttonClick)="populateNotificationData(notification)" type="text">[view]</de-button>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>


        <div class="notification-placeholder" *ngIf="!dataSource.length">
            No new notifications.
        </div>
    </div>
</ng-container>
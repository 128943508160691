<div class="main-page-container">
    <mat-card class="notifications-container">
        <tmp-notifications></tmp-notifications>
    </mat-card>

    <mat-card class="main-content-container">

        <div class="chatbot-container">

            <section class="chatbot-container-header">
                <h1 class="window-title">TMP Chatbot</h1>

                <button mat-button matSuffix mat-icon-button (click)="navigateHome()">
                    <mat-icon svgIcon="close"></mat-icon>
                </button>
            </section>

            <mat-divider></mat-divider>


            <section id="chat-window" class="chat-window" [class.active]="messages?.length">

                <div>
                    <ng-container *ngFor="let message of messages">
                        <div class="message-row">


                            <!-- Chat bubble container -->
                            <div class="message-container"
                                [ngClass]="{ 'from': message.author === 'bot', 'to': message.author === 'user' }">

                                <!-- Chatbot avatar -->
                                <div class="bot-avatar" *ngIf="message.showAvatar && message.author === 'bot'">CB</div>

                                <div *ngIf="message.responseType === 'readonly'" class="message"
                                    [ngClass]="{ 'from': message.author === 'bot', 'to': message.author === 'user', 'message-spacing': !message.showAvatar }"
                                    [innerHTML]="message.author === 'bot' ? (message.content | safeHtml) :  message.content">
                                    {{ message.content }}
                                </div>

                                <div *ngIf="message.responseType === 'interactive'">
                                    <button mat-button (click)="sendMessage(message)" mat-stroked-button disableRipple
                                        color="primary"
                                        [ngClass]="{'message-disabled': message.disabled || sessionTerminated }"
                                        class="interactive-message" *ngFor="let message of message.content"
                                        [disabled]="message.disabled || sessionTerminated">
                                        {{ message.text }}
                                    </button>
                                </div>

                                <!-- User avatar -->
                                <div class="user-avatar" *ngIf="message.showAvatar && message.author === 'user'">ME</div>

                            </div>

                        </div>


                    </ng-container>
                </div>

                <app-chat-loader *ngIf="isWaitingOnResponse()"></app-chat-loader>

            </section>

            <section>
                <mat-form-field class="chat-input-field" appearance="outline">
                    <mat-label>Write your message...</mat-label>
                    <input (keydown.enter)="sendMessage(customMessage)" [(ngModel)]="customMessage.content"
                        [disabled]="isWaitingOnResponse() || sessionTerminated" matInput maxlength="255">
                    <mat-hint align="end">{{customMessage.content?.length || 0}}/255</mat-hint>
                    <button (click)="sendMessage(customMessage)" [disabled]="isWaitingOnResponse() || sessionTerminated"
                        mat-icon-button matSuffix>
                        <mat-icon svgIcon="send"></mat-icon>
                    </button>
                </mat-form-field>
            </section>
        </div>


    </mat-card>


    <de-modal [showClose]="false" modalTitle="Your Session is Expiring Soon" [open]="sessionExpiringModalOpen"
        showMask="true" theme="secondary" width="auto">
        <div class="session-modal-body" position="centered">
            <div class="session-modal-content">
                <h2 class="session-modal-title">Click extend to continue session, or cancel to end your session.</h2>
                <p class="session-modal-desc">If no action is taken, the chatbot session will terminate.</p>
            </div>

            <div class="session-modal-button button-div-center">
                <de-button (buttonClick)="terminateSession()" type="secondary" size="small">CANCEL</de-button>
                <de-button (buttonClick)="extendSession()" type="primary" size="small">EXTEND</de-button>
            </div>
        </div>
    </de-modal>
</div>
<router-outlet></router-outlet>
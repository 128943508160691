<div class="main-page-container">
    <mat-card class="notifications-container">
        <tmp-notifications></tmp-notifications>
    </mat-card>
    <mat-card class="main-content-container">

        <form *ngIf="!previewMode" [formGroup]="createGigForm">
            <mat-form-field *ngIf="!previewMode" class="create-gig-form-field" appearance="fill">
                <mat-label>Gig Title</mat-label>
                <input [value]="(approvalMode) ? viewedGig?.gigName: null" formControlName="gigName" matInput>
            </mat-form-field>
            <div class="gig-description" *ngIf="viewedGig && !viewedGig?.isCloned">
                <h3 class="section-title-preview">Gig Host: {{viewedGig?.gigManager?.firstName}}
                    {{viewedGig?.gigManager?.lastName}}, {{viewedGig?.gigManager?.jobTitle}}</h3>
            </div>

            <section class="main-content-actions">

                <ng-container *ngIf="isManager && !isAdmin">
                    <de-button [disabled]="submittedGigForm" (buttonClick)="saveGig()" type="secondary"
                        size="small">{{submittedGigForm ? 'SUBMITTED' : 'SUBMIT'}}</de-button>
                    <de-button [disabled]="createGigForm.invalid || submittedGigForm"
                        (buttonClick)="togglePreviewMode()" *ngIf="!previewMode" type="secondary"
                        size="small">PREVIEW</de-button>
                    <de-button (buttonClick)="navigateHome()" *ngIf="!previewMode" type="secondary"
                        size="small">CANCEL</de-button>
                    <!-- <de-button [disabled]="createGigForm.invalid || submittedGigForm" (buttonClick)="saveGigDraftState()" *ngIf="!previewMode" type="primary" size="small">SAVE</de-button> -->
                </ng-container>

                <ng-container *ngIf="isAdmin">
                    <de-button *ngIf="approvalMode" (buttonClick)="toggleGigVisibilityModal()" size="small"
                        type="secondary">VISIBILITY</de-button>
                    <!-- <de-button *ngIf="approvalMode" (buttonClick)="toggleReviseGigModal()" [disabled]="createGigForm.invalid || submittedGigForm" size="small" type="secondary">REVISE</de-button> -->
                    <de-button *ngIf="approvalMode" [disabled]="createGigForm.invalid || submittedGigForm"
                        (buttonClick)="togglePostGigModal()" size="small" type="secondary">{{submittedGigForm ? 'POSTED'
                        : 'POST'}}</de-button>
                    <de-button *ngIf="!approvalMode" [disabled]="submittedGigForm" (buttonClick)="saveGig()"
                        size="small" type="secondary">{{submittedGigForm ? 'SUBMITTED' : 'SUBMIT'}}</de-button>
                    <de-button [disabled]="createGigForm.invalid || submittedGigForm"
                        (buttonClick)="togglePreviewMode()" size="small" type="secondary">PREVIEW</de-button>
                    <de-button [disabled]="submittedGigForm" (buttonClick)="navigateHome()" size="small"
                        type="secondary">CANCEL</de-button>
                    <!-- <de-button (buttonClick)="updateGigDraftState()" [disabled]="createGigForm.invalid || submittedGigForm" size="small" type="primary">SAVE</de-button> -->
                </ng-container>

                <de-modal #deModal modalTitle="Gig Visibility" [open]="visibilityFilterModalOpen" showMask="true"
                    (close)="toggleGigVisibilityModal()" theme="secondary" width="60vw" height="70vh">
                    <div position="centered" class="visibility-modal">
                        <section>
                            <h1 class="modal-section-title-first">Show This Gig To Specific Users</h1>
                            <tmp-employee-search [isSpinnerLoading]="isSpinnerLoading"
                                (searchText)="searchForEmployee($event)"
                                class="visibility-filter-field"></tmp-employee-search>
                        </section>

                        <section [hidden]="!gigVisibilityDataSource.data.length" class="employee-list">
                            <h1 class="modal-section-title">Employee List</h1>

                            <table mat-table [dataSource]="gigVisibilityDataSource">
                                <ng-container matColumnDef="employeeName">
                                    <th mat-header-cell *matHeaderCellDef>Employee Name</th>
                                    <td mat-cell *matCellDef="let employee"> {{employee.firstName}}
                                        {{employee.lastName}} </td>
                                </ng-container>

                                <ng-container matColumnDef="employeeId">
                                    <th mat-header-cell *matHeaderCellDef>Employee ID</th>
                                    <td mat-cell *matCellDef="let employee"> {{employee.employeeId}} </td>
                                </ng-container>

                                <ng-container matColumnDef="select">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <mat-checkbox (change)="$event ? masterToggle() : null"
                                            [checked]="gigVisibilitySelection.hasValue() && isAllSelected()"
                                            [indeterminate]="gigVisibilitySelection.hasValue() && !isAllSelected()">
                                        </mat-checkbox>
                                    </th>
                                    <td mat-cell *matCellDef="let employee">
                                        <mat-checkbox (click)="$event.stopPropagation()"
                                            (change)="gigVisibilitySelection.toggle(employee)"
                                            [checked]="gigVisibilitySelection.isSelected(employee)">
                                        </mat-checkbox>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="gigVisibilityColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: gigVisibilityColumns;"></tr>
                            </table>

                            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
                            </mat-paginator>
                        </section>

                        <section class="gig-visibility-buttons submit-modal-button">
                            <de-button (buttonClick)="toggleGigVisibilityModal(); resetSelectionData()"
                                class="notification-modal-button" type="secondary" size="small">CANCEL</de-button>
                            <de-button [disabled]="!canSaveVisibilityChanges" (buttonClick)="saveGigVisibility()"
                                class="notification-modal-button" type="primary" size="small">SAVE</de-button>
                        </section>

                    </div>
                </de-modal>

            </section>

            <section class="gig-description-container">
                <div class="gig-category-info gig-description">
                    <mat-form-field appearance="fill">
                        <mat-label>Category</mat-label>
                        <mat-select [compareWith]="compareCategoryObjects" formControlName="gigCategory"
                            (selectionChange)="onCategoriesChange($event)">
                            <mat-option *ngFor="let gigCategory of gigCategories"
                                [value]="gigCategory">{{gigCategory.gigCategoryName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <img [src]="imgBycat">
                </div>

                <div class="gig-description">
                    <h2 class="gig-description-title">This Gig is About...</h2>
                    <quill-editor class="quill-editor" [modules]="{toolbar : toolbarOptions}"
                        formControlName="gigDescription">
                    </quill-editor>
                </div>

                <div class="gig-description">
                    <h2 class="gig-description-title">Deliverables and Milestones</h2>
                    <quill-editor class="quill-editor" [modules]="{toolbar : toolbarOptions}"
                        formControlName="gigDeliverables">
                    </quill-editor>
                </div>
            </section>

            <skill-chip-field skillsFieldRequired class="skill-chip-field" fieldLabel="What Skills Do You Need?"
                (updatedSkills)="skillFieldValidator($event)" [onSubmitSkillsValidation]="onSubmitSkillsValidation"
                [shownSkills]="gigSkills" [allSkills]="allSkills" [disabled]="disableSkillChipField"></skill-chip-field>

            <section class="gig-additional-data-container">
                <div class="gig-additional-data">
                    <h2 class="gig-additional-data-title">Timeframe</h2>
                    <mat-form-field class="form-field-spacing" appearance="fill">
                        <mat-label>Duration</mat-label>
                        <mat-select [compareWith]="compareFn" formControlName="gigDuration">
                            <mat-option [value]="gigDurationOption"
                                *ngFor="let gigDurationOption of gigDurationOptions">{{gigDurationOption}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- Only show date range if user is a manager editing a gig that's In Recuiting or Fully Staffed -->
                    <mat-form-field *ngIf="!((!isAdmin && !viewedGig?.isCloned) && (viewedGig?.gigStatus === 'In Recruiting' || viewedGig?.gigStatus === 'Fully Staffed'))" class="form-field-spacing" appearance="fill">
                        <mat-label>Enter a date range *</mat-label>
                        <mat-date-range-input [formGroup]="createGigForm.get('dateRange')" [rangePicker]="picker">
                            <input formControlName="gigStartDate" matStartDate placeholder="Start date">
                            <input formControlName="gigEndDate" matEndDate placeholder="End date">
                        </mat-date-range-input>
                        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle disableRipple matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>

                    <!-- Only show end date if user is a manager editing a gig that's In Recuiting or Fully Staffed -->
                    <mat-form-field *ngIf="(!isAdmin && !viewedGig?.isCloned) && (viewedGig?.gigStatus === 'In Recruiting' || viewedGig?.gigStatus === 'Fully Staffed')" [formGroup]="createGigForm.get('dateRange')" class="form-field-spacing" appearance="fill">
                            <mat-label>Enter an end date</mat-label>
                            <input [min]="minDate" formControlName="gigEndDate" matInput [matDatepicker]="picker">
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matSuffix [for]="picker">
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="form-field-spacing" appearance="fill">
                        <mat-label>What Availability Do You Expect?</mat-label>
                        <mat-select [compareWith]="compareFn" formControlName="gigAvailability">
                            <mat-option [value]="timeCommitmentOption"
                                *ngFor="let timeCommitmentOption of timeCommitmentOptions">{{timeCommitmentOption}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="gig-additional-data">
                    <h2 class="gig-additional-data-title">Gig Details</h2>
                    <div class="gig-additional-data-location">
                        <h2 class="gig-additional-data-subtitle">Location</h2>
                        <mat-radio-group formControlName="gigMobilityClassification" class="location-radio-container"
                            (change)="onRadioChange($event)">
                            <mat-radio-button value="onsite">Onsite</mat-radio-button>
                            <mat-radio-button value="remote">Remote</mat-radio-button>
                            <mat-radio-button value="hybrid">Hybrid</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <mat-form-field appearance="fill"
                        *ngIf="createGigForm.get('gigMobilityClassification').value == 'onsite' || createGigForm.get('gigMobilityClassification').value == 'hybrid'">
                        <mat-label>Where is This Gig Located?</mat-label>
                        <input type="text" matInput formControlName="gigLocation" [matAutocomplete]="auto">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                            [displayWith]="displayLocations">
                            <mat-option *ngFor="let gigLocation of filteredGigLocations | async" [value]="gigLocation">
                                {{gigLocation.locationName}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>


                    <mat-form-field appearance="fill">
                        <mat-label>How Many Team Members Do You Need?</mat-label>
                        <mat-select [compareWith]="compareFn" formControlName="gigTeamMemberTotal">
                            <mat-option *ngFor="let noOfPositionOption of noOfPositionOptions"
                                [value]="noOfPositionOption">{{noOfPositionOption}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- <div class="gig-additional-data" *ngIf="isAdmin" > -->
                    <!-- <h2 class="gig-additional-data-title">Resources</h2>
                    <h2 class="gig-additional-data-subtitle">Upload Files</h2>
                    <span>(5MB limit)</span>

                    <div class="resource-container"> 
                         <div class="resource"> -->
                            <!-- Viewing a previously uploaded file from the server side -->
                            <!-- <p *ngIf="viewedGig && !selectedFileName?.name" class="file-name">{{selectedFile ? selectedFile?.gigFileKey : 'Choose File'}}</p> -->

                            <!-- Viewing a newly uploaded file from the client side -->
                            <!-- <p *ngIf="!viewedGig || selectedFileName" class="file-name">{{selectedFileName ? selectedFileName?.name : 'Choose File'}}</p>
                            <input type="file" style ="display: none;" (change)="onFileSelected($event)" #fileInput>
                            <button mat-button matSuffix mat-icon-button (click)="clearFile()" *ngIf="selectedFile">
                                <mat-icon svgIcon="close"></mat-icon>
                            </button>
                            <de-button mat-raised-button type="secondary" (click)="fileInput.click()" size="small">BROWSE</de-button>
                        </div>
                    </div>  -->
                     <!-- <h2 class="gig-additional-data-subtitle">Getting Started With Gigs</h2>
                    <div class="resource-container">
                        <div class="resource">
                            <p class="file-name">Gig Guidelines.doc</p>
                            <de-button type="secondary" size="small">VIEW</de-button>
                        </div>
                        <div class="resource">
                            <p class="file-name">Review the Full Gigs Process.doc</p>
                            <de-button type="secondary" size="small">VIEW</de-button>
                        </div>
                    </div> -->
                <!-- </div> -->
            </section>
        </form>


        <div *ngIf="previewMode">
            <div class="gig-title">
                <h1>{{createGigForm.get('gigName').value}}</h1>
            </div>
            <div class="gig-description" *ngIf="viewedGig && !viewedGig?.isCloned">
                <h3 class="section-title-preview">Gig Host: {{viewedGig?.gigManager?.firstName}}
                    {{viewedGig?.gigManager?.lastName}}, {{viewedGig?.gigManager?.jobTitle}}</h3>
            </div>

            <section class="main-content">
                <div class="main-content-actions">
                    <de-button [disabled]="createGigForm.invalid || submittedGigForm"
                        (buttonClick)="toggleSubmitModal()"
                        *ngIf="(!viewedGig) || (viewedGig?.gigStatus !== 'Pending Approval')" type="secondary"
                        size="small">{{submittedGigForm ? 'SUBMITTED' : 'SUBMIT'}}</de-button>
                    <de-button [disabled]="createGigForm.invalid || submittedGigForm"
                        (buttonClick)="togglePostGigModal()"
                        *ngIf="(isAdmin) && (viewedGig?.gigStatus === 'Pending Approval')" type="secondary"
                        size="small">{{submittedGigForm ? 'POSTED' : 'POST'}}</de-button>
                    <de-button [disabled]="submittedGigForm" (buttonClick)="togglePreviewMode()" type="primary"
                        size="small">EDIT</de-button>
                </div>
                <section class="gig-description-container-preview">
                    <img class="gig-image" [src]="imgBycat">
                    <div class="gig-description-preview">
                        <h3 class="section-title-preview">This Gig Is About...</h3>
                        <div class="gig-description-preview-text ql-editor ql-editor-preview"
                            [innerHtml]="createGigForm.get('gigDescription').value"></div>
                    </div>
                    <div class="gig-description-preview">
                        <h3 class="section-title-preview">Deliverables and Milestones</h3>
                        <div class="gig-description-preview-text ql-editor ql-editor-preview"
                            [innerHtml]="createGigForm.get('gigDeliverables').value"></div>
                    </div>
                </section>
                <section>
                    <h3 class="section-title-preview">Gig Skills</h3>
                    <skill-chip-field chipOnlyInput [shownSkills]="gigSkills"></skill-chip-field>
                </section>
                <section class="gig-additional-data-container">
                    <div class="gig-additional-data">
                        <h3 class="section-title-preview">Timeframe</h3>

                        <div class="gig-additional-data-preview-spacing">
                            <h3 class="gig-additional-data-subtitle">Duration</h3>
                            <p>{{createGigForm.get('gigDuration').value}}</p>
                        </div>
                        <div class="gig-additional-data-preview-spacing">
                            <h3 class="gig-additional-data-subtitle">Date Range</h3>
                            <p>{{this.createGigForm.get('dateRange').get('gigStartDate').value | date: 'MM/dd/yyyy'}} -
                                {{this.createGigForm.get('dateRange').get('gigEndDate').value | date: 'MM/dd/yyyy'}}</p>
                        </div>
                        <div class="gig-additional-data-preview-spacing">
                            <h3 class="gig-additional-data-subtitle">What Availability Do You Expect?</h3>
                            <p>{{createGigForm.get('gigAvailability').value}}</p>
                        </div>


                    </div>
                    <div class="gig-additional-data">
                        <h3 class="section-title-preview">Gig Details</h3>

                        <div class="gig-additional-data-preview-spacing">
                            <h3 class="gig-additional-data-subtitle">Location</h3>
                            <p>{{createGigForm.get('gigMobilityClassification').value | titlecase}}</p>
                        </div>
                        <div *ngIf="createGigForm.get('gigLocation').value" class="gig-additional-data-preview-spacing">
                            <h3 class="gig-additional-data-subtitle">Where Is This Gig Located?</h3>
                            <p>{{chosenLocation.locationName}}</p>
                        </div>
                        <div class="gig-additional-data-preview-spacing">
                            <h3 class="gig-additional-data-subtitle">How Many Team Members Are Needed?</h3>
                            <p>{{createGigForm.get('gigTeamMemberTotal').value}}</p>
                        </div>
                    </div>

                    <!-- <div class="gig-additional-data">
                        <h3 class="section-title-preview">Resources</h3>
                        <div class="gig-additional-data-preview-spacing">
                            <h3 class="gig-additional-data-subtitle">Uploaded Files</h3>
                                <p *ngIf="selectedFileName" class="gig-attachment">{{selectedFileName?.name}}</p>
                                <ng-container *ngIf="viewedGig?.gigFile && selectedFile?.gigFileKey">
                                    <p *ngFor="let file of viewedGig?.gigFile" class="gig-attachment">{{file?.gigFileKey}}</p>
                                </ng-container>
                                <p *ngIf="!selectedFile" class="gig-attachment">(none)</p>
                        </div>
                    </div> -->
                </section>
                <section *ngIf="similarGigs.length" class="similar-gigs">
                    <h3 class="similar-gigs-title">Similar Gigs</h3>
                    <div class="similar-gig-feed">
                        <mat-card *ngFor="let gig of similarGigs">
                            <mat-card-header class="gig-header-container">
                                <div class="gig-header">
                                    <mat-card-title>{{gig.gigTitle}}</mat-card-title>
                                </div>
                                <div class="gig-header">
                                    <mat-card-subtitle>{{gig.gigTeam}}</mat-card-subtitle>
                                </div>

                            </mat-card-header>
                            <img mat-card-image [src]="getImgUrl(gig.gigCategory?.gigCategoryName)"
                                alt="Photo of a Shiba Inu">
                            <mat-card-content>
                                <div class="gig-poster-container">
                                    <div mat-card-avatar class="gig-poster-avatar"></div>

                                    <div class="gig-poster-info">
                                        <mat-card-title class="gig-leader-name">{{gig.gigLeaderName}}</mat-card-title>
                                        <mat-card-subtitle
                                            class="gig-leader-title">{{gig.gigLeaderTitle}}</mat-card-subtitle>
                                    </div>
                                </div>

                                <div class="gig-status-container">
                                    <mat-card-subtitle class="gig-posted-date">{{gig.gigPostedDate}}</mat-card-subtitle>
                                </div>

                            </mat-card-content>
                            <mat-card-actions>
                                <de-button type="secondary" size="small">VIEW GIG</de-button>
                            </mat-card-actions>
                        </mat-card>
                    </div>
                </section>
            </section>
        </div>

        <de-modal modalTitle="Submit Gig" [open]="showSubmitModal" showMask="true" (close)="toggleSubmitModal()"
            theme="secondary" width="auto">
            <div class="submit-modal-body" position="centered">
                <div class="submit-modal-content">
                    <h2 class="submit-modal-title">Approval Required</h2>
                    <p class="submit-modal-desc">Before your gig is posted, it will need to be approved by the gig
                        admin.</p>
                </div>

                <div class="submit-modal-button">
                    <de-button (buttonClick)="toggleSubmitModal()" type="secondary" size="small">CANCEL</de-button>
                    <de-button (buttonClick)="submitGig()" type="primary" size="small">DONE</de-button>
                </div>
            </div>
        </de-modal>

        <de-modal modalTitle="Post Gig" [open]="showPostGigModal" showMask="true" [showClose]="false" theme="secondary"
            width="auto">
            <div class="post-gig-modal-body" position="centered">
                <div class="post-gig-modal-content">
                    <p>Are you sure?</p>
                </div>

                <div class="submit-modal-button">
                    <de-button (buttonClick)="togglePostGigModal()" type="secondary" size="small">CANCEL</de-button>
                    <de-button (buttonClick)="postGig()" type="primary" size="small">POST</de-button>
                </div>
            </div>
        </de-modal>

        <de-modal modalTitle="Revise Gig" [open]="showReviseGigModal" showMask="true" [showClose]="false"
            theme="secondary" width="auto">
            <div class="revise-gig-modal" position="centered">
                <div class="revise-gig-modal-content">
                    <mat-form-field class="revise-gig-modal-field" appearance="outline">
                        <mat-label>Add Comments</mat-label>
                        <textarea [(ngModel)]="reviseGigComment" required matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"></textarea>
                    </mat-form-field>
                </div>
                <div class="revise-gig-modal-actions">
                    <de-button (buttonClick)="toggleReviseGigModal()" type="secondary" size="small">CANCEL</de-button>
                    <de-button (buttonClick)="reviseGig()" class="notification-modal-button" type="primary"
                        size="small">SEND</de-button>
                </div>
            </div>
        </de-modal>

    </mat-card>
</div>
<router-outlet></router-outlet>
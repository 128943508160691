<div class="main-page-container">
    <mat-card class="notifications-container">
        <tmp-notifications></tmp-notifications>
    </mat-card>
    <mat-card class="main-content-container">

        <ng-container *ngIf="pageLoaded">
            <div class="feed-title">
                <h1>Gig Feed</h1>
            </div>
            <div class="main-content-actions">
                <mat-chip-list #chipList>
                    <mat-chip #chip="matChip"
                              *ngFor="let gigFilter of gigFilters"
                              [selected]="gigFilter.selected"
                              (click)="(gigFilter.selected && gigFilter.name !== selectedGigFilter.name) ? chip.deselect() : chip.select()"
                              (selectionChange)="onSelectedGigFilter($event, gigFilter)"
                              disableRipple>
                        {{gigFilter.name}}
                    </mat-chip>
                </mat-chip-list>
                <div class="search-filter-container">
                    <div class="search-filter-container-buttons">
                        <button mat-button disableRipple [matMenuTriggerFor]="sortGigResultMenu">
                            Sort
                            <mat-icon svgIcon="arrow_solid_down_base"></mat-icon>
                        </button>
                        <mat-menu #sortGigResultMenu="matMenu">
                            <button [ngStyle]="{ 'font-weight': selectedSortOption === gigSortOption ? 'bold' : 'normal' }" (click)="sortByOption(gigSortOption)" disableRipple *ngFor="let gigSortOption of gigSortOptions" mat-menu-item>{{gigSortOption}}</button>
                        </mat-menu>
                        <button disableRipple mat-button [matMenuTriggerFor]="filterGigResultMenu">
                            Filter
                            <mat-icon svgIcon="filter"></mat-icon>
                        </button>
                        <mat-menu #filterGigResultMenu="matMenu">
                            <div class="filter-button-options">
                                <de-button (buttonClick)="clearFilters()" size="small" type="secondary">CLEAR</de-button>
                                <de-button (buttonClick)="applyFilter()" size="small" type="primary">DONE</de-button>
                            </div>
                            <mat-divider></mat-divider>

                            <mat-checkbox [ngStyle]="{ 'font-weight': filterGigMenuOptions.skillsIHaveOption.checked ? 'bold' : 'normal' }"[(ngModel)]="filterGigMenuOptions.skillsIHaveOption.checked" [value]="filterGigMenuOptions.skillsIHaveOption.value" (click)="updateFilter('Best Match', filterGigMenuOptions.skillsIHaveOption.value)" (click)="$event.stopPropagation()" class="mat-menu-item">{{filterGigMenuOptions.skillsIHaveOption.value}}</mat-checkbox>
                            <mat-checkbox [ngStyle]="{ 'font-weight': filterGigMenuOptions.skillsIWantToDevelopOption.checked ? 'bold' : 'normal' }"[(ngModel)]="filterGigMenuOptions.skillsIWantToDevelopOption.checked" [value]="filterGigMenuOptions.skillsIWantToDevelopOption.value" (click)="updateFilter('Best Match', filterGigMenuOptions.skillsIWantToDevelopOption.value)" (click)="$event.stopPropagation()" class="mat-menu-item">{{filterGigMenuOptions.skillsIWantToDevelopOption.value}}</mat-checkbox>

                            <button [ngStyle]="{ 'font-weight': filterGigMenuOptions.locationMenu.info.checked ? 'bold' : 'normal' }" disableRipple [matMenuTriggerFor]="locationMenu" mat-menu-item>Location</button>
                            <button [ngStyle]="{ 'font-weight': filterGigMenuOptions.gigDurationMenu.info.checked ? 'bold' : 'normal' }" disableRipple [matMenuTriggerFor]="gigDurationMenu" mat-menu-item>Gig Duration</button>
                            <button [ngStyle]="{ 'font-weight': filterGigMenuOptions.gigAvailabilityMenu.info.checked ? 'bold' : 'normal' }" disableRipple [matMenuTriggerFor]="gigAvailabilityMenu" mat-menu-item>Gig Availability - Hours Per Week</button>
                            <button [ngStyle]="{ 'font-weight': filterGigMenuOptions.gigCategoryMenu.info.checked ? 'bold' : 'normal' }" disableRipple [matMenuTriggerFor]="gigCategoryMenu" mat-menu-item>Gig Category</button>
                            <button [ngStyle]="{ 'font-weight': filterGigMenuOptions.gigStatusMenu.info.checked ? 'bold' : 'normal' }" disableRipple [matMenuTriggerFor]="gigStatusMenu" mat-menu-item>Gig Status</button>
                        </mat-menu>
    
                        <mat-menu #locationMenu>
                            <mat-checkbox [ngStyle]="{ 'font-weight': locationMenuOption.checked ? 'bold' : 'normal' }" *ngFor="let locationMenuOption of filterGigMenuOptions.locationMenu.options" [(ngModel)]="locationMenuOption.checked" [value]="locationMenuOption.value" (change)="updateFilter(filterGigMenuOptions.locationMenu.info.value, locationMenuOption.value, locationMenuOption, filterGigMenuOptions.locationMenu)" (click)="$event.stopPropagation()" class="mat-menu-item">
                                {{locationMenuOption.value | titlecase}}
                            </mat-checkbox>
                        </mat-menu>
                        <mat-menu #gigDurationMenu>
                            <mat-checkbox [ngStyle]="{ 'font-weight': gigDurationMenuOption.checked ? 'bold' : 'normal' }" *ngFor="let gigDurationMenuOption of filterGigMenuOptions.gigDurationMenu.options" [(ngModel)]="gigDurationMenuOption.checked" (click)="$event.stopPropagation()" [value]="gigDurationMenuOption.value" (change)="updateFilter(filterGigMenuOptions.gigDurationMenu.info.value, gigDurationMenuOption.value, gigDurationMenuOption, filterGigMenuOptions.gigDurationMenu)" class="mat-menu-item">
                                {{gigDurationMenuOption.value}}
                            </mat-checkbox>
                        </mat-menu>
                        <mat-menu #gigAvailabilityMenu>
                            <mat-checkbox [ngStyle]="{ 'font-weight': gigAvailabilityMenuOption.checked ? 'bold' : 'normal' }" *ngFor="let gigAvailabilityMenuOption of filterGigMenuOptions.gigAvailabilityMenu.options" [(ngModel)]="gigAvailabilityMenuOption.checked" (click)="$event.stopPropagation()" [value]="gigAvailabilityMenu" (change)="updateFilter(filterGigMenuOptions.gigAvailabilityMenu.info.value, gigAvailabilityMenuOption.value, gigAvailabilityMenuOption, filterGigMenuOptions.gigAvailabilityMenu)" class="mat-menu-item">
                                {{gigAvailabilityMenuOption.value}}
                            </mat-checkbox>
                        </mat-menu>
                        <mat-menu #gigCategoryMenu>
                            <mat-checkbox [ngStyle]="{ 'font-weight': gigCategoryMenuOption.checked ? 'bold' : 'normal' }" *ngFor="let gigCategoryMenuOption of filterGigMenuOptions.gigCategoryMenu.options" [(ngModel)]="gigCategoryMenuOption.checked" (click)="$event.stopPropagation()" [value]="gigCategoryMenuOption.value" (change)="updateFilter(filterGigMenuOptions.gigCategoryMenu.info.value, gigCategoryMenuOption.value, gigCategoryMenuOption, filterGigMenuOptions.gigCategoryMenu)" class="mat-menu-item">
                                {{gigCategoryMenuOption.value}}
                            </mat-checkbox>
                        </mat-menu>
                        <mat-menu #gigStatusMenu>
                            <mat-checkbox [ngStyle]="{ 'font-weight': gigStatusMenuOption.checked ? 'bold' : 'normal' }" *ngFor="let gigStatusMenuOption of filterGigMenuOptions.gigStatusMenu.options" [(ngModel)]="gigStatusMenuOption.checked" (click)="$event.stopPropagation()" [value]="gigStatusMenuOption.value" (change)="updateFilter(filterGigMenuOptions.gigStatusMenu.info.value, gigStatusMenuOption.value, gigStatusMenuOption, filterGigMenuOptions.gigStatusMenu)" class="mat-menu-item">
                                {{gigStatusMenuOption.value}}
                            </mat-checkbox>
                        </mat-menu>
                    </div>
                    <mat-form-field appearance="outline">
                        <span matPrefix>
                            <mat-icon svgIcon="search"></mat-icon>
                        </span>
                        <input [(ngModel)]="searchText" matInput (keyup.enter)="searchGigs()">
                        <button *ngIf="searchText" matSuffix mat-icon-button disableRipple (click)="clearSearchResults()">
                            <mat-icon svgIcon="close"></mat-icon>
                        </button>
                    </mat-form-field>
                    <de-button *ngIf="isManager || isAdmin" type="primary" size="small" (buttonClick)="navigateToCreateGig()">CREATE</de-button>
    
                </div>
            </div>
        </ng-container>

        <div  *ngIf="gigList.length || (searchText || !searchText)" class="feed-content">
            <!-- TODO: turn all this into a shared component with a preview mode/input? -->
            <mat-card *ngFor="let gig of filterGigList">
                <mat-card-header class="gig-header-container">
                    <div class="gig-header">
                        <mat-card-title [matTooltip]="gig.gigName">{{gig.gigName | truncatewords:20 }}</mat-card-title>
                        <button  mat-icon-button (click)="unSavedGig(gig.gigId)">
                            <mat-icon *ngIf="gig.savedGig" class="saved-icon" svgIcon="saved"></mat-icon>
                        </button>
                        <mat-card-title class="gig-duration">{{gig.gigDuration}}</mat-card-title>
                    </div>
                    <div class="gig-header">   
                        <mat-card-subtitle>{{gig.gigTeam}}</mat-card-subtitle>
                        <mat-card-subtitle>Starts {{gig.startDate | date: 'MM/dd/yyyy'}}</mat-card-subtitle>
                    </div>

                </mat-card-header>
                <img mat-card-image [src]="getImgUrl(gig.gigCategory?.gigCategoryName)">
                <mat-card-content>
                    <div class="gig-poster-container">
                        <div mat-card-avatar class="gig-poster-avatar"></div>

                        <div class="gig-poster-info">
                            <mat-card-title class="gig-leader-name">{{gig.gigManager?.firstName}} {{gig.gigManager?.lastName}}</mat-card-title>
                            <mat-card-subtitle class="gig-leader-title">{{gig.gigManager?.jobTitle}}</mat-card-subtitle>
                        </div>
                    </div>

                    <div class="gig-status-container">
                        <mat-card-subtitle class="gig-status">{{gig.gigStatus}}</mat-card-subtitle>
                        <mat-card-subtitle class="gig-posted-date">{{(gig.gigPostedDate) ? 'Posted ' : ''}} {{gig.gigPostedDate | date: 'MM/dd/yyyy'}}</mat-card-subtitle>
                    </div>

                </mat-card-content>
                <mat-card-actions>
                  <div>
                      <p>{{gig.gigCategory?.gigCategoryName}}</p>
                      <ng-container *ngIf="gig.gigLocation">
                        <p>{{gig.gigLocation?.locationName}} ({{gig.gigLocation?.city}} {{gig.gigLocation?.stateCode}})</p>
                      </ng-container>
                      <p>{{gig.gigWorkerMobilityClassification | titlecase}}</p>
                  </div>
                  <de-button type="secondary" size="small" (buttonClick)="viewGig(gig)">VIEW GIG</de-button>
                </mat-card-actions> 
            </mat-card>
        </div>

        <p *ngIf="!filterGigList.length && pageLoaded" class="no-results-msg">No results for search.</p>
    </mat-card>
</div>
<router-outlet></router-outlet>
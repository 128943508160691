import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { GigService } from 'src/app/services/gig/gig.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { GigReport } from 'src/app/shared/interfaces/gig/gig-report.interface';

@Component({
  selector: 'app-gig-report',
  templateUrl: './gig-report.component.html',
  styleUrls: ['./gig-report.component.scss'],
  providers: [DatePipe]
})
export class GigReportComponent implements OnInit {
  searchText: any = null;
  gigVisibilityColumns = ['gigId','gigName','gigStatus', 'gigHostName', 'gigHostEmployeeId', 'gigHostEmailAddress', 'gigHostFercClassification',
    'gigApplicantName', 'gigApplicantEmployeeId', 'gigApplicantEmailAddress', 'gigApplicantFercClassification', 'gigApplicationStatus'];
  pageSizeOptions: number[] = [10, 25, 50, 100];
  private paginator: MatPaginator;
  private sort: MatSort;
  gigVisibilityDataSource: any = new MatTableDataSource<any>();
  activeSubscriptions: Subscription = new Subscription();
  startDate: any = null;
  endDate: any = null;
  maxDate = new Date();
  gigList: GigReport[] = [];
  gigTotalRecord: number = 0;
  itemPerPage: number = 10;
  pageNo: number = 1;
  sortColumnPayload: any = {
    "gigHostEmployeeId": "ASC"
  }



  @ViewChild(MatPaginator, { static: false }) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  @ViewChild(MatSort, { static: false }) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }
  constructor(private gigService: GigService, private utilitiesService: UtilitiesService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.getGigDataList();
  }

  //function to search gig by entering value
  searchGigs(): void {
      this.pageNo = 1;
      this.getGigDataList();
      this.setDataSourceAttributes();
  }

  clearSearchResults(): void {
    this.searchText = null;
    this.searchGigs();
  }

  //function to cal after html page is rendered
  ngAfterViewInit(): void {
    this.gigVisibilityDataSource.paginator = this.paginator;
    this.gigVisibilityDataSource.sort = this.sort;
  }

  //function to sort data
  sortData(sort: Sort) {
    if (sort?.active && sort?.direction) {
      this.sortColumnPayload = {};
      this.sortColumnPayload[sort.active] = sort.direction?.toUpperCase();
    }
    this.getGigDataList();
  }


  //function to set table pagination and sort 
  private setDataSourceAttributes() {
    this.gigVisibilityDataSource.paginator = this.paginator;
    this.gigVisibilityDataSource.sort = this.sort;
  }

  //function to change dates
  dateRangeChange(startDate, endDate) {
    this.pageNo = 1;
    this.getGigDataList();
    this.setDataSourceAttributes();
  }

  //function to get gig record list
  getGigDataList() {
    // api call for record list
    let payload: any = {
      queryFilters: {
        startDate: this.startDate ? this.datePipe.transform(this.startDate, 'yyyy-MM-dd') : null,
        endDate: this.endDate ? this.datePipe.transform(this.endDate, 'yyyy-MM-dd') : null,
        customFilter: this.searchText || null,
      },
      querySortOrder: this.sortColumnPayload,
      pageSize: this.itemPerPage,
      pageNumber: this.pageNo
    }

    for (let prop in payload.queryFilters) {
      if (payload.queryFilters[prop] === null || payload.queryFilters[prop] === undefined) {
        delete payload.queryFilters[prop];
      }
    }

    this.activeSubscriptions.add(
      this.gigService.gigReport(payload).subscribe((response: any) => {
        this.gigList = response.data || null;
        this.gigTotalRecord = response.totalRecordCount;
        this.gigVisibilityDataSource = new MatTableDataSource(response?.data ? response?.data : null);
        this.utilitiesService.updateIsLoading(false);
      },
        (error: Error) => {
          throw new Error(error.message);
        })
    );

  }

  //function to set pagination data
  loadPageData(event) {
    this.pageNo = event.pageIndex + 1;
    this.itemPerPage = event.pageSize;
    this.getGigDataList();
  }

  //function to clear dates
  clearDates(type){
    if(type ==='startDate'){
      this.startDate=null;
    }else {
      this.endDate=null;
    }
    this.getGigDataList();
  }

  //function to destroy api subscription data
  ngOnDestroy(): void {
    this.activeSubscriptions.unsubscribe();
  }

}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription, throwError } from 'rxjs';
import { UserProfile } from 'src/app/auth/models/user-profile';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { Notification } from '../../interfaces/notifications-interface';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { AuthenticatedUser } from 'src/app/auth/models/authenticated-user';
import { MatDialog } from '@angular/material/dialog';
import { NotificationDialogComponent } from '../notification-dialog/notification-dialog.component';

const DEFAULT_USER_PHOTO: string = '../../../../assets/images/home/default-user.png';

@Component({
  selector: 'tmp-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['name'];
  dataSource = [];
  filterDataSource = [];
  unreadNotifications: number = 0;
  activeSubscriptions: Subscription = new Subscription();
  userProfile: UserProfile;
  feedbackModalOpen: boolean = false;
  approvalModalOpen: boolean = false;
  empApprovalModalOpen: boolean = false;

  userPhoto: any;

  notificationModalOpen: boolean = false;

  activeNotification: Notification;
  user: AuthenticatedUser = JSON.parse(sessionStorage.getItem('currentUser'));

  request: any;
  nameOfPopUp = '';
  feedBackNotificationData: Notification;

  notificationFilterOptions = ['View All', 'Read', 'Unread'];
  selectedFilterOption: string = '';

  constructor(private authService: AuthenticationService,
    private sanitizer: DomSanitizer,
    private notificationService: NotificationsService,
    private utilitiesService: UtilitiesService,
    public dialog: MatDialog) { }

  ngOnInit(): void {

    this.notificationService.unreadNotifications$.subscribe((data) => this.unreadNotifications = data);

    this.activeSubscriptions.add(
      this.authService.getUserProfile().subscribe((profile: UserProfile) => {
        this.userProfile = profile;
      })
    );

    this.activeSubscriptions.add(
      this.authService.getUserPhoto().subscribe((photoBlob) => {
        let objectURL = URL.createObjectURL(photoBlob);
        this.userPhoto = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      },
        (error: HttpErrorResponse) => {
          if (error) {
            this.userPhoto = DEFAULT_USER_PHOTO;
          }
          else {
            throw new Error();
          }
        })
    );

    this.getUserNotifications();

  }

  ngOnDestroy(): void {
    this.activeSubscriptions.unsubscribe();
  }

  populateNotificationData(notification: Notification): void {
    this.notificationService.activeNotification = notification;
  }

  filterNotifications(filterOption: string): void {
    this.selectedFilterOption = filterOption;

    switch (filterOption) {
      case 'Read': {
        this.dataSource = this.filterDataSource.filter(data => data.readFlag === true);
        break;
      }
      case 'Unread': {
        this.dataSource = this.filterDataSource.filter(data => data.readFlag === false);
        break;
      }
      case 'View All': {
        this.dataSource = this.filterDataSource;
        break;
      }
    }
  }

  notificationStatusUpdate(notificationId: number): void {
    this.activeSubscriptions.add(
      this.notificationService.updateNotificationStatus(notificationId).subscribe(
        () => {
          this.getUserNotifications();
        },
        (error) => {
          throwError(error);
        },
        () => {
        })
    );
  }

  getUserNotifications(): void {
    this.activeSubscriptions.add(
      this.notificationService.getUserNotifications().subscribe(
        (notifications: Notification[]) => {
          this.dataSource = notifications;
          this.filterDataSource = notifications;
          let unreadNotifications = this.dataSource.filter(data => data.readFlag === false).length;
          this.notificationService.unreadNotifications$.next(unreadNotifications);
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', `There was an error retrieving user notifications. Please try again later.`, 6000, true);
        },
        () => { }
      )
    );
  }

  submitApproval(): void {

    this.request = {
      "action": "Approved",
      "actionComment": "Gig application approved successfully"
    }

    this.activeSubscriptions.add(
      this.notificationService.employeeManagerApproval(this.notificationService.activeNotification.gigID, this.notificationService.activeNotification.gigApplicationID, this.request).subscribe(
        () => {
          this.utilitiesService.showSnackbarMessage('success', 'The Gig application status updated successfully', 6000);
          this.notificationStatusUpdate(this.notificationService.activeNotification.notificationID);
          this.utilitiesService.updateIsLoading(false);
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', 'There was an error submitting feedback. Please try again later.', 6000);
          this.utilitiesService.updateIsLoading(false);
        },
        () => {
        })
    );
  }

  denyApproval(): void {

    this.request = {
      "action": "Rejected",
      "actionComment": ""
    }

    this.activeSubscriptions.add(
      this.notificationService.employeeManagerApproval(this.notificationService.activeNotification.gigID, this.notificationService.activeNotification.gigApplicationID, this.request).subscribe(
        () => {
          this.utilitiesService.showSnackbarMessage('success', 'The Gig application status updated successfully', 6000);
          this.notificationStatusUpdate(this.notificationService.activeNotification.notificationID);
          this.utilitiesService.updateIsLoading(false);
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', 'There was an error submitting feedback. Please try again later.', 6000);
          this.utilitiesService.updateIsLoading(false);
        },
        () => {
        })
    );
  }

  declineApproval(): void {

    this.request = {
      "action": "Rejected",
      "actionComment": ""
    }

    this.activeSubscriptions.add(
      this.notificationService.applicantApproval(this.notificationService.activeNotification.gigID, this.notificationService.activeNotification.gigApplicationID, this.request).subscribe(
        () => {
          this.utilitiesService.showSnackbarMessage('success', 'The Gig application status updated successfully', 6000);
          this.notificationStatusUpdate(this.notificationService.activeNotification.notificationID);
          this.utilitiesService.updateIsLoading(false);
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', 'There was an error submitting feedback. Please try again later.', 6000);
          this.utilitiesService.updateIsLoading(false);
        },
        () => {
        })
    );

  }

  acceptApproval(): void {

    this.request = {
      "action": "Approved",
      "actionComment": "Gig application approved successfully"
    }

    this.activeSubscriptions.add(
      this.notificationService.applicantApproval(this.notificationService.activeNotification.gigID, this.notificationService.activeNotification.gigApplicationID, this.request).subscribe(
        () => {
          this.utilitiesService.showSnackbarMessage('success', 'The Gig application status updated successfully', 6000);
          this.notificationStatusUpdate(this.notificationService.activeNotification.notificationID);
          this.utilitiesService.updateIsLoading(false);
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', 'There was an error submitting feedback. Please try again later.', 6000);
          this.utilitiesService.updateIsLoading(false);
        },
        () => {
        })
    );
  }
}
<div class="main-page-container">
    <mat-card class="notifications-container">
        <tmp-notifications></tmp-notifications>
    </mat-card>
    <mat-card class="main-content-container">
        
        
            <div class="feed-title" *ngIf="viewedGig">
                <h1>{{viewedGig?.gigName}}</h1>
            </div>
            <div class="gig-description" *ngIf="viewedGig">
                <h3 class="section-title">Gig Host: {{viewedGig?.gigManager?.firstName}} {{viewedGig?.gigManager?.lastName}}, {{viewedGig?.gigManager?.jobTitle}}</h3>
            </div>
        

        <!-- Using [hidden] instead of *ngIf to stop conflict with MatPaginator not rendering after component view init -->
        <section class="main-content" [hidden]="!viewedGig">
            <div class="main-content-actions action-buttons-margin">
                <de-button (buttonClick)="navigateHome()" size="small" type="secondary">GO BACK HOME</de-button>
                <de-button *ngIf="isAdmin" type="secondary" size="small" (buttonClick)="toggleGigVisibilityModal()">VISIBILITY</de-button>
                <div *ngIf="!isManager && !isHostedGig && !isAdmin">
                    <de-button *ngIf="!inactiveGigStatuses.includes(viewedGig?.gigStatus) && applyButtonText== 'APPLY'" (buttonClick)="saveGig()" type="secondary" size="small">{{savedText}}</de-button>
                    <de-button *ngIf="!inactiveGigStatuses.includes(viewedGig?.gigStatus)" (buttonClick)="applyToGig()" type="primary" size="small">{{applyButtonText}}</de-button>
                </div>
                <div *ngIf="(isManager || isAdmin) && isHostedGig">
                    <de-button *ngIf="!inactiveGigStatuses.includes(viewedGig?.gigStatus)" matTooltip="Know someone qualified for this gig? Click here to share." (buttonClick)="toggleShareGigModal()" type="secondary" size="small">SHARE</de-button>
                    <de-button *ngIf="!inactiveGigStatuses.includes(viewedGig?.gigStatus)" (buttonClick)="saveGig()" type="secondary" size="small">{{savedText}}</de-button>
                    <de-button (buttonClick)="closeGigModel()" [disabled]="viewedGig?.gigStatus !== 'Fully Staffed'" type="secondary" size="small">Close Gig</de-button>
                    <de-button (buttonClick)="cloneGig()" type="secondary" size="small">CLONE</de-button>
                    <de-button *ngIf="!inactiveGigStatuses.includes(viewedGig?.gigStatus) || viewedGig?.gigStatus == 'Draft'" (buttonClick)="modifyGig()" type="primary" size="small">EDIT</de-button>
                    <de-button *ngIf="viewedGig?.gigStatus == 'In Recruiting'" [disabled]="viewedGig?.gigStatus !== 'In Recruiting'" (buttonClick)="toggleCancelGigModal()" type="secondary" size="small">Cancel Gig</de-button>
                </div>
                <div *ngIf="(isManager || isAdmin) && !isHostedGig">
                    <de-button *ngIf="(viewedGig?.gigStatus == 'Draft' || viewedGig?.gigStatus == 'In Recruiting' || viewedGig?.gigStatus == 'Fully Staffed') && applyButtonText== 'APPLY'"  (buttonClick)="saveGig()" size="small" type="secondary">{{savedText}}</de-button>
                    <de-button (buttonClick)="cloneGig()" size="small" type="secondary">CLONE</de-button>
                    <de-button *ngIf="!inactiveGigStatuses.includes(viewedGig?.gigStatus)" (buttonClick)="applyToGig()" size="small" type="primary">{{applyButtonText}}</de-button>
                    <!-- Admins can edit any gig -->
                    <de-button *ngIf="isAdmin" (buttonClick)="modifyGig()" type="primary" size="small">EDIT</de-button>
                    <de-button *ngIf="isAdmin && viewedGig?.gigStatus == 'In Recruiting'" [disabled]="viewedGig?.gigStatus !== 'In Recruiting'" (buttonClick)="toggleCancelGigModal()" type="secondary" size="small">Cancel Gig</de-button>
                </div>
            </div>

            <de-modal
                modalTitle="Share This Gig"
                [open]="shareGigModalOpen"
                showMask="true"
                (close)="toggleShareGigModal()"
                theme="secondary"
                width="50vw">
                <div class="share-gig-modal" position="centered">
                    <div class="share-gig-modal-content">
                        <tmp-employee-search *ngIf="shareGigModalOpen" #employeeSearch (selectedEmployee)="selectEmployeeToShareGig($event)" isAutocompleteField class="visibility-filter-field"></tmp-employee-search>

                        <form [formGroup]="shareGigForm">
                            <mat-form-field appearance="outline">
                                <mat-label>Message</mat-label>
                                <textarea formControlName="message" required matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"></textarea>
                            </mat-form-field>
                        </form>

                    </div>
                    <div class="share-gig-modal-actions button-div-center">
                        <de-button [disabled]="shareGigForm.invalid" (buttonClick)="shareGig()" class="notification-modal-button" type="primary" size="small">SEND</de-button>
                    </div>
                </div>
            </de-modal>
            <de-modal modalTitle="Cancel This Gig" [open]="cancelGigModelOpen" showMask="true" (close)="toggleCancelGigModal()"
                theme="secondary" width="auto">
                <div class="notification-modal-body" position="centered">
                    <div>
                        <h2 class="notification-modal-title">Please send a message to the gig applicant</h2>
                        <p class="notification-desc">Use the space below to let the gig applicant know the gig is cancelled and explain why</p>
                    </div>
                    <mat-form-field appearance="outline">
                        <textarea class="notification-desc"
                        required matInput cdkTextareaAutosize  [(ngModel)] = "cancelGigMessage" #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"></textarea>
                    </mat-form-field>
                    <div class="button-div-center">
                  <de-button (buttonClick)="cancelGig()" class="notification-modal-button" type="primary" size="small">SEND</de-button>
                    </div>
                </div>
            </de-modal>
            <de-modal modalTitle="Close Gig" [open]="closeGigModelOpen" showMask="true" [showClose]="false" theme="secondary"
            width="auto">
            <div class="post-gig-modal-body" position="centered">
                <div class="post-gig-modal-content">
                    <p>Are you sure?</p>
                </div>

                <div class="submit-modal-button button-div-center">
                    <de-button (buttonClick)="closeGigModel()" type="secondary" size="small">CANCEL</de-button>
                    <de-button (buttonClick)="closeGig()" type="primary" size="small">CLOSE GIG</de-button>
                </div>
            </div>
        </de-modal>
        <de-modal modalTitle="Withdraw Gig" [open]="withdrawGigModelOpen" showMask="true" [showClose]="false" theme="secondary"
            width="auto">
            <div class="post-gig-modal-body" position="centered">
                <div class="post-gig-modal-content">
                    <p>Are you sure?</p>
                </div>
        
                <div class="submit-modal-button button-div-center">
                    <de-button (buttonClick)="withdrawGigModel()" type="secondary" size="small">CANCEL</de-button>
                    <de-button (buttonClick)="withdrawGig()" type="primary" size="small">WITHDRAW GIG</de-button>
                </div>
            </div>
        </de-modal>
            <de-modal
                #deModal
                modalTitle="Gig Visibility"
                [open]="visibilityFilterModalOpen"
                showMask="true"
                (close)="toggleGigVisibilityModal()"
                theme="secondary"
                width="60vw"
                height="70vh">
                <div position="centered" class="visibility-modal" [style.justify-content]="(!gigVisibilityDataSource.data.length && !isAdmin) ? 'center' : 'space-between'">
                    <section *ngIf="isAdmin">
                        <h1 class="modal-section-title-first">Show This Gig To Specific Users</h1>
                          <tmp-employee-search [isSpinnerLoading]="isSpinnerLoading" (searchText)="searchForEmployee($event)" class="visibility-filter-field"></tmp-employee-search>
                    </section>

                    <section [hidden]="!gigVisibilityDataSource.data.length" class="employee-list">
                        <h1 class="modal-section-title">Employee List</h1>

                        <table mat-table [dataSource]="gigVisibilityDataSource">
                            <ng-container matColumnDef="employeeName">
                              <th mat-header-cell *matHeaderCellDef>Employee Name</th>
                              <td mat-cell *matCellDef="let employee"> {{employee.firstName}} {{employee.lastName}} </td>
                            </ng-container>
                        
                            <ng-container matColumnDef="employeeId">
                              <th mat-header-cell *matHeaderCellDef>Employee ID</th>
                              <td mat-cell *matCellDef="let employee"> {{employee.employeeId}} </td>
                            </ng-container>
                        
                            <ng-container matColumnDef="select">
                              <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox 
                                    (change)="$event ? masterToggle() : null"
                                    [checked]="gigVisibilitySelection.hasValue() && isAllSelected()"
                                    [indeterminate]="gigVisibilitySelection.hasValue() && !isAllSelected()">
                                </mat-checkbox>
                              </th>
                              <td mat-cell *matCellDef="let employee">
                                <mat-checkbox 
                                    (click)="$event.stopPropagation()"
                                    (change)="gigVisibilitySelection.toggle(employee)"
                                    [checked]="gigVisibilitySelection.isSelected(employee)">
                                </mat-checkbox>
                              </td>
                            </ng-container>
                        
                            <tr mat-header-row *matHeaderRowDef="gigVisibilityColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: gigVisibilityColumns;"></tr>
                        </table>

                        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
                        </mat-paginator>
                    </section>

                    <section *ngIf="!gigVisibilityDataSource.data.length && !isAdmin" class="gig-visibility-message">
                        {{gigVisibilityMessage}}
                    </section>

                    <section *ngIf="isAdmin" class="gig-visibility-buttons button-div-center">
                        <de-button (buttonClick)="toggleGigVisibilityModal(); resetSelectionData()" class="notification-modal-button" type="secondary" size="small">CANCEL</de-button>
                        <de-button [disabled]="!canSaveVisibilityChanges" (buttonClick)="saveGigVisibility()" class="notification-modal-button" type="primary" size="small">SAVE</de-button>
                    </section>
                </div>
            </de-modal>
            <section>
                <h2 class="gig-status"  *ngIf="viewedGig?.gigStatus == 'Completed' || viewedGig?.gigStatus == 'Canceled' || viewedGig?.gigStatus == 'Fully Staffed' || viewedGig?.gigStatus == 'Closed'">{{viewedGig?.gigStatus | uppercase}}</h2>
                <div class="gig-description-container">
                    <img class="gig-image" [src]="getImgUrl(viewedGig?.gigCategory?.gigCategoryName)">
                    <div class="gig-description">
                        <h3 class="section-title">This Gig Is About...</h3>
                        <p class="gig-description-text ql-editor ql-editor-preview" [innerHtml]="viewedGig?.gigDescription"></p>
                    </div>
                    <div class="gig-description">
                        <h3 class="section-title">Deliverables and Milestones</h3>
                        <p class="gig-description-text ql-editor ql-editor-preview" [innerHtml]="viewedGig?.deliverables"></p>
                    </div>
                </div>
            </section>
            <section>
                <div *ngIf="!isAdmin && (!isHostedGig || showGigDetailsStatuses.includes(viewedGig?.gigStatus))">
                    <h3 class="section-title">Gig Skills</h3>
                    <skill-chip-field (skillIWantToDevelop)="addSkillToSkillsToDevelop($event)" chipOnlyInput [shownSkills]="gigSkills" [userSkills]="userSkills"></skill-chip-field>
                </div>
                
                <div class="expansion-panels" *ngIf="isAdmin || (isHostedGig && !showGigDetailsStatuses.includes(viewedGig?.gigStatus))">
                    <mat-accordion>
                        <mat-expansion-panel (opened)="skillsPanelOpen = true"
                        (closed)="skillsPanelOpen = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Gig Details
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                                <skill-chip-field fieldLabel="Gig Skills" chipOnlyInput [shownSkills]="gigSkills"></skill-chip-field>
                                <section class="gig-additional-data-container">
                                    <div class="gig-additional-data">
                                        <h3 class="section-title">Timeframe</h3>
                                        
                                        <div class="gig-additional-data-spacing">
                                            <h3 class="gig-additional-data-subtitle">Duration</h3>
                                            <p>{{viewedGig?.gigDuration}}</p>
                                        </div>
                                        <div class="gig-additional-data-spacing">
                                            <h3 class="gig-additional-data-subtitle">Date Range</h3>
                                            <p>{{viewedGig?.startDate | date: 'MM/dd/yyyy' }} - {{viewedGig?.endDate | date: 'MM/dd/yyyy' }}</p>
                                        </div>
                                        <div class="gig-additional-data-spacing">
                                            <h3 class="gig-additional-data-subtitle">What Availability Do You Expect?</h3>
                                            <p>{{viewedGig?.timeCommitment}}</p>
                                        </div>
                    
                    
                                    </div>
                                    <div class="gig-additional-data">
                                        <h3 class="section-title">Gig Details</h3>
                                    
                                        <div class="gig-additional-data-spacing">
                                            <h3 class="gig-additional-data-subtitle">Location</h3>
                                            <p>{{viewedGig?.gigWorkerMobilityClassification | titlecase}}</p>
                                        </div>
                                        <div class="gig-additional-data-spacing">
                                            <h3 class="gig-additional-data-subtitle">Where Is This Gig Located?</h3>
                                            <p>{{(viewedGig?.gigLocation?.locationName) ? viewedGig?.gigLocation?.locationName : 'N/A'}}</p>
                                        </div>
                                        <div class="gig-additional-data-spacing">
                                            <h3 class="gig-additional-data-subtitle">How Many Team Members Are Needed?</h3>
                                            <p>{{viewedGig?.noOfPositions}}</p>
                                        </div>
                                    </div>
                                
                                    <!-- <div class="gig-additional-data"> -->
                                        <!-- <h3 class="section-title">Resources</h3> -->
                                    
                                        <!-- <div class="gig-additional-data-spacing">
                                            <h3 class="gig-additional-data-subtitle">Uploaded Files</h3>
                                            <ng-container *ngIf="viewedGig?.gigFile"> -->
                                                <!-- <p class="gig-attachment-container" *ngFor="let file of viewedGig?.gigFile">{{file.gigFileKey}}</p> -->
                                                <!-- <div class="gig-attachment-container">
                                                    <a class="attachment" (click)="viewFile(file)" *ngFor="let file of viewedGig?.gigFile">{{file.gigFileKey}}</a>
                                                </div>
                                            </ng-container>
                                            <p *ngIf="!viewedGig?.gigFile" class="gig-attachment-container">(none)</p>
                                        </div> -->
                                        <!-- <div class="gig-additional-data-spacing">
                                            <h3 class="gig-additional-data-subtitle">Getting Started with Gigs</h3>
                                            <p class="gig-attachment-container">Gig Guidelines.doc</p>
                                            <p class="gig-attachment-container">Review the Full Gigs Process.doc</p>
                                        </div> -->

                                    <!-- </div> -->
                                </section>
                        </mat-expansion-panel>
                    </mat-accordion>

                    <mat-card *ngIf="isAdmin || (isHostedGig && feedApplicantStatus.includes(viewedGig?.gigStatus))">
                        <mat-card-title>Applicant Feed</mat-card-title>

                        <div class="applicant-section-subtitle">
                            <mat-card-subtitle class="selected-applicant-subtitle">Selected</mat-card-subtitle>
                            <mat-card-subtitle class="selected-applicant-subtitle" *ngIf="selectedApplicants.length > 0">{{selectedApplicants.length}} of {{viewedGig?.noOfPositions}} Participants Selected</mat-card-subtitle>
                            <mat-card-subtitle class="selected-applicant-subtitle" *ngIf="selectedApplicants.length == 0"> No Participants Selected</mat-card-subtitle>
                        </div>    
                        <div class="applicant-feed-section">
                            <mat-card class="applicant applicant-selected" *ngFor="let applicant of selectedApplicants">
                                <div class="applicant-details">
                                    <div mat-card-avatar class="gig-poster-avatar"></div>
                                    <div class="applicant-name-container">
                                        <mat-card-title class="applicant-name">{{applicant?.gigEmployee?.firstName}} {{applicant?.gigEmployee?.lastName}}</mat-card-title>
                                        <mat-card-subtitle class="applicant-job">{{applicant?.gigEmployee?.jobTitle}}</mat-card-subtitle>
                                        <mat-card-subtitle class="applicant-job">{{applicant?.gigEmployee?.emailAddress}}</mat-card-subtitle>
                                        <mat-card-subtitle class="applicant-job">{{applicant?.gigEmployee?.fercClassification}}</mat-card-subtitle>
                                        <mat-card-subtitle class="applicant-status">{{applicant?.status}}</mat-card-subtitle>
                                    </div>
                                </div>

                                <div class="applicant-actions">
                                    <div class="applicant-skills">
                                        <ng-container *ngIf="applicant.gigEmployee?.skills">
                                            <mat-card-subtitle *ngIf="applicant.gigEmployee?.hasSkillOverlap">Required Skills</mat-card-subtitle>
                                            <mat-chip-list>
                                                <ng-container *ngFor="let applicantSkill of applicant.gigEmployee?.skills">
                                                    <mat-chip disableRipple *ngIf="applicantSkill.skillOverlaps">{{applicantSkill?.skillName}}</mat-chip>
                                                </ng-container>
                                            </mat-chip-list>
                                        </ng-container>
                                    </div>
                                    
                                    <div class="applicant-action-buttons">
                                        <de-button (buttonClick)="deselectApplicant(applicant)" size="small" type="secondary">DESELECT</de-button>
                                        <de-button (buttonClick)="viewEmployeeProfile(applicant)" size="small" type="secondary">VIEW</de-button>
                                    </div>
                                </div>

                            </mat-card>   
                        </div>
                        <mat-divider></mat-divider>
                        <div class="applicant-section-subtitle">
                            <mat-card-subtitle>Pending Approval</mat-card-subtitle>
                            <mat-card-subtitle *ngIf="applicantPendingSelectionList.length == 0">No Participants Pending Selection</mat-card-subtitle>
                        </div>
                        <div class="applicant-feed-section">
                            <mat-card class="applicant" *ngFor="let applicant of applicantPendingSelectionList">
                                <div class="applicant-details">
                                    <div mat-card-avatar class="gig-poster-avatar"></div>
                                    <div class="applicant-name-container">
                                        <mat-card-title class="applicant-name">{{applicant.gigEmployee?.firstName}} {{applicant.gigEmployee?.lastName}}</mat-card-title>
                                        <mat-card-subtitle class="applicant-job">{{applicant?.gigEmployee?.jobTitle}}</mat-card-subtitle>
                                        <mat-card-subtitle class="applicant-job">{{applicant?.gigEmployee?.emailAddress}}</mat-card-subtitle>
                                        <mat-card-subtitle class="applicant-job">{{applicant?.gigEmployee?.fercClassification}}</mat-card-subtitle>
                                        <mat-card-subtitle class="applicant-status">{{applicant?.status}}</mat-card-subtitle>
                                    </div>
                                </div>

                                <div class="applicant-actions">
                                    <div class="applicant-skills">
                                        <mat-card-subtitle *ngIf="applicant.gigEmployee?.hasSkillOverlap">Required Skills</mat-card-subtitle>
                                        <mat-chip-list>
                                            <ng-container *ngFor="let applicantSkill of applicant.gigEmployee?.skills">
                                                <mat-chip disableRipple *ngIf="applicantSkill.skillOverlaps">{{applicantSkill?.skillName}}</mat-chip>
                                            </ng-container>
                                        </mat-chip-list>
                                    </div>
                                    
                                    <div class="applicant-action-buttons">
                                        <!-- <de-button matTooltip="Invite to Participate" matTooltipPosition="above" (buttonClick)="selectApplicantFromInterviewList(applicant)" size="small" type="secondary">SELECT</de-button> -->
                                        <de-button (buttonClick)="viewEmployeeProfile(applicant)" size="small" type="secondary">VIEW</de-button>
                                    </div>
                                </div>

                            </mat-card>   
                        </div>
                        <mat-divider></mat-divider>
                        <div class="applicant-section-subtitle">
                            <mat-card-subtitle >Invited to Interview</mat-card-subtitle>
                            <mat-card-subtitle *ngIf="applicantInterviewList.length == 0"> No Interview Participants Selected</mat-card-subtitle>
                        </div>
                        <div class="applicant-feed-section">
                            <mat-card class="applicant" *ngFor="let applicant of applicantInterviewList">
                                <div class="applicant-details">
                                    <div mat-card-avatar class="gig-poster-avatar"></div>
                                    <div class="applicant-name-container">
                                        <mat-card-title class="applicant-name">{{applicant.gigEmployee?.firstName}} {{applicant.gigEmployee?.lastName}}</mat-card-title>
                                        <mat-card-subtitle class="applicant-job">{{applicant?.gigEmployee?.jobTitle}}</mat-card-subtitle>
                                        <mat-card-subtitle class="applicant-job">{{applicant?.gigEmployee?.emailAddress}}</mat-card-subtitle>
                                        <mat-card-subtitle class="applicant-job">{{applicant?.gigEmployee?.fercClassification}}</mat-card-subtitle>
                                       
                                        <mat-card-subtitle class="applicant-status">{{applicant?.status}}</mat-card-subtitle>
                                        <!-- <mat-checkbox [disabled]="applicant?.status.includes('Rejected')" (change)="deselectApplicant(applicant)" disableRipple class="applicant-checkbox">Not Selected - Position Filled</mat-checkbox> -->
                                    </div>
                                </div>

                                <div class="applicant-actions">
                                    <div class="applicant-skills">
                                        <mat-card-subtitle *ngIf="applicant.gigEmployee?.hasSkillOverlap">Required Skills</mat-card-subtitle>
                                        <mat-chip-list>
                                            <ng-container *ngFor="let applicantSkill of applicant.gigEmployee?.skills">
                                                <mat-chip disableRipple *ngIf="applicantSkill.skillOverlaps">{{applicantSkill?.skillName}}</mat-chip>
                                            </ng-container>
                                        </mat-chip-list>
                                    </div>
                                    
                                    <div class="applicant-action-buttons">
                                        <de-button [disabled]="applicant?.status.includes('Rejected')" matTooltip="Invite to Participate" matTooltipPosition="above" (buttonClick)="toggleSelectModal(applicant)" size="small" type="secondary">SELECT</de-button>
                                        <de-button (buttonClick)="viewEmployeeProfile(applicant)" size="small" type="secondary">VIEW</de-button>
                                    </div>
                                </div>
                                <de-modal modalTitle="Select this candidate for your gig" [open]="selectionPopUp" showMask="true"
                                    (close)="toggleSelectModal()" theme="secondary" width="50vw" >
                                    <div class="notification-modal-body" position="centered">
                                        <div>
                                           <p class="notification-desc">You’re about to select {{selectedApplicant?.gigEmployee?.firstName}}  {{selectedApplicant?.gigEmployee?.lastName}} to participate in your gig.</p>
                                           <br>
                                           <p class="notification-desc">If you click continue, a notification will be sent to the applicant’s manager asking them to approve the employee’s participation in your gig. You can click cancel if you do not want to select this employee as the participant for your gig.</p>
                                        </div>
                                        <div class="feedback-buttons flex-div-center">
                                            <de-button size="small" (buttonClick)="toggleSelectModal()" type='secondary'>Cancel</de-button>
                                            <de-button size="small" (buttonClick)="selectApplicantFromInterviewList()" type='primary'>Continue</de-button>
                                        </div>
                                    </div>
                                </de-modal>

                            </mat-card>   
                        </div>
                        <mat-divider></mat-divider>
                        <div class="applicant-section-subtitle">
                            <mat-card-subtitle>Feed</mat-card-subtitle>
                            <mat-card-subtitle *ngIf="applicantFeed.length == 0">No Applicants in Feed</mat-card-subtitle>
                        </div>
                        <mat-chip-list *ngIf="applicantFeed.length > 0" #chipList>
                            <mat-chip #chip="matChip"
                                      *ngFor="let applicantFilter of applicantFilters"
                                      [selected]="applicantFilter.selected"
                                      (click)="(applicantFilter.selected && applicantFilter.name !== selectedApplicantFilter.name) ? chip.deselect() : chip.select()"
                                      (selectionChange)="onSelectedApplicantFilter($event, applicantFilter)"
                                      disableRipple>
                                {{applicantFilter.name}}
                            </mat-chip>
                        </mat-chip-list>
                        <div class="applicant-feed-section">
                            <mat-card class="applicant" *ngFor="let applicant of applicantFeed">
                                <div class="applicant-details">
                                    <div mat-card-avatar class="gig-poster-avatar"></div>
                                    <div class="applicant-name-container">
                                        <mat-card-title class="applicant-name">{{applicant?.gigEmployee?.firstName}} {{applicant?.gigEmployee?.lastName}}</mat-card-title>
                                        <mat-card-subtitle class="applicant-job">{{applicant?.gigEmployee?.jobTitle}}</mat-card-subtitle>
                                        <mat-card-subtitle class="applicant-job">{{applicant?.gigEmployee?.emailAddress}}</mat-card-subtitle>
                                        <mat-card-subtitle class="applicant-job">{{applicant?.gigEmployee?.fercClassification}}</mat-card-subtitle>
                                        <mat-card-subtitle class="applicant-status">{{applicant?.status}}</mat-card-subtitle>
                                    </div>
                                </div>

                                <div class="applicant-actions">
                                    <div class="applicant-skills">
                                        <mat-card-subtitle *ngIf="applicant.gigEmployee?.hasSkillOverlap">Required Skills</mat-card-subtitle>
                                        <mat-chip-list>
                                            <ng-container *ngFor="let applicantSkill of applicant.gigEmployee?.skills">
                                                <mat-chip disableRipple *ngIf="applicantSkill.skillOverlaps">{{applicantSkill?.skillName}}</mat-chip>
                                            </ng-container>
                                        </mat-chip-list>
                                    </div>
                                    
                                    <div class="applicant-action-buttons">
                                        <de-button [disabled]="applicant?.status.includes('Rejected')" matTooltip="Invite to Interview" matTooltipPosition="above" (buttonClick)="toggleInterviewModal(applicant)" size="small" type="secondary">INTERVIEW</de-button>
                                        <de-button (buttonClick)="viewEmployeeProfile(applicant)" size="small" type="secondary">VIEW</de-button>
                                    </div>
                                </div>
                                <de-modal modalTitle="Select this candidate for an interview" [open]="interviewPopUp" showMask="true"
                                    (close)="toggleInterviewModal()" theme="secondary" width="50vw" >
                                    <div class="notification-modal-body" position="centered">
                                        <div>
                                        <p class="notification-desc">You're about to select {{selectedApplicant?.gigEmployee?.firstName}} {{selectedApplicant?.gigEmployee?.lastName}} for an interview for your gig. </p>
                                        <br>
                                        <p class="notification-desc">If you click continue, a notification will be sent to the employee letting them know they were selected for an interview. You need to reach out to the employee to schedule time to discuss the gig. You can click cancel if you do not want to select this employee for an interview for your gig.</p>
                                        </div>
                                
                                        <div class="feedback-buttons flex-div-center">
                                            <de-button size="small"  (buttonClick)="toggleInterviewModal()" type='secondary'>Cancel</de-button>
                                            <de-button size="small" (buttonClick)="interviewApplicant()" type='primary'>Continue</de-button>
                                        </div>
                                    </div>
                                </de-modal>

                            </mat-card>   
                        </div>
                    </mat-card>
                </div>

            </section>
            <section *ngIf="!isAdmin && (!isHostedGig || showGigDetailsStatuses.includes(viewedGig?.gigStatus))" class="gig-additional-data-container">
                <div class="gig-additional-data">
                    <h3 class="section-title">Timeframe</h3>
    
                    <div class="gig-additional-data-spacing">
                        <h3 class="gig-additional-data-subtitle">Duration</h3>
                        <p>{{viewedGig?.gigDuration}}</p>
                    </div>
                    <div class="gig-additional-data-spacing">
                        <h3 class="gig-additional-data-subtitle">Date Range</h3>
                        <p>{{viewedGig?.startDate | date: 'MM/dd/yyyy' }} - {{viewedGig?.endDate | date: 'MM/dd/yyyy' }}</p>
                    </div>
                    <div class="gig-additional-data-spacing">
                        <h3 class="gig-additional-data-subtitle">What Availability Do You Expect?</h3>
                        <p>{{viewedGig?.timeCommitment}}</p>
                    </div>
    
    
                </div>
                <div class="gig-additional-data">
                    <h3 class="section-title">Gig Details</h3>
    
                    <div class="gig-additional-data-spacing">
                        <h3 class="gig-additional-data-subtitle">Location</h3>
                        <p>{{viewedGig?.gigWorkerMobilityClassification | titlecase}}</p>
                    </div>
                    <div class="gig-additional-data-spacing">
                        <h3 class="gig-additional-data-subtitle">Where Is This Gig Located?</h3>
                        <p>{{(viewedGig?.gigLocation?.locationName) ? viewedGig?.gigLocation?.locationName : 'N/A'}}</p>
                    </div>
                    <div class="gig-additional-data-spacing">
                        <h3 class="gig-additional-data-subtitle">How Many Team Members Are Needed?</h3>
                        <p>{{viewedGig?.noOfPositions}}</p>
                    </div>
                </div>
    
                <!-- <div class="gig-additional-data"> -->
                    <!-- <h3 class="section-title">Resources</h3> -->
    
                    <!-- <div class="gig-additional-data-spacing">
                        <h3 class="gig-additional-data-subtitle">Uploaded Files</h3>
                        <ng-container *ngIf="viewedGig?.gigFile">
                            <a class="gig-attachment-container" *ngFor="let file of viewedGig?.gigFile">{{file.gigFileKey}}</a>
                        </ng-container>
                        <p *ngIf="!viewedGig?.gigFile" class="gig-attachment-container">(none)</p>
                    </div> -->
                    
                    <!-- <div class="gig-additional-data-spacing">
                        <h3 class="gig-additional-data-subtitle">Getting Started with Gigs</h3>
                        <p class="gig-attachment-container">Gig Guidelines.doc</p>
                        <p class="gig-attachment-container">Review the Full Gigs Process.doc</p>
                    </div> -->
                    
                <!-- </div> -->
            </section>
            <section class="similar-gigs" *ngIf="similarGigs.length && !isHostedGig">
                <h3 class="similar-gigs-title">Similar Gigs</h3>
                <div class="similar-gig-feed">
                    <mat-card *ngFor="let gig of similarGigs">
                        <mat-card-header class="gig-header-container">
                            <div class="gig-header">
                                <mat-card-title>{{gig?.gigName}}</mat-card-title>
                                <mat-card-title class="gig-duration">{{gig?.gigDuration}}</mat-card-title>
                            </div>
                            <div class="gig-header">   
                                <mat-card-subtitle>{{gig?.gigTeam}}</mat-card-subtitle>
                                <mat-card-subtitle>Starts {{gig.startDate | date: 'MM/dd/yyyy'}}</mat-card-subtitle>
                            </div>
                        </mat-card-header>
                        <img mat-card-image [src]="getImgUrl(gig.gigCategory?.gigCategoryName)" alt="Photo of a Shiba Inu">
                        <mat-card-content>
                            <div class="gig-poster-container">
                                <div mat-card-avatar class="gig-poster-avatar"></div>
        
                                <div class="gig-poster-info">
                                    <mat-card-title class="gig-leader-name">{{gig?.gigManager.firstName}} {{gig?.gigManager.lastName}}</mat-card-title>
                                    <mat-card-subtitle class="gig-leader-title">{{gig?.gigManager.jobTitle}}</mat-card-subtitle>
                                </div>
                            </div>
        
                            <div class="gig-status-container">
                                <mat-card-subtitle class="gig-posted-date">{{(gig?.gigPostedDate) ? 'Posted ' : ''}} {{gig?.gigPostedDate | date: 'MM/dd/yyyy'}}</mat-card-subtitle>
                            </div>
        
                        </mat-card-content>
                        <mat-card-actions>
                            <div>
                                <p>{{gig?.gigCategory?.gigCategoryName}}</p>
                                <ng-container *ngIf="gig.gigLocation">
                                  <p>{{gig?.gigLocation?.locationName}} ({{gig?.gigLocation?.city}} {{gig?.gigLocation?.stateCode}})</p>
                                </ng-container>
                                <p>{{gig?.gigWorkerMobilityClassification | titlecase}}</p>
                            </div>
                          <de-button (buttonClick)="viewGig(gig)" type="secondary" size="small">VIEW GIG</de-button>
                        </mat-card-actions> 
                    </mat-card>
                </div>
            </section>
        </section>

    </mat-card>
</div>
<router-outlet></router-outlet>
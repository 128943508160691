import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { EmotionIcon } from '../../interfaces/emotion-icon.interface';
import { Feedback } from '../../interfaces/feedback/feedback-interface';
import { Subscription, throwError } from 'rxjs';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { AuthenticatedUser } from 'src/app/auth/models/authenticated-user';
import { Notification } from '../../interfaces/notifications-interface';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';

@Component({
  selector: 'tmp-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit, OnDestroy {


  @Input() feedbackMode: string;
  @Input() notificationData: Notification;
  @Output() submitedFeedback = new EventEmitter<any>();
  @Output() notificationClick = new EventEmitter<any>();

  secondarybuttonType = 'secondary';
  

  showReportProblem: boolean = false;
  showRateExperience: boolean = false;

  problemText: string = '';
  suggestionText: string = '';

  participantFeedbackText: string = '';
  managerFeedbackText: string = '';

  ratingSelected: boolean = false;
  emotionChoice: EmotionIcon;


  emotionIcons: EmotionIcon[] = [
    {
      icon: 'rating_1',
      text: 'Sad',
      rating: 1,
      selected: false
    },
    {
      icon: 'rating_2',
      rating: 2,
      selected: false
    },
    {
      icon: 'rating_3',
      text: 'Neutral',
      rating: 3,
      selected: false
    },
    {
      icon: 'rating_4',
      rating: 4,
      selected: false
    },
    {
      icon: 'rating_5',
      text: 'Happy',
      rating: 5,
      selected: false
    },
  ]

  user: AuthenticatedUser;

  @ViewChild("text") text: ElementRef;

  activeSubscriptions: Subscription = new Subscription();

  constructor(private feedbackService: FeedbackService, private utilitiesService: UtilitiesService,  private notificationService: NotificationsService, private authService: AuthenticationService) { }

  ngOnInit(): void {

    this.activeSubscriptions.add(    
      this.authService.currentUser$.subscribe((user: AuthenticatedUser) => {
      this.user = user;
    }));

    this.showReportProblem = true;
  }

  ngOnDestroy(): void {
    this.activeSubscriptions.unsubscribe();
  }

  submitFeedback(): void {

    let request: Feedback = {
      fromEmployeeId: this.user.idTokenClaims.employeeId,
      comments: '',
      gigId : 0
    };
    
    switch (this.feedbackMode) {
  
      case 'general':
        if (this.showReportProblem) {
          request.comments = this.problemText;
          this.reportProblem(request);
          break;
        }
        else if (this.showRateExperience) {
          request.comments = this.suggestionText;
          request.rating = this.emotionChoice.rating;
          this.submitRating(request);
          break;
        }
      case 'participant':
        request.comments = this.participantFeedbackText;
        request.gigId = this.notificationData?.gigID;
        this.submitGigParticipant(request,this.notificationData.notificationID);
        break;

      case 'manager':
        request.comments = this.managerFeedbackText;
        request.toEmployeeId = this.notificationData?.applicantEmployeeID;
        request.gigId = this.notificationData?.gigID;
        this.submitGigHost(request,this.notificationData.notificationID);
        break;
    }

    this.problemText = '';
    this.suggestionText = '';
    this.participantFeedbackText = '';
    this.managerFeedbackText = '';

    this.submitedFeedback.emit();
  }

  displayReportProblem(): boolean {
    this.showRateExperience = false;
    return this.showReportProblem = true;
  }

  displayRateExperience(): boolean {
    this.showReportProblem = false;
    return this.showRateExperience = true;
  }

  onRatingClick(selectedIcon: EmotionIcon): void {
    this.emotionChoice = selectedIcon;
    selectedIcon.selected = true;
    for (let icon of this.emotionIcons) {
      if (icon.rating !== selectedIcon.rating) {
        icon.selected = false;
      }
    }
  }

  reportProblem(feedback: Feedback): void {
    this.activeSubscriptions.add(
      this.feedbackService.reportProblem(feedback).subscribe(
        () => {
          this.utilitiesService.showSnackbarMessage('success', 'Feedback submitted successfully', 6000);
          this.utilitiesService.updateIsLoading(false);
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', 'There was an error submitting feedback. Please try again later.', 6000, true);
          this.utilitiesService.updateIsLoading(false);
        },
        () => {
        })
    );
  }

  submitRating(feedback: Feedback): void {
    this.activeSubscriptions.add(
      this.feedbackService.submitRating(feedback).subscribe(
        () => {
          this.utilitiesService.showSnackbarMessage('success', 'Feedback submitted successfully', 6000);
          this.utilitiesService.updateIsLoading(false);
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', 'There was an error submitting feedback. Please try again later.', 6000, true);
          this.utilitiesService.updateIsLoading(false);
        },
        () => {
        })
    );
  }

  submitGigParticipant(feedback: Feedback, notificationId: number): void {
    this.activeSubscriptions.add(
      this.feedbackService.submitGigParticipant(feedback).subscribe(
        () => {
          this.utilitiesService.showSnackbarMessage('success', 'Feedback submitted successfully', 6000);
          this.notificationStatusUpdate(notificationId);
          this.utilitiesService.updateIsLoading(false);
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', 'There was an error submitting feedback. Please try again later.', 6000, true);
          this.utilitiesService.updateIsLoading(false);
        },
        () => {
        })
    );
  }

  submitGigHost(feedback: Feedback, notificationId: number): void {
    this.activeSubscriptions.add(
      this.feedbackService.submitGigHost(feedback).subscribe(
        () => {
          this.utilitiesService.showSnackbarMessage('success', 'Feedback submitted successfully', 6000);
          this.notificationStatusUpdate(notificationId);
          this.utilitiesService.updateIsLoading(false);
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', 'There was an error submitting feedback. Please try again later.', 6000, true);
          this.utilitiesService.updateIsLoading(false);
        },
        () => {
        })
    );
  }

  notificationStatusUpdate(notificationId: number): void {
    this.activeSubscriptions.add(
      this.notificationService.updateNotificationStatus(notificationId).subscribe(
        () => {
          this.utilitiesService.updateIsLoading(false);
          this.notificationClick.emit();
        },
        (error) => {
          throwError(error);
          this.utilitiesService.showSnackbarMessage('warning', 'There was an error submitting feedback. Please try again later.', 6000);
          this.utilitiesService.updateIsLoading(false);
          
        },
        () => {
        })
    );
  }

}